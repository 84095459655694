@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.control-bar-tracked-questions {
    display: flex;
    align-items: center;

    &__progress {
        display: none;

        @include medium {
            display: block;

            margin-right: r.replaceBy($space-2, --hop-space-inline-sm);

            [data-brand="workleap"] & {
                @include heading-5;
                font-weight: var(--font-weight-medium);
                line-height: var(--line-height-md);
            }
        }
    }
}