@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.recognition-select-prompt-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(76rem * var(--rem-ratio, 1));
    margin: 0 auto;
    padding: 0 calc(3.2rem * var(--rem-ratio, 1)) calc(1.6rem * var(--rem-ratio, 1)) calc(3.2rem * var(--rem-ratio, 1));

    [data-brand="workleap"] & {
        margin-top: calc(-1.4rem * var(--rem-ratio, 1));

        @include small {
            margin-top: calc(-4rem * var(--rem-ratio, 1));
        }
    }

    &__title {
        color: $contrast-text;

        @include heading-2;
        line-height: var(--line-height-xl);
        text-align: center;

        [data-brand="workleap"] & {
            margin-bottom: calc(2.4rem * var(--rem-ratio, 1));

            @include heading-3;
            line-height: var(--hop-heading-lg-line-height);

            @include small {
                margin-bottom: 0;
            }
        }
    }

    &__subtitle {
        display: none;

        @include small {
            display: block;
            margin-top: calc(0.8rem * var(--rem-ratio, 1));

            color: $contrast-text;

            @include medium-text;
            text-align: center;
        }
    }

    &__prompt-stack {
        margin-top: calc(0.8rem * var(--rem-ratio, 1));

        @include small {
            margin-top: calc(5.6rem * var(--rem-ratio, 1));
        }
    }

    &__shuffle-card-button {
        margin-top: calc(2.4rem * var(--rem-ratio, 1));
        margin-bottom: calc(0.8rem * var(--rem-ratio, 1));

        &:focus-visible {
            &:after {
                border-color: $surface-primary-button-dandelion;
            }
        }

        [data-brand="workleap"] & {
            margin: calc(2.4rem * var(--rem-ratio, 1)) 0 calc(1.6rem * var(--rem-ratio, 1));

            svg {
                //Override icon margin in primary button
                margin-right: 0;
            }
        }

        @include small {
            margin-top: calc(5.6rem * var(--rem-ratio, 1));
            margin-bottom: calc(2.4rem * var(--rem-ratio, 1));

            [data-brand="workleap"] & {
                margin: calc(3.2rem * var(--rem-ratio, 1)) 0 calc(1.6rem * var(--rem-ratio, 1));
            }
        }
    }

    &__secondary-actions {
        text-align: center;

        [data-brand="workleap"] & {
            //Override ghost button padding
            button {
                padding: 0 calc(0.8rem * var(--rem-ratio, 1));
            }
        }
    }

    &--animated-intro {
        .recognition-select-prompt-step__title {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-title-slide-in-animation);

            animation: fade-in linear 500ms both,
                recognition-title-slide-in-animation ease-out 500ms both;
        }

        .recognition-select-prompt-step__subtitle {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-subtitle-slide-in-animation);
            $delay: 200ms;

            animation: fade-in linear 500ms $delay both,
                recognition-subtitle-slide-in-animation ease-out 500ms $delay both;
        }

        .recognition-select-prompt-step__prompt-stack {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-prompt-stack-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                recognition-prompt-stack-slide-in-animation ease-out 500ms $delay both;
        }

        .recognition-select-prompt-step__shuffle-card-button {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-shuffle-card-button-slide-in-animation);
            $delay: 600ms;

            animation: fade-in linear 500ms $delay both,
                recognition-shuffle-card-button-slide-in-animation ease-out 500ms $delay both;
        }

        .recognition-select-prompt-step__skip-button {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-skip-button-slide-in-animation);
            $delay: 700ms;

            animation: fade-in linear 500ms $delay both,
                recognition-skip-button-slide-in-animation ease-out 500ms $delay both;
        }

        .recognition-select-prompt-step__secondary-actions {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-secondary-actions-slide-in-animation);
            $delay: 700ms;

            animation: fade-in linear 500ms $delay both,
                recognition-secondary-actions-slide-in-animation ease-out 500ms $delay both;
        }
    }

    &--confirmed {
        pointer-events: none;

        .recognition-prompt-stack__current-card {
            @keyframes recognition-prompt-confirmed-animation {
                0% { transform: scale(1) rotate(0deg); }
                100% { transform: scale(1.15) rotate(-2deg); }
            }

            animation: recognition-prompt-confirmed-animation ease-out 200ms both;

            &.recognition-prompt--creamsicle .recognition-prompt__content {
                @keyframes recognition-prompt-confirmed-background-creamsicle-animation {
                    0% { background-color: $surface-recognition-card-creamsicle; }
                    100% { background-color: var(--creamsicle-100); }
                }

                animation: recognition-prompt-confirmed-background-creamsicle-animation linear 80ms 4 alternate both;
            }

            &.recognition-prompt--seaweed .recognition-prompt__content {
                @keyframes recognition-prompt-confirmed-background-seaweed-animation {
                    0% { background-color: $surface-recognition-card-seaweed; }
                    100% { background-color: var(--seaweed-100); }
                }

                animation: recognition-prompt-confirmed-background-seaweed-animation linear 80ms 4 alternate both;
            }

            &.recognition-prompt--dandelion .recognition-prompt__content {
                @keyframes recognition-prompt-confirmed-background-dandelion-animation {
                    0% { background-color: $surface-recognition-card-dandelion; }
                    100% { background-color: var(--dandelion-100); }
                }

                animation: recognition-prompt-confirmed-background-dandelion-animation linear 80ms 4 alternate both;
            }

            &.recognition-prompt--electric-blue .recognition-prompt__content {
                @keyframes recognition-prompt-confirmed-background-electric-blue-animation {
                    0% { background-color: $surface-recognition-card-electric-blue; }
                    100% { background-color: var(--electric-blue-50); }
                }

                animation: recognition-prompt-confirmed-background-electric-blue-animation linear 80ms 4 alternate both;
            }

            &.recognition-prompt--sky .recognition-prompt__content {
                @keyframes recognition-prompt-confirmed-background-sky-animation {
                    0% { background-color: $surface-recognition-card-sky; }
                    100% { background-color: var(--sky-50); }
                }

                animation: recognition-prompt-confirmed-background-sky-animation linear 80ms 4 alternate both;
            }

            &.recognition-prompt--coral .recognition-prompt__content {
                @keyframes recognition-prompt-confirmed-background-coral-animation {
                    0% { background-color: $surface-recognition-card-coral; }
                    100% { background-color: var(--coral-50); }
                }

                animation: recognition-prompt-confirmed-background-coral-animation linear 80ms 4 alternate both;
            }

            &.recognition-prompt--strawberry-fields .recognition-prompt__content {
                @keyframes recognition-prompt-confirmed-background-strawberry-fields-animation {
                    0% { background-color: $surface-recognition-card-strawberry-fields; }
                    100% { background-color: var(--strawberry-fields-50); }
                }

                animation: recognition-prompt-confirmed-background-strawberry-fields-animation linear 80ms 4 alternate both;
            }
        }

        .recognition-prompt-stack__next-card,
        .recognition-prompt-stack__second-next-card {
            animation: fade-out linear 200ms both;
        }
    }
}