@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

@include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-outro-slide-in);

.recognition-outro {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: r.replaceBy($space-8, --_padding-none) r.replaceBy($space-3, --hop-space-inset-md) 0 r.replaceBy($space-3, --hop-space-inset-md);

    [data-brand="workleap"] & {
        margin-top: calc(-3.2rem * var(--rem-ratio, 1));

        @include small {
            margin-top: calc(-7.2rem * var(--rem-ratio, 1));
        }
    }

    &__content {
        max-width: calc(32rem * var(--rem-ratio, 1));

        [data-brand="workleap"] & {
            max-width: calc(44rem * var(--rem-ratio, 1));
        }
    }

    &__image, &__placeholder {
        display: block;
        width: calc(20rem * var(--rem-ratio, 1));
        height: calc(20rem * var(--rem-ratio, 1));
        margin: 0 auto var(--hop-space-stack-md) auto;

        animation: fade-in linear 400ms both,
            pulse-survey-outro-slide-in ease-out 400ms both;

        @include small {
            margin: 0 auto var(--hop-space-stack-xl) auto;
        }
    }

    &__title {
        color: r.replaceBy($contrast-text, --hop-neutral-text);

        @include heading-1;
        font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-lg-font-weight);
        text-align: center;

        animation: fade-in linear 400ms both,
            recognition-outro-slide-in ease-out 400ms both;

        [data-brand="workleap"] & {
            @include heading-3;
        }

        @include small {
            [data-brand="workleap"] & {
                @include heading-1;
            }
        }
    }

    &__redirect-timer-label {
        margin-top: r.replaceBy($space-5, --hop-space-stack-lg);

        color: r.replaceBy($contrast-text, --hop-neutral-text);

        @include medium-text;
        white-space: pre-line;
        text-align: center;

        animation: fade-in linear 400ms 200ms both,
            recognition-outro-slide-in ease-out 400ms 200ms both;
    }

    &__remaining-seconds-before-redirect {
        font-weight: r.replaceBy(var(--font-weight-semibold), --hop-accent-sm-font-weight);
        white-space: nowrap;
    }

    &__go-to-officevibe-button {
        display: flex;
        margin: r.replaceBy($space-5, --hop-space-inline-xl) auto 0 auto;

        animation: fade-in linear 400ms 400ms both,
            recognition-outro-slide-in ease-out 400ms 400ms both;
    }
}