@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.signup-slide-explanation {
    padding: 0 calc(1.6rem * var(--rem-ratio, 1));

    @include small {
        padding: 0 r.replaceBy($space-5, --hop-space-inset-xl);
    }

    &__title {
        margin-bottom: r.replaceBy($space-2, --hop-space-stack-md);

        @include heading-3;
        color: r.replaceBy($coral-950, --hop-status-neutral-text);
        text-align: center;

        @include small {
            margin-bottom: r.replaceBy($space-2, --hop-space-stack-sm);
        }
    }

    &__description {
        color: r.replaceBy($secondary-text, --hop-neutral-text);
        text-align: center;

        @include small {
            [data-brand="workleap"] & {
                @include small-text;

                color: var(--hop-neutral-text-weak);
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: r.replaceBy($space-3, --hop-space-stack-xl);

        @include small {
            margin-top: r.replaceBy($space-3, --hop-space-stack-lg);
        }
    }
}