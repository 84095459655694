@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.base-pulse-survey-question {
    padding: r.replaceBy($space-3 $space-5, --hop-space-inset-squish-md);

    &__header {
        margin-bottom: r.replaceBy($space-5, --hop-space-stack-xl);

        @include small {
            max-width: calc(76rem * var(--rem-ratio, 1));
            margin: 0 auto calc(4rem * var(--rem-ratio, 1)) auto;
        }
    }

    &__input {
        @include small {
            min-height: calc(16rem * var(--rem-ratio, 1));
        }
    }
}