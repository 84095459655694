@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.activities-flow {
    &__header {
        margin-bottom: calc(3.2rem * var(--rem-ratio, 1));

        @include small {
            margin-bottom: calc(7.2rem * var(--rem-ratio, 1));
        }
    }

    &__logo {
        display: block;
        width: calc(11rem * var(--rem-ratio, 1));
        margin: calc(3.2rem * var(--rem-ratio, 1)) auto 0 auto;

        [data-brand="workleap"] & {
            width: calc(12.4rem * var(--rem-ratio, 1));
        }

        @include small {
            width: calc(16rem * var(--rem-ratio, 1));
            height: calc(4.8rem * var(--rem-ratio, 1));
            margin-top: calc(6.4rem * var(--rem-ratio, 1));

            [data-brand="workleap"] & {
                width: calc(16.4rem * var(--rem-ratio, 1));
                height: auto;
            }
        }

        &--on-light-background {
            fill: $primary-text;
        }

        &--on-dark-background {
            fill: r.replaceBy($contrast-text, --hop-neutral-surface-strong);
        }
    }

    &__control-bar-section {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;

        pointer-events: none;
    }

    &__banner-section {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }

    &__tip {
        position: fixed;
        top: calc(3.2rem * var(--rem-ratio, 1));
        right: 0;
    }

    &--PulseSurveyPreview, &--CustomPollPreview {
        .activities-flow__logo {
            margin-top: calc(6.4rem * var(--rem-ratio, 1));
        }
    }

    &--recognition-customizemessage {
        .activities-flow__header {
            [data-brand="workleap"] & {
                display: none;

                @include small {
                    display: block;
                }
            }
        }
    }

    &--intro {
        .activities-flow__logo {
            animation: fade-in linear 300ms 300ms both;
        }
    }

    &--pending-between-steps .activities-flow__content-section,
    &--pending-between-activities .activities-flow__content-section {
        visibility: hidden;
    }

    &--step-slide-in .activities-flow__content-section {
        @include slide-in-keyframes("right", calc(6rem * var(--rem-ratio, 1)), step-slide-in-animation);

        animation: fade-in linear 400ms both,
            step-slide-in-animation ease-out 400ms both;
    }

    &--step-slide-out .activities-flow__content-section {
        @include slide-out-keyframes("left", calc(6rem * var(--rem-ratio, 1)), step-slide-out-animation);

        animation: fade-out linear 400ms both,
            step-slide-out-animation ease-in 400ms both;
    }

    &--activity-slide-in .activities-flow__content-section {
        @include slide-in-keyframes("right", calc(10rem * var(--rem-ratio, 1)), activity-slide-in-animation);

        animation: fade-in linear 800ms both,
            activity-slide-in-animation ease-out 800ms both;
    }

    &--activity-slide-out .activities-flow__content-section {
        @include slide-out-keyframes("left", calc(10rem * var(--rem-ratio, 1)), activity-slide-out-animation);

        animation: fade-out linear 800ms both,
            activity-slide-out-animation ease-in 800ms both;
    }

    &--step-slide-in-reverse .activities-flow__content-section {
        @include slide-in-keyframes("left", calc(6rem * var(--rem-ratio, 1)), step-slide-in-reverse-animation);

        animation: fade-in linear 400ms both,
            step-slide-in-reverse-animation ease-out 400ms both;
    }

    &--step-slide-out-reverse .activities-flow__content-section {
        @include slide-out-keyframes("right", calc(6rem * var(--rem-ratio, 1)), step-slide-out-reverse-animation);

        animation: fade-out linear 400ms both,
            step-slide-out-reverse-animation ease-in 400ms both;
    }

    &--outro {
        .activities-flow__logo {
            animation: fade-out linear 600ms both;

            [data-brand="workleap"] & {
                animation: none;
            }
        }

        .activities-flow__content-section {
            display: none;
        }
    }

    &--interactions-disabled {
        pointer-events: none;
    }
}