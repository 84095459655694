@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

body.with-recognition-intro {
    [data-brand="workleap"] & {
        --_recognition-slide-top: calc(32rem * var(--rem-ratio, 1));
        --_recognition-slide-top-mobile: calc(24rem * var(--rem-ratio, 1));
    }

    .activities-background {
        @keyframes recognition-intro-background-color-animation {
            0% { background-color: $background-light; }
            100% { background-color: $background-dark; }
        }

        animation: recognition-intro-background-color-animation linear 500ms 2000ms both;
    }

    #logo {
        @keyframes recognition-intro-logo-fill-animation {
            0% { fill: r.replaceBy($background-dark, --hop-neutral-surface-strong); }
            100% { fill: r.replaceBy($background-light, --hop-neutral-surface-strong); }
        }

        animation: recognition-intro-logo-fill-animation linear 500ms 2000ms both;
    }
}

.recognition-intro-step {
    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-index-popover;

        @include slide-out-keyframes("left", calc(6rem * var(--rem-ratio, 1)), recognition-intro-slide-out-animation);

        animation: fade-out linear 400ms 4000ms both,
            recognition-intro-slide-out-animation ease-in 400ms 4000ms both;

        pointer-events: none;

        @include small {
            animation: fade-out linear 400ms 5500ms both,
                recognition-intro-slide-out-animation ease-in 400ms 5500ms both;
        }
    }

    &__survey-sent-slide {
        position: absolute;
        /* stylelint-disable-next-line custom-property-no-missing-var-function */
        top: r.replaceBy(50%, --_recognition-slide-top-mobile);
        left: 50%;

        width: 100%;
        padding: calc(1.6rem * var(--rem-ratio, 1));

        transform: translate(-50%, -50%);

        animation: fade-out linear 500ms 2000ms forwards;

        @include small {
            /* stylelint-disable-next-line custom-property-no-missing-var-function */
            top: r.replaceBy(50%, --_recognition-slide-top);
        }
    }

    &__survey-sent-illustration, &__survey-sent-placeholder {
        display: block;
        width: calc(14.6rem * var(--rem-ratio, 1));
        height: calc(14.6rem * var(--rem-ratio, 1));
        margin: 0 auto;

        @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-intro-survey-sent-illustration-slide-in-animation);

        animation: fade-in linear 500ms 400ms both,
            recognition-intro-survey-sent-illustration-slide-in-animation ease-out 500ms 400ms both;

        @include small {
            width: calc(16.8rem * var(--rem-ratio, 1));
            height: calc(16.8rem * var(--rem-ratio, 1));
        }
    }

    &__survey-sent-title {
        @include heading-1;

        margin-top: r.replaceBy($space-0, --hop-space-stack-md);

        font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-lg-font-weight);
        text-align: center;

        @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), recognition-intro-survey-sent-title-slide-in-animation);

        animation: fade-in linear 500ms 700ms both,
            recognition-intro-survey-sent-title-slide-in-animation ease-out 500ms 700ms both;

        [data-brand="workleap"] & {
            @include heading-3;
        }

        @include small {
            margin-top: r.replaceBy($space-0, --hop-space-stack-xl);

            [data-brand="workleap"] & {
                @include heading-1;
            }
        }
    }

    &__make-someones-day-slide {
        position: absolute;
        /* stylelint-disable-next-line custom-property-no-missing-var-function */
        top: r.replaceBy(50%, --_recognition-slide-top-mobile);
        left: 50%;

        width: 100%;
        padding: calc(1.6rem * var(--rem-ratio, 1));

        transform: translate(-50%, -50%);

        @include small {
            /* stylelint-disable-next-line custom-property-no-missing-var-function */
            top: r.replaceBy(50%, --_recognition-slide-top);
        }
    }

    &__make-someones-day-title {
        @include heading-1;

        margin-top: r.replaceBy($space-0, --hop-space-stack-md);

        color: r.replaceBy($contrast-text, --hop-neutral-text);
        font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-lg-font-weight);
        text-align: center;

        @include slide-in-keyframes("right", calc(1.6rem * var(--rem-ratio, 1)), recognition-intro-make-someones-day-title-slide-in-animation);
        @include slide-out-keyframes("left", calc(6rem * var(--rem-ratio, 1)), recognition-intro-step-slide-out-animation);

        animation: fade-in linear 300ms 2400ms backwards,
            recognition-intro-make-someones-day-title-slide-in-animation ease-out 500ms 2400ms backwards,
            fade-out linear 400ms 4000ms forwards,
            recognition-intro-step-slide-out-animation ease-in 400ms 4000ms forwards;

        [data-brand="workleap"] & {
            @include heading-3;
        }

        @include small {
            margin-top: r.replaceBy($space-0, --hop-space-stack-xl);

            [data-brand="workleap"] & {
                @include heading-1;
            }
        }
    }
}