@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.base-custom-poll-question {
    max-width: calc(76rem * var(--rem-ratio, 1));
    margin: 0 auto;
    padding: r.replaceBy($space-3 $space-5, --hop-space-inset-squish-md);

    &__header {
        margin-bottom: r.replaceBy($space-5, --hop-space-stack-xl);

        [data-brand="workleap"] & {
            @include small {
                max-width: calc(76rem * var(--rem-ratio, 1));
                margin: 0 auto calc(4rem * var(--rem-ratio, 1)) auto;
            }
        }
    }
}