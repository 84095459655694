@import "@sass/utils/all";

.custom-poll-question-title {
    @include heading-2;

    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-lg);
    text-align: center;

    [data-brand="workleap"] & {
        @include heading-3;
    }
}