@import "@sass/utils/all";

.activities-banner {
    @include slide-in-keyframes("top", calc(4rem * var(--rem-ratio, 1)), activities-banner-slide-in-animation);

    animation: fade-in linear 400ms both,
        activities-banner-slide-in-animation ease-out 400ms both;

    &--hidden {
        @include slide-out-keyframes("top", calc(4rem * var(--rem-ratio, 1)), activities-banner-slide-out-animation);

        animation: fade-out linear 600ms both,
            activities-banner-slide-out-animation ease-out 600ms both;
    }
}