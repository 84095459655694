@import "./theme";

// Transitions
$short-hover-transition-duration: 200ms;

@mixin short-hover-transitions($properties...) {
    transition: build-transition($short-hover-transition-duration, $properties...);
}

@mixin custom-hover-transitions($duration, $properties...) {
    transition: build-transition($duration, $properties...);
}

@function build-transition($duration, $properties...) {
    $final-transitions: null;
    @each $property in $properties {
        $final-transitions: #{$final-transitions, $property $duration};
    }

    @return $final-transitions;
}

@mixin flex() {
    flex: 1 1 100%;
    min-width: 0;
    min-height: 0;
}

@mixin fancy-webkit-scrollbar($background-color: $surface, $scrollbar-color: $surface-scrollbar, $scrollbar-width: calc(1rem * var(--rem-ratio, 1))) {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: $scrollbar-width;

        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-color;
        border: calc(0.2rem * var(--rem-ratio, 1)) solid $background-color;
        border-radius: calc(0.5rem * var(--rem-ratio, 1));
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}