@import "./theme";

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loading-pulse-dark {
    0%, 100% {
        background-color: $surface-loading;
        opacity: 0.01;
    }
    50% {
        background-color: $surface-loading;
        opacity: 0.35;
    }
}

@keyframes loading-pulse-light {
    0%, 100% {
        background-color: $surface-loading;
        opacity: 0.01;
    }
    50% {
        background-color: $surface-loading;
        opacity: 0.10;
    }
}

@mixin slide-in-keyframes($direction, $distance, $name) {
    @if $direction == "right" {
        @keyframes #{$name} {
            0% { transform: translateX($distance); }
            100% { transform: translateX(0); }
        }
    }
    @else if $direction == "left" {
        @keyframes #{$name} {
            0% { transform: translateX(calc(0px - $distance)); }
            100% { transform: translateX(0); }
        }
    }
    @else if $direction == "top" {
        @keyframes #{$name} {
            0% { transform: translateY(calc(0px - $distance)); }
            100% { transform: translateY(0); }
        }
    }
    @else {
        @keyframes #{$name} {
            0% { transform: translateY($distance); }
            100% { transform: translateY(0); }
        }
    }
}

@mixin slide-out-keyframes($direction, $distance, $name) {
    @if $direction == "right" {
        @keyframes #{$name} {
            0% { transform: translateX(0); }
            100% { transform: translateX($distance); }
        }
    }
    @else if $direction == "left" {
        @keyframes #{$name} {
            0% { transform: translateX(0); }
            100% { transform: translateX(calc(0px - $distance)); }
        }
    }
    @else if $direction == "top" {
        @keyframes #{$name} {
            0% { transform: translateY(0); }
            100% { transform: translateY(calc(0px - $distance)); }
        }
    }
    @else {
        @keyframes #{$name} {
            0% { transform: translateY(0); }
            100% { transform: translateY($distance); }
        }
    }
}