@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.select {
    position: relative;

    margin: calc(0.4rem * var(--rem-ratio, 1)) 0;

    &--open .select__control-arrow {
        transform: translateY(-50%) scale(-1);
    }

    &__control {
        position: relative;

        display: block;
        width: 100%;
        height: calc(4rem * var(--rem-ratio, 1));
        padding: 0 calc(4rem * var(--rem-ratio, 1)) 0 calc(1.6rem * var(--rem-ratio, 1));

        background-color: r.replaceBy(initial, --hop-neutral-surface);

        border: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-1px) solid r.replaceBy($inputs-border, --hop-neutral-border);
        border-radius: r.replaceBy($border-radius-sm, --hop-shape-rounded-md);

        &:focus {
            border-color: $focus-outline;
            outline: none;
        }
    }

    &__control-arrow {
        position: absolute;
        top: 50%;
        right: calc(0.8rem * var(--rem-ratio, 1));

        color: r.replaceBy($secondary-text, --hop-neutral-icon-weak);

        transform: translateY(-50%);

        transition: transform linear 200ms;

        pointer-events: none;
    }

    &__popover {
        position: absolute;
        top: r.replaceBy(calc(100% + calc(0.8rem * var(--rem-ratio, 1))), --_100-percent);
        right: 0;
        left: 0;
        z-index: $z-index-popover;

        max-height: calc(23.2rem * var(--rem-ratio, 1));
        padding: calc(0.8rem * var(--rem-ratio, 1)) 0;
        overflow-y: auto;

        background-color: $surface;

        @keyframes select-popover-slide-in-animation {
            0% { transform: translateY(calc(1.6rem * var(--rem-ratio, 1))); }
            100% { transform: translateY(0); }
        }

        animation: fade-in linear 200ms,
            select-popover-slide-in-animation ease-out 200ms;
    }

    &__option-group-separator {
        height: calc(0.1rem * var(--rem-ratio, 1));
        margin: calc(0.8rem * var(--rem-ratio, 1)) calc(1.6rem * var(--rem-ratio, 1));

        background-color: r.replaceBy($inputs-border, --hop-neutral-border);

        border: none;
    }
}