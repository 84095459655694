@import "@sass/utils/all";

$initialDelay: 600ms;
$offsetDelay: 150ms;

.illustrated-background {
    display: none;

    @include small {
        display: block;
    }

    @keyframes scribble-fade-in {
        0% { opacity: 0; }
        100% { opacity: 0.5; }
    }
    @keyframes scribble-entrance-animation {
        0% { transform: translate(-50%, -50%) scale(0); }
        100% { transform: translate(-50%, -50%) scale(1); }
    }
    $scribble-entrance-easing: cubic-bezier(.38,1.99,.64,1);

    &__lightning {
        $delay: $initialDelay;

        position: absolute;
        top: 17%;
        left: 11%;

        width: 9%;

        opacity: 0.5;

        animation: scribble-fade-in linear 300ms $delay both,
            scribble-entrance-animation $scribble-entrance-easing 600ms $delay both;

        stroke: $background-scribble;
        stroke-miterlimit: 10;
        stroke-width: 5
    }

    &__heart {
        $delay: $initialDelay + 5 * $offsetDelay;

        position: absolute;
        top: 47%;
        left: 21%;

        width: 6%;

        opacity: 0.5;

        animation: scribble-fade-in linear 300ms $delay both,
            scribble-entrance-animation $scribble-entrance-easing 600ms $delay both;

        stroke: $background-scribble;
        stroke-miterlimit: 10;
        stroke-width: 5
    }

    &__curve-line {
        $delay: $initialDelay + 2 * $offsetDelay;

        position: absolute;
        top: 78%;
        left: 30%;

        width: 15%;

        opacity: 0.5;

        animation: scribble-fade-in linear 300ms $delay both,
            scribble-entrance-animation $scribble-entrance-easing 600ms $delay both;

        stroke: $background-scribble;
        stroke-miterlimit: 10;
        stroke-width: 5
    }

    &__zigzag {
        $delay: $initialDelay + 3 * $offsetDelay;

        position: absolute;
        top: 14%;
        left: 79%;

        width: 7%;

        opacity: 0.5;

        animation: scribble-fade-in linear 300ms $delay both,
            scribble-entrance-animation $scribble-entrance-easing 600ms $delay both;

        stroke: $background-scribble;
        stroke-miterlimit: 10;
        stroke-width: 5
    }

    &__curve-arrow {
        $delay: $initialDelay + 1 * $offsetDelay;

        position: absolute;
        top: 38%;
        left: 91%;

        width: 26%;

        opacity: 0.5;

        animation: scribble-fade-in linear 300ms $delay both,
            scribble-entrance-animation $scribble-entrance-easing 600ms $delay both;

        stroke: $background-scribble;
        stroke-miterlimit: 10;
        stroke-width: 5
    }

    &__explosion {
        $delay: $initialDelay + 4 * $offsetDelay;

        position: absolute;
        top: 69%;
        left: 74%;

        width: 10%;

        opacity: 0.5;

        animation: scribble-fade-in linear 300ms $delay both,
            scribble-entrance-animation $scribble-entrance-easing 600ms $delay both;

        stroke: $background-scribble;
        stroke-miterlimit: 10;
        stroke-width: 5
    }

    &__hidden {
        animation: fade-out linear 400ms both;
    }
}