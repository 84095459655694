@use '@sass/workleap-rebrand/replace-by' as r;
@use "@sass/workleap-rebrand/semantic";
@use "@sass/workleap-rebrand/custom-tokens";
@import "@sass/normalize";
@import "@sass/reset";
@import "@sass/variables";
@import "@sass/fonts";
@import "@sass/utils/all";

@import "@hopper-ui/icons/index.css";
@import "@hopper-ui/styled-system/index.css";
@import "@hopper-ui/tokens/tokens.css";

html {
    font-size: r.replaceBy(10px, --_font-size-base);
}

body {
    color: $primary-text;

    @include medium-text;

    font-weight: r.replaceBy(var(--font-weight-regular), --hop-body-md-font-weight);

    background-color: r.replaceBy($background-light, --hop-decorative-option7-surface-weak);
}

:root {
    --rem-ratio: 1;
}

[data-brand="workleap"] {
    --rem-ratio: 0.625;
}