@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.peer-select-placeholder {
    padding: r.replaceBy(calc(0.7rem * var(--rem-ratio, 1)), --hop-space-inset-sm) r.replaceBy($space-3, --hop-space-inset-md) r.replaceBy(calc(0.7rem * var(--rem-ratio, 1)), --hop-space-inset-sm) 0;

    @include medium-text;
    color: $placeholder-text;

    font-weight: r.replaceBy($font-weight-regular, --hop-body-md-font-weight);

    user-select: none;
}