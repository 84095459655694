@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.deib-survey-question-title {
    @include heading-2;

    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-lg);

    [data-brand="workleap"] & {
        @include heading-3;
    }

    text-align: center;
}