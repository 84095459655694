@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.anonymity-banner {
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(3.2rem * var(--rem-ratio, 1));

        color: r.replaceBy(initial, --hop-primary-text-strong);
        white-space: nowrap;

        background-color: $surface-anonymity-banner;

        &--mobile {
            @include large {
                display: none;
            }

            [data-brand="workleap"] & {
                padding: var(--hop-space-inset-xs) var(--hop-space-inset-xl);
            }
        }

        &--desktop {
            display: none;

            @include large {
                display: flex;

                [data-brand="workleap"] & {
                    height: calc(4rem * var(--rem-ratio, 1));
                    padding: var(--hop-space-inset-sm) var(--hop-space-inset-xl);
                }
            }
        }
    }

    &__icon {
        flex-shrink: 0;
        width: calc(2.4rem * var(--rem-ratio, 1));
        height: calc(2.4rem * var(--rem-ratio, 1));
        margin-right: r.replaceBy($space-2, --hop-space-inline-sm);

        fill: r.replaceBy(currentColor, --hop-warning-icon-weakest);
    }

    &__link {
        margin: 0 r.replaceBy($space-1, --hop-space-inline-xs);
        
        color: r.replaceBy($primary-text, --hop-primary-text-strong);
        
        font-weight: var(--font-weight-semibold);

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &--collapsed {
            margin-right: 0;
        }
    }
}