@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.peer-select-value {
    display: flex;
    align-items: center;
    padding: r.replaceBy($space-1, --hop-space-inset-squish-md);

    [data-brand="workleap"] & {
        @include medium-text;
        font-weight: var(--hop-body-md-font-weight);
    }

    &__thumbnail {
        flex-shrink: 0;
        width: calc(3.2rem * var(--rem-ratio, 1));
        height: calc(3.2rem * var(--rem-ratio, 1));
        margin-right: r.replaceBy(calc(1.2rem * var(--rem-ratio, 1)), --hop-space-inline-sm);

        border-radius: r.replaceBy(ar(--border-radius-sm), --hop-shape-circle);

        [data-brand="workleap"] & {
            width: calc(2.4rem * var(--rem-ratio, 1));
            height: calc(2.4rem * var(--rem-ratio, 1));
        }
    }

    &__fullname {
        flex: 1 1 0%;
        min-width: 0;
        overflow: hidden;

        color: $primary-text-alt;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}