:root {
    // Colors
    --electric-blue-50: #EDF3FE;
    --electric-blue-100: #C1D3FE;
    --electric-blue-200: #95B3FE;
    --electric-blue-300: #6893FF;
    --electric-blue-400: #3C73FF;
    --electric-blue-500: #1053FF;
    --electric-blue-600: #0C46D0;
    --electric-blue-700: #0939A2;
    --electric-blue-800: #052C73;
    --electric-blue-900: #011F44;
    --sky-50: #E5F4FB;
    --sky-100: #B2DDF2;
    --sky-200: #7FC6EA;
    --dandelion-50: #FEF8E2;
    --dandelion-100: #FDEBB6;
    --dandelion-200: #FDDE88;
    --dandelion-300: #FCD35A;
    --dandelion-400: #FBC73B;
    --dandelion-500: #FABF2C;
    --dandelion-600: #F9B127;
    --coral-50: #F9EEEF;
    --coral-100: #FFD8D2;
    --coral-200: #FFC3BA;
    --coral-300: #FF988B;
    --coral-400: #FF7160;
    --coral-500: #F96155;
    --coral-600: #E24D48;
    --coral-700: #BD494A;
    --coral-800: #9A3842;
    --coral-900: #6B2837;
    --coral-950: #422439;
    --seaweed-50: #D6F9F3;
    --seaweed-100: #BBEFE4;
    --seaweed-200: #9FE9DC;
    --seaweed-300: #85D9C9;
    --seaweed-400: #30C9AD;
    --seaweed-500: #0CBA9D;
    --seaweed-600: #00A587;
    --seaweed-700: #00977A;
    --seaweed-800: #00765B;
    --seaweed-900: #00593F;
    --strawberry-fields-50: #F9E4E6;
    --strawberry-fields-100: #F1BBC1;
    --strawberry-fields-200: #E7919A;
    --strawberry-fields-300: #DB6A74;
    --creamsicle-25: #F9F7F6;
    --creamsicle-50: #FFF6EB;
    --creamsicle-75: #FFF0DF;
    --creamsicle-100: #FFE3C2;
    --creamsicle-150: #FFCB96;
    --creamsicle-200: #FFA878;
    --creamsicle-300: #FA8742;
    --grey-95: #F8F8F8;
    --grey-100: #F7F9FA;
    --grey-200: #EFF1F5;
    --grey-250: #E8EBEF;
    --grey-300: #D5D9DE;
    --grey-400: #BDC6D0;
    --grey-500: #838B95;
    --grey-600: #606B79;
    --grey-700: #32455B;
    --grey-800: #233043;
    --grey-900: #19222F;
    --white: #FFFFFF;

    // Fonts
    --primary-font-family: Inter;
    --secondary-font-family: GT-Walsheim;

    // Font weights
    --font-weight-thin: 100;
    --font-weight-extra-light: 200;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --font-weight-black: 900;

    // Font sizes
    --font-size-1: calc(1rem * var(--rem-ratio, 1));
    --font-size-2: calc(1.2rem * var(--rem-ratio, 1));
    --font-size-3: calc(1.4rem * var(--rem-ratio, 1));
    --font-size-4: calc(1.6rem * var(--rem-ratio, 1));
    --font-size-5: calc(1.8rem * var(--rem-ratio, 1));
    --font-size-6: calc(2.2rem * var(--rem-ratio, 1));
    --font-size-7: calc(2.4rem * var(--rem-ratio, 1));
    --font-size-8: calc(3rem * var(--rem-ratio, 1));
    --font-size-9: calc(3.2rem * var(--rem-ratio, 1));
    --font-size-10: calc(4.8rem * var(--rem-ratio, 1));
    --line-height-xxxl: 1.5;
    --line-height-xxl: 1.4285;
    --line-height-xl: 1.33333333;
    --line-height-lg: 1.27;
    --line-height-md: 1.25;
    --line-height-sm: 1.2;
    --line-height-xs: 1.166;
    --line-height-xxs: 1.14;
    --line-height-xxxs: 1.125;

    // Breakpoints
    --breakpoints-sm: 600px;
    --breakpoints-md: 768px;
    --breakpoints-lg: 992px;
    --breakpoints-xl: 1200px;
    --breakpoints-xxl: 1440px;

    // Border radius
    --border-radius-none: 0;
    --border-radius-xs: calc(0.2rem * var(--rem-ratio, 1));
    --border-radius-sm: calc(0.4rem * var(--rem-ratio, 1));
    --border-radius-md: calc(0.6rem * var(--rem-ratio, 1));
    --border-radius-xl: calc(1.6rem * var(--rem-ratio, 1));
    --border-radius-card: calc(0.4rem * var(--rem-ratio, 1));

    // Shadow
    --shadow-6: 0 calc(0.1rem * var(--rem-ratio, 1)) calc(0.6rem * var(--rem-ratio, 1)) 0 rgba(#233043, 0.1);
    --shadow-12: 0 calc(0.4rem * var(--rem-ratio, 1)) calc(1rem * var(--rem-ratio, 1)) calc(0.4rem * var(--rem-ratio, 1)) rgba(#233043, 0.1);
    --shadow-24: 0 calc(1rem * var(--rem-ratio, 1)) calc(1.8rem * var(--rem-ratio, 1)) calc(0.8rem * var(--rem-ratio, 1)) rgba(#233043, 0.1);

    // Spacing
    --space-0: 0;
    --space-1: calc(0.4rem * var(--rem-ratio, 1));
    --space-2: calc(0.8rem * var(--rem-ratio, 1));
    --space-3: calc(1.6rem * var(--rem-ratio, 1));
    --space-4: calc(2.4rem * var(--rem-ratio, 1));
    --space-5: calc(3.2rem * var(--rem-ratio, 1));
    --space-6: calc(4rem * var(--rem-ratio, 1));
    --space-7: calc(4.8rem * var(--rem-ratio, 1));
    --space-8: calc(6.4rem * var(--rem-ratio, 1));
    --space-9: calc(8rem * var(--rem-ratio, 1));
    --space-10: calc(9.6rem * var(--rem-ratio, 1));

    // Z-Indexes
    --z-index-min: -1;
    --z-index-max: 999;
}