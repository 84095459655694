@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.custom-poll-text-question {
    max-width: calc(76rem * var(--rem-ratio, 1));
    margin: 0 auto;
    padding: r.replaceBy($space-3, --hop-space-inset-md) r.replaceBy($space-5, --hop-space-inset-md);

    &--confirmed {
        pointer-events: none;
    }

    &__header {
        margin-bottom: r.replaceBy($space-3, --hop-space-stack-lg);

        @include small {
            margin-bottom: r.replaceBy($space-5, --hop-space-stack-lg);
        }
    }
}