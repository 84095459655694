@import "@sass/utils/all";

.recognition-prompt-stack {
    position: relative;

    width: 100%;
    height: calc(25rem * var(--rem-ratio, 1));

    &--go-to-previous-card {
        .recognition-prompt-stack__current-card {
            @keyframes previous-current-card-position-adjustment-animation {
                0% {
                    transform: translateX(0) rotate(0);
                }
                100% {
                    transform: translateX(calc(-0.8rem * var(--rem-ratio, 1))) rotate(-3.74deg);
                }
            }

            @keyframes previous-current-card-background-color-animation {
                0% { background-color: $surface; }
                100% { background-color: $surface-recognition-card-in-stack; }
            }

            animation: previous-current-card-position-adjustment-animation ease-in-out 400ms 100ms both,
                previous-current-card-background-color-animation linear 200ms both;
        }

        .recognition-prompt-stack__next-card {
            @keyframes previous-next-card-position-adjustment-animation {
                0% {
                    transform: translateX(calc(-0.8rem * var(--rem-ratio, 1))) rotate(-3.74deg);
                }
                100% {
                    transform: translateX(calc(0.8rem * var(--rem-ratio, 1))) rotate(4.82deg);
                }
            }

            animation: previous-next-card-position-adjustment-animation ease-in-out 400ms 200ms both;
        }

        .recognition-prompt-stack__previous-card {
            @keyframes previous-card-flick-up-animation {
                0% {
                    transform: translateX(calc(0.8rem * var(--rem-ratio, 1))) rotate(4.82deg);
                }
                100% {
                    transform: translateY(calc(-27rem * var(--rem-ratio, 1))) rotate(17deg);
                }
            }

            @keyframes previous-card-flick-down-animation {
                0% {
                    z-index: 4;

                    transform: translateY(calc(-27rem * var(--rem-ratio, 1))) rotate(17deg);
                }
                100% {
                    z-index: 4;

                    transform: translate(0, 0) rotate(0);
                }
            }

            @keyframes previous-card-background-color-animation {
                0% { background-color: $surface-recognition-card-in-stack }
                100% { background-color: $surface; }
            }

            animation: previous-card-flick-up-animation ease-in-out 200ms forwards,
                previous-card-flick-down-animation ease-in-out 400ms 200ms forwards,
                previous-card-background-color-animation linear 200ms 400ms;
        }
    }

    &--go-to-next-card {
        .recognition-prompt-stack__current-card {
            @keyframes current-card-flick-up-animation {
                0% { transform: translateY(0) rotate(0); }
                100% { transform: translateY(calc(-27rem * var(--rem-ratio, 1))) rotate(17deg); }
            }

            @keyframes current-card-flick-down-animation {
                0% {
                    z-index: 1;

                    transform: translateY(calc(-27rem * var(--rem-ratio, 1))) rotate(17deg);
                }
                100% {
                    z-index: 1;

                    transform: translate(calc(0.8rem * var(--rem-ratio, 1)), 0) rotate(4.82deg);
                }
            }

            @keyframes current-card-background-color-animation {
                0% { background-color: $surface; }
                100% { background-color: $surface-recognition-card-in-stack }
            }

            animation: current-card-flick-up-animation ease-in-out 200ms,
                current-card-flick-down-animation ease-in-out 400ms 200ms forwards,
                current-card-background-color-animation linear 200ms 400ms both;
        }

        .recognition-prompt-stack__next-card {
            @keyframes next-card-background-color-animation {
                0% { background-color: $surface-recognition-card-in-stack }
                100% { background-color: $surface; }
            }

            @keyframes next-card-position-adjustment-animation {
                0% { transform: translateX(calc(-0.8rem * var(--rem-ratio, 1))) rotate(-3.74deg); }
                100% { transform: translateX(0) rotate(0); }
            }

            animation: next-card-background-color-animation linear 200ms 100ms both,
                next-card-position-adjustment-animation ease-in-out 400ms 200ms both;
        }

        .recognition-prompt-stack__second-next-card {
            @keyframes second-next-card-position-adjustment-animation {
                0% { transform: translateX(calc(0.8rem * var(--rem-ratio, 1))) rotate(4.82deg); }
                100% { transform: translateX(calc(-0.8rem * var(--rem-ratio, 1))) rotate(-3.74deg); }
            }

            animation: second-next-card-position-adjustment-animation ease-in-out 200ms 400ms both;
        }
    }

    &__cards {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 100%;
        max-width: calc(32.8rem * var(--rem-ratio, 1));
        height: calc(22rem * var(--rem-ratio, 1));

        transform: translate(-50%, -50%);
    }

    &__current-card {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;

        transform-origin: center;
    }

    &__next-card {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        background-color: $surface-recognition-card-in-stack;

        transform: translateX(calc(-0.8rem * var(--rem-ratio, 1))) rotate(-3.74deg);
        transform-origin: center;
    }

    &__second-next-card {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        background-color: $surface-recognition-card-in-stack;

        transform: translateX(calc(0.8rem * var(--rem-ratio, 1))) rotate(4.82deg);
        transform-origin: center;
    }

    &__previous-card {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        background-color: $surface-recognition-card-in-stack;

        transform: translateX(calc(0.8rem * var(--rem-ratio, 1))) rotate(4.82deg);
        transform-origin: center;
    }
}