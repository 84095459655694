@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.recognition-card-image {
    position: relative;

    padding: r.replaceBy($space-3, --hop-space-inset-md);

    border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-rounded-sm);

    @include small {
        padding: r.replaceBy($space-4, --hop-space-inset-lg) r.replaceBy($space-4, --hop-space-inset-lg) calc(25.2rem * var(--rem-ratio, 1)) r.replaceBy($space-4, --hop-space-inset-lg);
    }

    @include medium {
        padding: r.replaceBy($space-5, --hop-space-inset-xl) r.replaceBy($space-5, --hop-space-inset-xl) calc(26.4rem * var(--rem-ratio, 1)) r.replaceBy($space-5, --hop-space-inset-xl);

        [data-brand="workleap"] & {
            padding-bottom: calc(34rem * var(--rem-ratio, 1))
        }
    }

    [data-brand="workleap"] & {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &--creamsicle {
        background-color: $surface-recognition-card-creamsicle;

        .recognition-card-image__image {
            fill: r.replaceBy($surface-recognition-image-creamsicle, --hop-neutral-icon);
        }
    }

    &--seaweed {
        background-color: $surface-recognition-card-seaweed;

        .recognition-card-image__image {
            fill: r.replaceBy($surface-recognition-image-seaweed, --hop-neutral-icon);
        }
    }

    &--electric-blue {
        background-color: $surface-recognition-card-electric-blue;

        .recognition-card-image__image {
            fill: r.replaceBy($surface-recognition-image-electric-blue, --hop-neutral-icon);
        }
    }

    &--dandelion {
        background-color: $surface-recognition-card-dandelion;

        .recognition-card-image__image {
            fill: r.replaceBy($surface-recognition-image-dandelion, --hop-neutral-icon);
        }
    }

    &--sky {
        background-color: $surface-recognition-card-sky;

        .recognition-card-image__image {
            fill: r.replaceBy($surface-recognition-image-sky, --hop-neutral-icon);
        }
    }

    &--coral {
        background-color: $surface-recognition-card-coral;

        .recognition-card-image__image {
            fill: r.replaceBy($surface-recognition-image-coral, --hop-neutral-icon);
        }
    }

    &--strawberry-fields {
        background-color: $surface-recognition-card-strawberry-fields;

        .recognition-card-image__image {
            fill: r.replaceBy($surface-recognition-image-strawberry-fields, --hop-neutral-icon);
        }
    }

    &__prompt {
        display: block;
        margin-top: r.replaceBy($space-2, --hop-space-stack-sm);

        color: $primary-text-alt;

        @include heading-4;
        font-weight: r.replaceBy(var(--font-weight-medium), --hop-heading-sm-font-weight);

        overflow-wrap: break-word;

        @include small {
            margin-top: r.replaceBy($space-3, --hop-space-stack-md);

            @include heading-3;
        }

        @include medium {
            margin-top: r.replaceBy($space-4, --hop-space-stack-lg);

            @include heading-display-xlarge;

            [data-brand="workleap"] & {
                @include heading-1;
            }
        }
    }

    &__image {
        display: none;

        @include small {
            position: absolute;
            bottom: calc(0.8rem * var(--rem-ratio, 1));
            left: calc(0.8rem * var(--rem-ratio, 1));

            display: block;
            width: calc(100% - calc(1.6rem * var(--rem-ratio, 1)));
            max-height: calc(26.5rem * var(--rem-ratio, 1));
        }
    }
}