@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.signup-profile-slide {
    display: flex;
    flex-direction: column;

    &__header {
        flex-shrink: 0;
    }

    &__form {
        display: flex;
        flex: 0 1 100%;
        flex-direction: column;
        gap: r.replaceBy(0, --hop-space-stack-md);
        min-width: 0;

        @include ie {
            flex: 0 0 auto;
        }
    }

    &__form-row + &__form-row {
        margin-top: calc(0.8rem * var(--rem-ratio, 1));
    }

    &__footer {
        flex-shrink: 0;
    }

    &__full-name-form-row {
        @include small {
            display: flex;
            align-items: flex-start;
        }
    }

    &__first-name-field {
        margin-bottom: r.replaceBy(0, --hop-space-stack-md);

        @include small {
            flex: 1 0 0%;
            min-width: 0;
            margin-bottom: 0;
        }
    }

    &__last-name-field {
        @include small {
            flex: 1 0 0%;
            min-width: 0;
            margin-left: calc(1.6rem * var(--rem-ratio, 1));
        }
    }
}