// Tokens added to use, as values non included in Workleap tokens, in replaceBy
/* stylelint-disable workleap/no-naked-rem  */
[data-brand="workleap"] {
    --_font-size-base: 16px;
    --_border-1px: 1px;
    --_border-2px: 2px;
    --_100-percent: 100%;
    --_transparent: transparent;
    --_9-rem: 9rem;
    --_auto: auto;
    --_input-margin: --ids-input-margin;
    --_padding-none: 0;
    --_height-2_5-rem: 2.5rem;
    --_signup-activation-feedback-explanation-slide-image-mobile-padding-x: 1.65rem;
    --_signup-activation-feedback-explanation-slide-image-mobile-padding-y: 2.5rem;
    --_signup-activation-slide-image-mobile-padding-top: 1.15rem;
    --_signup-activation-slide-image-mobile-width: 15rem;
    --_signup-activation-slide-image-small-width: 20rem;
    --_signup-activation-slide-image-margin-bottom: -2px;
    --_justify-content-center: center;
    --_display-flex: flex;
}
