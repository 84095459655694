.custom-poll-question-asked-by {
    &__author {
        border-radius: var(--border-radius-sm);

        &--landscape {
            width: 100%;
            height: auto;
        }

        &--portrait {
            display: flex;

            width: auto;
            height: 100%;
        }
    }
}