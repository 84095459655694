@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.visibility-disclaimer {
    display: inline-flex;

    color: r.replaceBy($secondary-text, --hop-neutral-text-weak);

    @include small-text;

    &__icon {
        flex-shrink: 0;
        margin-right: r.replaceBy($space-2, --hop-space-inline-sm);
    }

    &__label {
        flex: 1 1 auto;
        min-width: 0;
    }
}