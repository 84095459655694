@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.dismissable-tip {
    display: none;

    @include small {
        position: relative;

        display: block;
        max-width: calc(42rem * var(--rem-ratio, 1));

        background-color: r.replaceBy($surface, --hop-neutral-surface);
        border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-rounded-md);
        box-shadow: 0 calc(0.4rem * var(--rem-ratio, 1)) calc(1rem * var(--rem-ratio, 1)) calc(0.4rem * var(--rem-ratio, 1)) rgba(#233043, 0.1);

        cursor: pointer;

        transition: transform ease-in-out 200ms;

        [data-brand="workleap"] & {
            border: calc(0.16rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-weak);
            box-shadow: var(--hop-shadow-md);
        }
    }

    &:hover {
        @include media-hover {
            transform: translateY(calc(-0.1rem * var(--rem-ratio, 1)));
        }
    }

    &:focus-visible {
        position: relative;

        outline: none;

        &:after {
            position: absolute;
            top: calc(-0.4rem * var(--rem-ratio, 1));
            right: calc(-0.4rem * var(--rem-ratio, 1));
            bottom: calc(-0.4rem * var(--rem-ratio, 1));
            left: calc(-0.4rem * var(--rem-ratio, 1));

            display: block;

            border: calc(0.1rem * var(--rem-ratio, 1)) solid r.replaceBy($primary-text, --hop-primary-border-focus);
            border-radius: r.replaceBy($space-2, --hop-border-radius-2);

            content: '';

            [data-brand="workleap"] & {
                border-width: calc(0.16rem * var(--rem-ratio, 1));
            }
        }
    }

    &__container {
        display: flex;
    }

    &__icon {
        display: flex;
        align-items: center;
        padding: r.replaceBy(calc(1.2rem * var(--rem-ratio, 1)), --hop-space-inset-md);

        border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);

        &--info {
            background-color: r.replaceBy(var(--sky-100), --hop-neutral-surface);
        }

        & > svg {
            width: calc(4.8rem * var(--rem-ratio, 1));
            height: calc(4.8rem * var(--rem-ratio, 1));

            [data-brand="workleap"] & {
                width: calc(4rem * var(--rem-ratio, 1));
                height: calc(4rem * var(--rem-ratio, 1));

                border-radius: var(--hop-shape-circle);
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        width: 100%;

        padding: r.replaceBy($space-3, --hop-space-inset-md);

        [data-brand="workleap"] & {
            padding-left: 0;
        }
    }

    &__title {
        margin-bottom: r.replaceBy($space-1, --hop-space-stack-sm);

        color: r.replaceBy($secondary-text, --hop-neutral-text-weak);

        @include overline;

        font-family: r.replaceBy(var(--primary-font-family), --hop-overline-font-family);

        [data-brand="workleap"] & {
            line-height: calc(1rem * var(--rem-ratio, 1));
        }
    }

    &__subtitle {
        color: r.replaceBy(var(--grey-800), --hop-neutral-text);

        @include small-text;
    }

    &__dismiss-button {
        position: absolute;
        top: calc(0.8rem * var(--rem-ratio, 1));
        right: calc(0.8rem * var(--rem-ratio, 1));

        color: r.replaceBy($surface-close-button, --hop-neutral-icon-weakest);
        font-size: 0;

        &:hover {
            color: r.replaceBy($surface-close-button-hovered, --neutral-icon-hover);
        }

        [data-brand="workleap"] & {
            top: calc(0.5rem * var(--rem-ratio, 1));
            right: calc(0.5rem * var(--rem-ratio, 1));
        }
    }
}