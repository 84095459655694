@import "@sass/utils/all";

.inline-labeled-form-field {
    @include small {
        display: flex;
        align-items: center;
    }

    &__label {
        @include small-text;
        color: $secondary-text;
        white-space: nowrap;

        @include small {
            flex-shrink: 0;
            margin-right: calc(0.8rem * var(--rem-ratio, 1));
        }
    }

    &__content {
        @include small {
            flex: 1 1 auto;
            min-width: 0;
        }
    }
}