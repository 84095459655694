@use '@sass/workleap-rebrand/replace-by' as r;

$background-light: r.replaceBy(var(--creamsicle-50), --hop-decorative-option3-surface-weakest);
$background-dark: r.replaceBy(var(--coral-950), --hop-decorative-option7-surface);
$background-scribble: r.replaceBy(var(--coral-900), --hop-decorative-option7-surface-weakest);

$brand-primary: var(--electric-blue-500);
$brand-primary-accent: var(--electric-blue-600);
$brand-primary-dim: var(--electric-blue-300);

$brand-highlight: var(--dandelion-200);
$brand-highlight-accent: var(--dandelion-300);
$brand-highlight-dim: var(--dandelion-100);

$error: var(--coral-500);
$success: var(--seaweed-500);
$warning: var(--dandelion-500);

$surface-anonymity-banner: r.replaceBy(var(--creamsicle-200), --hop-decorative-option9-surface-strong);
$surface-previewing-banner: r.replaceBy(var(--creamsicle-200), --hop-decorative-option9-surface-strong);
$surface-recognition-banner: r.replaceBy(var(--creamsicle-100), --hop-decorative-option9-surface);
$surface-control-bar-light: var(--white);
$surface-control-bar-dark: var(--coral-900);
$surface: r.replaceBy(var(--white), --hop-neutral-surface);
$surface-disabled: var(--grey-200);
$surface-input: var(--grey-300);
$surface-highlight: var(--electric-blue-50);
$surface-scrollbar: var(--grey-300);
$surface-toggle-off: var(--grey-300);
$surface-toggle-on: var(--seaweed-500);
$surface-toggle-thumb: var(--white);
$surface-close-button: var(--grey-400);
$surface-close-button-hovered: var(--coral-950);
$surface-loading: var(--coral-950);

$primary-text: r.replaceBy(var(--coral-950), --hop-neutral-text);
$primary-text-alt: r.replaceBy(var(--grey-800), --hop-neutral-text);
$secondary-text: var(--grey-600);
$secondary-text-accent: var(--grey-700);
$contrast-text: r.replaceBy(var(--creamsicle-75), --hop-neutral-text);
$disabled-text: r.replaceBy(var(--grey-500), --hop-neutral-text-disabled);
$placeholder-text: var(--grey-400);
$warning-text: var(--coral-500);

$inputs-border: var(--grey-300);
$inputs-hover-border: var(--grey-500);
$inputs-disabled-border: var(--grey-200);

$focus-outline: r.replaceBy(var(--coral-950), --hop-primary-border-focus);

// Buttons
$surface-primary-button-dandelion: var(--dandelion-400);
$surface-primary-button-dandelion-hovered: var(--dandelion-600);
$surface-primary-button-dandelion-disabled: var(--dandelion-200);
$primary-button-dandelion-text: var(--coral-950);

$surface-primary-button-coral: r.replaceBy(var(--coral-900), --hop-neutral-surface-strong);
$surface-primary-button-coral-hovered: r.replaceBy(var(--coral-950), --hop-neutral-text-hover);
$surface-primary-button-coral-disabled: r.replaceBy(var(--grey-300), --hop-neutral-surface-disabled);
$primary-button-coral-text: r.replaceBy(var(--creamsicle-75), --hop-neutral-text-strong);
$primary-button-coral-text-hovered: r.replaceBy(var(--creamsicle-75), --hop-primary-text-strong-hover);

$surface-secondary-button: r.replaceBy(var(--white), --hop-neutral-surface);
$surface-secondary-button-disabled: r.replaceBy(var(--grey-300), --hop-neutral-surface-disabled);
$secondary-button-text: r.replaceBy(var(--coral-900), --hop-neutral-text);
$secondary-button-text-hovered: r.replaceBy(var(--coral-950), --hop-neutral-text-hover);

$ghost-button-text-dark: r.replaceBy(var(--coral-950), --hop-neutral-text);
$ghost-button-text-light: var(--creamsicle-75);

// Questions
$surface-star-rating: var(--creamsicle-100);
$surface-star-rating-hovered: var(--dandelion-300);
$surface-star-rating-selected: var(--dandelion-600);
$surface-star-rating-focused: var(--coral-950);

$surface-likert: r.replaceBy(var(--creamsicle-100), --hop-neutral-surface-strong);
$surface-likert-hovered: r.replaceBy(var(--coral-800), --hop-neutral-text);
$surface-likert-selected: r.replaceBy(var(--seaweed-500), --hop-neutral-surface-strong);
$surface-likert-selected-alt: r.replaceBy(var(--seaweed-300), --hop-neutral-surface-weak);
$surface-likert-mobile: r.replaceBy(var(--creamsicle-200), --hop-neutral-text);
$likert-selected-text: r.replaceBy(var(--seaweed-800), --hop-neutral-text);

$surface-multiple-choice: r.replaceBy(var(--white), --hop-neutral-surface);
$surface-multiple-choice-hovered: r.replaceBy(var(--creamsicle-100), --hop-neutral-surface-hover);
$surface-multiple-choice-hovered-border: r.replaceBy(var(--coral-950), --hop-neutral-border-hover);
$surface-multiple-choice-selected-border: r.replaceBy(var(--coral-950), --hop-neutral-border-active);
$surface-multiple-choice-selected: r.replaceBy(var(--seaweed-500), --hop-neutral-surface-weak);

$surface-slider-handle: r.replaceBy(var(--grey-400), --hop-neutral-surface);
$surface-slider-handle-selected: r.replaceBy(var(--seaweed-500), --hop-neutral-surface-active);
$surface-slider-bar: var(--coral-950);
$surface-slider-rail: r.replaceBy(var(--creamsicle-100), --hop-neutral-surface);
$surface-slider-track: r.replaceBy(var(--creamsicle-200), --hop-information-surface-weak);
$surface-slider-step: r.replaceBy(var(--creamsicle-150), --hop-decorative-option9-border);

$surface-slider-score: (
    0: r.replaceBy(var(--coral-950), --hop-dataviz-diverging-sequence-1-negative5),
    1: r.replaceBy(var(--coral-950), --hop-dataviz-diverging-sequence-1-negative5),
    2: r.replaceBy(var(--coral-900), --hop-dataviz-diverging-sequence-1-negative5),
    3: r.replaceBy(var(--coral-800), --hop-dataviz-diverging-sequence-1-negative5),
    4: r.replaceBy(var(--coral-700), --hop-dataviz-diverging-sequence-1-negative3),
    5: r.replaceBy(var(--coral-600), --hop-dataviz-diverging-sequence-1-negative3),
    6: r.replaceBy(var(--creamsicle-300), --hop-dataviz-diverging-sequence-1-neutral),
    7: r.replaceBy(var(--dandelion-600), --hop-dataviz-diverging-sequence-1-neutral),
    8: r.replaceBy(var(--seaweed-500), --hop-dataviz-diverging-sequence-1-positive3),
    9: r.replaceBy(var(--seaweed-600), --hop-dataviz-diverging-sequence-1-positive5),
    10: r.replaceBy(var(--seaweed-800), --hop-dataviz-diverging-sequence-1-positive5)
);

$surface-step-in-progress: r.replaceBy(var(--creamsicle-150), --hop-neutral-surface-active);
$surface-step-completed: var(--creamsicle-300);
$surface-step-not-completed: var(--grey-300);

// Signup
$surface-signup-slide-coral: var(--coral-900);
$surface-signup-slide-electric-blue: var(--electric-blue-800);
$surface-signup-slide-dark-coral: var(--coral-950);

// Tags
$tag-text: var(--grey-800);
$surface-tag-creamsicle: r.replaceBy(var(--creamsicle-200), --hop-decorative-option3-surface-strong);
$surface-tag-seaweed: r.replaceBy(var(--seaweed-300), --hop-decorative-option2-surface-strong);
$surface-tag-dandelion: r.replaceBy(var(--dandelion-400), --hop-decorative-option6-surface-strong);
$surface-tag-electric-blue: r.replaceBy(var(--electric-blue-200), --hop-decorative-option1-surface-strong);
$surface-tag-sky: r.replaceBy(var(--sky-200), --hop-decorative-option5-surface-strong);
$surface-tag-coral: r.replaceBy(var(--coral-300), --hop-decorative-option4-surface-strong);
$surface-tag-strawberry-fields: r.replaceBy(var(--strawberry-fields-200), --hop-decorative-option8-surface-strong);

// Recognition
$surface-recognition-card-creamsicle: r.replaceBy(var(--creamsicle-150), --hop-decorative-option3-surface);
$surface-recognition-card-seaweed: r.replaceBy(var(--seaweed-200), --hop-decorative-option2-surface);
$surface-recognition-card-dandelion: r.replaceBy(var(--dandelion-200), --hop-decorative-option6-surface);
$surface-recognition-card-electric-blue: r.replaceBy(var(--electric-blue-100), --hop-decorative-option1-surface);
$surface-recognition-card-sky: r.replaceBy(var(--sky-100), --hop-decorative-option5-surface);
$surface-recognition-card-coral: r.replaceBy(var(--coral-200), --hop-decorative-option4-surface);
$surface-recognition-card-strawberry-fields: r.replaceBy(var(--strawberry-fields-100), --hop-decorative-option8-surface);
$surface-recognition-card-in-stack: r.replaceBy(var(--creamsicle-50), --hop-neutral-surface);

// Recognition Images
$surface-recognition-image-creamsicle: var(--creamsicle-200);
$surface-recognition-image-seaweed: var(--seaweed-400);
$surface-recognition-image-dandelion: var(--dandelion-500);
$surface-recognition-image-electric-blue: var(--electric-blue-300);
$surface-recognition-image-sky: var(--sky-200);
$surface-recognition-image-coral: var(--coral-300);
$surface-recognition-image-strawberry-fields: var(--strawberry-fields-200);