@import "@sass/utils/all";

/* stylelint-disable workleap/no-naked-rem */

.custom-poll-intro {
    max-width: 47.5rem;
    margin: 0 auto;
    padding: 0 var(--hop-space-inset-md);

    &__permissions-alert {
        position: fixed;
        top: 0;
        right: 0;

        max-width: min(23rem, calc(100vw - 2 * var(--hop-space-inline-md)));

        margin: var(--hop-space-stack-md);
    }

    &__company-logo-thumbnail {
        display: block;
        width: auto;
        max-width: 100vw;
        height: var(--hop-space-800);
        margin: 0 auto var(--hop-space-stack-lg);

        border-radius: var(--border-radius-xl);
    }

    &__author-thumbnail {
        display: block;
        width: var(--hop-space-800);
        height: var(--hop-space-800);
        margin: 0 auto var(--hop-space-stack-lg);

        border-radius: 50%;
    }

    &__title {
        @include heading-1;
        font-weight: var(--hop-heading-2xl-font-weight);
        text-align: center;
    }

    &__description {
        margin-top: var(--hop-space-stack-sm);

        @include large-text;
        line-height: var(--hop-body-lg-line-height);
        text-align: center;
    }

    &__details {
        margin-top: 3.75rem;
    }

    &__poll-title {
        @include heading-3;

        text-align: center;
    }

    &__poll-description {
        margin-top: var(--hop-space-stack-sm);
        padding: var(--hop-space-inset-lg);

        @include large-text;
        line-height: var(--hop-body-lg-line-height);
        text-align: center;

        background-color: $surface;
        border: var(--hop-space-10) solid var(--hop-neutral-border-weak);
        border-radius: var(--hop-shape-rounded-md);
    }

    &__answer-button {
        display: flex;
        margin: var(--hop-space-stack-xl) auto var(--hop-space-stack-md) auto;

        font-weight: var(--font-weight-regular);
    }

    &__total-questions {
        text-align: center;
    }

    &--animated-intro {
        .custom-poll-intro__permissions-alert,
        .custom-poll-intro__company-logo-thumbnail,
        .custom-poll-intro__author-thumbnail {
            @include slide-in-keyframes("bottom", var(--hop-space-480), custom-poll-intro-image-slide-in-animation);

            animation: fade-in linear 500ms both,
                custom-poll-intro-image-slide-in-animation ease-out 500ms both;
        }

        .custom-poll-intro__title {
            @include slide-in-keyframes("bottom", var(--hop-space-480), custom-poll-intro-title-slide-in-animation);
            $delay: 200ms;

            animation: fade-in linear 500ms $delay both,
                custom-poll-intro-title-slide-in-animation ease-out 500ms $delay both;
        }

        .custom-poll-intro__description {
            @include slide-in-keyframes("bottom", var(--hop-space-480), custom-poll-intro-description-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                custom-poll-intro-description-slide-in-animation ease-out 500ms $delay both;
        }

        .custom-poll-intro__separator {
            $delay: 600ms;

            animation: fade-in linear 500ms $delay both;
        }

        .custom-poll-intro__details {
            @include slide-in-keyframes("bottom", var(--hop-space-480), custom-poll-intro-details-slide-in-animation);
            $delay: 600ms;

            animation: fade-in linear 500ms $delay both,
                custom-poll-intro-details-slide-in-animation ease-out 500ms $delay both;
        }

        .custom-poll-intro__footer {
            @include slide-in-keyframes("bottom", var(--hop-space-480), custom-poll-intro-footer-slide-in-animation);
            $delay: 800ms;

            animation: fade-in linear 500ms $delay both,
                custom-poll-intro-footer-slide-in-animation ease-out 500ms $delay both;
        }
    }
}