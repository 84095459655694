@font-face {
    font-weight: 100 900;
    font-family: Inter;
    font-style: normal;
    src: url("https://cdn.platform.workleap.com/hopper/fonts/inter/v4/InterVariable.woff2") format("woff2-variations");
    font-display: fallback;
}

@font-face {
    font-weight: 300;
    font-family: GT-Walsheim;
    font-style: normal;
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Light.ttf');
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Light.ttf') format("truetype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Light.otf') format("opentype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Light.woff') format("woff");
}

@font-face {
    font-weight: 400;
    font-family: GT-Walsheim;
    font-style: normal;
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Regular.ttf');
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Regular.ttf') format("truetype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Regular.otf') format("opentype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Regular.woff') format("woff");
}

@font-face {
    font-weight: 500;
    font-family: GT-Walsheim;
    font-style: normal;
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Medium.ttf');
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Medium.ttf') format("truetype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Medium.otf') format("opentype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Medium.woff') format("woff");
}

@font-face {
    font-weight: 700;
    font-family: GT-Walsheim;
    font-style: normal;
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Bold.ttf');
    src: url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Bold.ttf') format("truetype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Bold.otf') format("opentype"),
        url('https://cdn.officevibe.workleap.com/assets/Fonts/GT-Walsheim-Bold.woff') format("woff");
}