@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.peer-select {
    [data-brand="workleap"] & {
        --_search-bar-padding-right: calc(var(--hop-space-inset-xl) + var(--hop-space-inset-sm));
        --_search-bar-padding-left: 0;
    }

    position: relative;

    &--error {
        .peer-select__control {
            border-color: r.replaceBy($error, --hop-danger-border-strong);
        }
    }

    &--open {
        .peer-select__control {
            border-color: r.replaceBy($brand-primary, --hop-neutral-border-active);
        }
    }

    &--readonly .peer-select__search-bar {
        position: absolute;

        width: 0;
        height: 0;

        opacity: 0;

        pointer-events: none;
    }

    &__control {
        position: relative;

        display: flex;
        align-items: center;
        width: 100%;
        height: calc(4rem * var(--rem-ratio, 1));

        background-color: $surface;
        border: calc(0.1rem * var(--rem-ratio, 1)) solid r.replaceBy($inputs-border, --hop-neutral-border);
        border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-rounded-md);

        cursor: pointer;

        &:hover {
            background-color: r.replaceBy($surface, --hop-neutral-surface-hover);
            border-color: r.replaceBy($inputs-border, --hop-neutral-border-hover);
        }

        &:focus {
            border-color: r.replaceBy($focus-outline, --hop-neutral-border-active);
            outline: none;
        }

        &:focus-within {
            outline: calc(0.16rem * var(--rem-ratio, 1)) solid r.replaceBy(transparent, --hop-primary-border-focus);
            outline-offset: calc(0.219rem * var(--rem-ratio, 1));
        }
    }

    &__icon {
        flex-shrink: 0;
        padding: 0 r.replaceBy($space-2, --hop-space-inset-sm) 0 r.replaceBy($space-2, --hop-space-inset-md);

        color: r.replaceBy($placeholder-text, --hop-neutral-icon-weak);

        [data-brand="workleap"] & {
            height: calc(1.6rem * var(--rem-ratio, 1));
        }
    }

    &__search-bar {
        flex: 1 1 100%;
        width: 100%; // Fix for Firefox
        height: 100%;
        margin-right: calc(0.1rem * var(--rem-ratio, 1)); // Fix for border glitch
        /* stylelint-disable-next-line custom-property-no-missing-var-function */
        padding: 0 r.replaceBy($space-6, --_search-bar-padding-right) 0 r.replaceBy($space-2, --_search-bar-padding-left);

        color: $primary-text-alt;

        border: none;
        border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);

        &::placeholder {
            color: $placeholder-text;
        }

        &:focus {
            outline: none;
        }

        [data-brand="workleap"] & {
            @include medium-text;

            font-weight: var(--hop-body-md-font-weight);

            background-color: inherit;

            border-radius: var(--hop-shape-rounded-md);
        }
    }

    &__placeholder {
        flex: 1 1 100%;
        overflow-x: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;

        [data-brand="workleap"] & {
            @include medium-text;
            font-weight: var(--hop-body-md-font-weight);
        }
    }

    &__value {
        flex: 1 1 100%;
        min-width: 0;
        height: 100%;
    }

    &__control-arrow {
        position: absolute;
        right: 0;

        padding: calc(0.8rem * var(--rem-ratio, 1));

        color: r.replaceBy($secondary-text, --hop-neutral-icon-weak);

        [data-brand="workleap"] & {
            height: calc(1.6rem * var(--rem-ratio, 1));

            padding: 0 var(--hop-space-inset-md);
        }
    }

    &__popover {
        position: absolute;
        top: calc(100% + r.replaceBy($space-2, --hop-space-stack-sm));
        right: 0;
        left: 0;
        z-index: $z-index-popover;

        max-height: calc(33.6rem * var(--rem-ratio, 1));
        padding: r.replaceBy($space-3 0, --hop-space-inset-sm);
        overflow-y: auto;

        background-color: $surface;

        @include fancy-webkit-scrollbar;

        @keyframes peer-select-popover-slide-in-animation {
            0% { transform: translateY(r.replaceBy($space-3, --hop-space-stack-md)); }
            100% { transform: translateY(0); }
        }

        animation: fade-in linear 200ms,
            peer-select-popover-slide-in-animation ease-out 200ms;

        [data-brand="workleap"] & {
            padding: var(--hop-space-inset-sm);

            border: calc(0.1rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-weak);
            border-radius: var(--hop-shape-rounded-md);
            box-shadow: var(--hop-shadow-md);
        }
    }
}