@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

$z-index-background: 1;
$z-index-foreground: 2;

.signup-page {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    background-color: r.replaceBy($surface, --_transparent);

    animation: fade-in linear 300ms both;

    @include small {
        background-color: transparent;

        animation: none;
    }

    &__background {
        display: none;

        @include small {
            position: absolute;
            z-index: $z-index-background;

            display: block;
            width: r.replaceBy(auto, --_100-percent);
            height: 100%;

            animation: fade-in linear 300ms 600ms both;
        }
    }

    &__header {
        z-index: $z-index-foreground;

        flex-shrink: 0;
        margin-bottom: r.replaceBy($space-5, --hop-space-stack-xl);
        padding-top: r.replaceBy($space-5, --space-960);

        @include small {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;

            padding-top: r.replaceBy(calc(5.2rem * var(--rem-ratio, 1)), --hop-space-inset-xl);
        }
    }

    &__logo {
        [data-brand="workleap"] & {
            height: auto;
        }

        display: block;
        width: r.replaceBy(calc(11rem * var(--rem-ratio, 1)), --_9-rem);
        margin: 0 auto;

        animation: fade-in linear 300ms 300ms both;

        fill: $primary-text;

        @include small {
            width: calc(16rem * var(--rem-ratio, 1));
            height: calc(4.8rem * var(--rem-ratio, 1));
            margin: 0 r.replaceBy(auto, --hop-space-inline-xl);
        }
    }

    &__body {
        z-index: $z-index-foreground;

        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }

    &__flow {
        flex: 1 0 100%;
    }
}