@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.click-to-confirm-slider-handle {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(5.6rem * var(--rem-ratio, 1));
    height: calc(5.6rem * var(--rem-ratio, 1));

    background-color: $surface-slider-handle-selected;
    border-radius: 50%;

    cursor: pointer;

    &__icon {
        width: calc(2.5rem * var(--rem-ratio, 1));
        height: calc(2rem * var(--rem-ratio, 1));

        [data-brand="workleap"] & {
            width: calc(3.2rem * var(--rem-ratio, 1));
            height: calc(3.2rem * var(--rem-ratio, 1));
        }
    }

    &__labels {
        position: absolute;
        top: calc(-0.8rem * var(--rem-ratio, 1));
        left: 50%;

        width: calc(20rem * var(--rem-ratio, 1));

        transform: translate(-50%, -100%);

        cursor: pointer;
    }

    &__label {
        text-align: center;

        @keyframes label-slide-in-animation {
            0% { transform: translateY(calc(0.8rem * var(--rem-ratio, 1))); }
            100% { transform: translateY(0); }
        }

        $animationPercentage: 30%;
        @keyframes label-bounce-animation {
            0%, #{$animationPercentage * 0.5}, #{$animationPercentage}, 100% { transform: translateY(0); }
            #{$animationPercentage * 0.25} { transform: translateY(calc(-2.4rem * var(--rem-ratio, 1))); }
            #{$animationPercentage * 0.75} { transform: translateY(calc(-0.8rem * var(--rem-ratio, 1))); }
        }

        animation: fade-in linear 300ms both,
            label-slide-in-animation ease-out 300ms backwards,
            label-bounce-animation ease-in-out 1500ms 1000ms 3 forwards;

        @include ie {
            animation: none;
        }
    }

    &__confirmed-label {
        text-align: center;

        @keyframes confirmed-label-animation {
            0% { color: r.replaceBy(var(--coral-950), --hop-neutral-text); }
            100% { color: r.replaceBy(var(--coral-500), --hop-neutral-text-weak); }
        }

        animation: confirmed-label-animation linear 80ms 6 alternate both;
    }
}