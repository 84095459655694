@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.step {
    position: relative;

    padding: calc(0.8rem * var(--rem-ratio, 1)) calc(0.4rem * var(--rem-ratio, 1));

    &--current .step__dot {
        width: calc(0.8rem * var(--rem-ratio, 1));
        height: calc(0.8rem * var(--rem-ratio, 1));
    }

    &__background-dot {
        width: calc(0.8rem * var(--rem-ratio, 1));
        height: calc(0.8rem * var(--rem-ratio, 1));

        background-color: r.replaceBy($surface-step-not-completed, --hop-neutral-surface-weak);
        border-radius: 50%;
    }

    &__dot {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 0;
        height: 0;

        background-color: r.replaceBy($surface-step-completed, --hop-neutral-surface-active);
        border-radius: 50%;
        transform: translate(-50%, -50%);

        transition: width linear 200ms,
            height linear 200ms;
    }
}