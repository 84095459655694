@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.multiple-choice-question-custom-choice {
    width: 100%;
    margin-top: r.replaceBy($space-3, --hop-space-stack-sm);

    @include medium {
        margin-top: r.replaceBy($space-4, --hop-space-stack-lg);
    }

    @include small {
        margin-top: r.replaceBy($space-3, --hop-space-stack-md);

        .multiple-choice-question-choice__label-container {
            padding: r.replaceBy($space-2, --hop-space-inset-sm) r.replaceBy(calc(1.2rem * var(--rem-ratio, 1)), --hop-space-inset-lg);
        }

        .multiple-choice-question-choice__selected-icon {
            top: 50%;
            right: r.replaceBy($space-2, --hop-space-inline-md);

            transform: translateY(-50%);
        }
    }

    &--confirmed {
        pointer-events: none;
    }
}