@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.signup-slide-footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: r.replaceBy($space-5, --hop-space-stack-lg);
    padding: r.replaceBy($space-2, --hop-space-inset-lg) r.replaceBy($space-3, --hop-space-inset-md);

    background-color: r.replaceBy(initial, --hop-status-neutral-surface);

    border-radius: r.replaceBy(0, --hop-shape-rounded-md);

    @include small {
        padding: r.replaceBy($space-3, --space-0) r.replaceBy($space-3, --hop-space-inset-xl)  r.replaceBy($space-3, --hop-space-inset-xl);
    }

    &--with-previous-button .signup-slide-footer__previous-button {
        visibility: visible;
    }

    &--with-next-button .signup-slide-footer__next-button {
        visibility: visible;
    }

    &__left-section {
        flex-shrink: 0;
        margin-right: calc(1.6rem * var(--rem-ratio, 1));
    }

    &__middle-section {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        min-width: 0;
    }

    &__right-section {
        flex-shrink: 0;
        margin-left: calc(1.6rem * var(--rem-ratio, 1));
    }

    &__previous-button,
    &__next-button {
        visibility: hidden;
    }
}