@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.deib-intro {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 calc(1.6rem * var(--rem-ratio, 1));

        [data-brand="workleap"] & {
            margin-top: calc(-0.8rem * var(--rem-ratio, 1));

            @include small {
                margin-top: calc(-4rem * var(--rem-ratio, 1));
            }
        }
    }

    &__description {
        @include large-text();
        text-align: center;
    }

    &__illustration {
        width: calc(16.8rem * var(--rem-ratio, 1));
        height: calc(16.8rem * var(--rem-ratio, 1));
    }

    &__title {
        @include heading-1();
        margin: calc(4.8rem * var(--rem-ratio, 1)) 0 calc(5.6rem * var(--rem-ratio, 1));

        font-weight: var(--font-weight-bold);

        text-align: center;

        [data-brand="workleap"] & {
            margin: calc(3.2rem * var(--rem-ratio, 1)) 0 calc(1.6rem * var(--rem-ratio, 1));
        }
    }

    &__button {
        margin: calc(4.8rem * var(--rem-ratio, 1)) 0 calc(1.6rem * var(--rem-ratio, 1));

        [data-brand="workleap"] & {
            margin: calc(6.4rem * var(--rem-ratio, 1)) 0 calc(1.6rem * var(--rem-ratio, 1));
        }
    }

    &__subtext {
        @include medium-text();
    }
}