@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.labeled-form-field {
    &__label {
        display: flex;
        gap: r.replaceBy($space-1, --hop-space-stack-xs);
        align-items: center;

        color: r.replaceBy($secondary-text, --hop-neutral-text-weak);

        font-size: r.replaceBy($font-size-3, --hop-heading-xs-font-size);
        font-family: r.replaceBy($primary-font-family, --hop-heading-xs-font-family);
        line-height: r.replaceBy($line-height-xxxl, --hop-heading-xs-line-height);
    }
}