@import "@sass/utils/all";

.country-select {
    .select__control {
        width: calc(10rem * var(--rem-ratio, 1));
        height: calc(3.8rem * var(--rem-ratio, 1));

        color: $secondary-text;

        background-color: var(--grey-100);
        border: none;
        border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
    }

    .select__popover {
        right: auto;

        width: calc(30rem * var(--rem-ratio, 1));
    }
}

.country-select-value {
    color: $secondary-text;
    text-align: center;

    user-select: none;
}

.country-select-option {
    display: flex;
    align-items: center;

    &__label {
        flex: 1 0 0%;
        min-width: 0;
    }

    &__country-calling-code {
        flex-shrink: 0;
        margin-left: calc(1.6rem * var(--rem-ratio, 1));

        @include small-text;
        color: $secondary-text;
    }
}