@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.signup-slide-image-header {
    display: r.replaceBy(block, --_display-flex);
    justify-content: r.replaceBy(initial, --_justify-content-center);
    margin: 0 r.replaceBy(0, --hop-space-inline-xl) r.replaceBy($space-4, --hop-space-stack-xl);
    padding: r.replaceBy($space-3, --_signup-activation-slide-image-mobile-padding-top) r.replaceBy($space-3, --hop-space-inset-lg) 0;

    line-height: 0;

    border: var(--hop-neutral-border-strong) solid r.replaceBy(0, --_border-2px);
    border-radius: r.replaceBy(0, --hop-shape-rounded-md);

    @include small {
        margin: r.replaceBy(0, --hop-space-stack-xl) r.replaceBy(0, --hop-space-inline-xl) r.replaceBy($space-4, --hop-space-stack-lg);
        padding: r.replaceBy($space-5, --hop-space-inset-xl) r.replaceBy($space-5, --hop-space-inset-xl) 0;

        border: var(--hop-neutral-border-strong) solid r.replaceBy(0, --_border-2px);
        border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-rounded-md) r.replaceBy(var(--border-radius-sm), --hop-shape-rounded-md) r.replaceBy(0, --hop-shape-rounded-md) r.replaceBy(0, --hop-shape-rounded-md);
    }

    &--coral {
        background-color: r.replaceBy($surface-signup-slide-coral, --hop-decorative-option6-surface);
    }

    &--dark-coral {
        background-color: r.replaceBy($surface-signup-slide-dark-coral, --hop-decorative-option1-surface-weak);
    }

    &--electric-blue {
        background-color: r.replaceBy($surface-signup-slide-electric-blue, --hop-decorative-option4-surface);
    }

    &__image {
        width: r.replaceBy(100%, --_signup-activation-slide-image-mobile-width);
        height: auto;
        margin-bottom: r.replaceBy(0, --_signup-activation-slide-image-margin-bottom);

        @include small {
            width: r.replaceBy(100%, --_signup-activation-slide-image-small-width);
        }
    }
}