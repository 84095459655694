@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

[data-brand="workleap"] {
    --_progress-step-margin-none: 0;
    --_progress-step-width-mobile: calc(2.5rem * var(--rem-ratio, 1));
    --_progress-step-width-large: calc(4rem * var(--rem-ratio, 1));
    --_progress-step-height: calc(2rem * var(--rem-ratio, 1));
}

/* stylelint-disable-next-line custom-property-no-missing-var-function */
$remove-progress-step-margin: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_progress-step-margin-none);

.progress-bar-step {
    /* stylelint-disable-next-line custom-property-no-missing-var-function */
    width: r.replaceBy(calc(1.6rem * var(--rem-ratio, 1)), --_progress-step-width-mobile);
    /* stylelint-disable-next-line custom-property-no-missing-var-function */
    height: r.replaceBy(calc(1.6rem * var(--rem-ratio, 1)), --_progress-step-height);
    margin-left: $remove-progress-step-margin;

    background-color: r.replaceBy($surface-step-not-completed, --hop-neutral-surface);

    transition: background-color linear 300ms;

    @include large {
        /* stylelint-disable-next-line custom-property-no-missing-var-function */
        width: r.replaceBy(calc(2.8rem * var(--rem-ratio, 1)), --_progress-step-width-large);
    }

    &:first-child {
        margin-left: 0;

        border-top-left-radius: r.replaceBy(calc(0.8rem * var(--rem-ratio, 1)), --hop-shape-pill);
        border-bottom-left-radius: r.replaceBy(calc(0.8rem * var(--rem-ratio, 1)), --hop-shape-pill);
    }

    &:last-of-type {
        border-top-right-radius: r.replaceBy(calc(0.8rem * var(--rem-ratio, 1)), --hop-shape-pill);
        border-bottom-right-radius: r.replaceBy(calc(0.8rem * var(--rem-ratio, 1)), --hop-shape-pill);
    }

    &--in-progress {
        background-color: $surface-step-in-progress;
    }

    &--completed {
        background-color: r.replaceBy($surface-step-completed, --hop-decorative-option7-surface-strong);
    }

    [data-brand="workleap"] & {
        border: calc(0.1rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-strong);
    }
}