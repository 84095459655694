@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.custom-poll-multiple-choice-question {
    &__input {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
    }

    &__choice {
        width: 100%;

        margin-top: r.replaceBy($space-3, --hop-space-stack-sm);

        &:first-child {
            margin-top: 0;
        }

        @include small {
            width: calc(50% - calc(0.8rem * var(--rem-ratio, 1)));

            margin-top: 0;

            &:nth-child(even) {
                margin-left: r.replaceBy($space-3, --hop-space-inline-md);
            }

            &:nth-child(n+3) {
                margin-top: r.replaceBy($space-3, --hop-space-stack-md);
            }

            [data-brand="workleap"] & {
                width: calc(50% - var(--hop-space-inline-sm));
            }
        }

        @include medium {
            width: calc(50% - calc(1.2rem * var(--rem-ratio, 1)));

            &:nth-child(even) {
                margin-left: r.replaceBy($space-4, --hop-space-inline-lg);
            }

            &:nth-child(n+3) {
                margin-top: r.replaceBy($space-4, --hop-space-stack-lg);
            }

            [data-brand="workleap"] & {
                width: calc(50% - var(--hop-space-inline-xs) * 3);
            }
        }
    }

    &--confirmed {
        pointer-events: none;
    }

    &--dense {
        .custom-poll-multiple-choice-question__choice {
            margin-top: var(--hop-space-stack-sm);

            @include small {
                width: calc(50% - var(--hop-space-inline-sm));

                &:nth-child(even) {
                    margin-left: var(--hop-space-inline-md);
                }

                &:nth-child(n+3) {
                    margin-top: var(--hop-space-stack-sm);
                }
            }

            @include medium {
                width: calc(50% - var(--hop-space-inline-sm));

                &:nth-child(even) {
                    margin-left: var(--hop-space-inline-md);
                }

                &:nth-child(n+3) {
                    margin-top: var(--hop-space-stack-sm);
                }
            }
        }

        .multiple-choice-question-custom-choice, .custom-choice-input {
            margin-top: var(--hop-space-stack-sm);
        }
    }
}