@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.multiple-choice-question-choice {
    &__radio-input {
        position: absolute;

        width: 0;
        height: 0;

        opacity: 0;

        pointer-events: none;
    }

    &__choice-label {
        cursor: pointer;

        &--selected {
            .multiple-choice-question-choice__label-container {
                color: r.replaceBy($surface-multiple-choice-selected-border, --hop-neutral-text);

                background-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-surface-weak);
                outline: r.replaceBy(inherit, --_border-2px) solid r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-border-active);
            }

            .multiple-choice-question-choice__selected-icon {
                visibility: visible;
            }
        }

        &--confirmed {
            &.multiple-choice-question-choice__choice-label--selected .multiple-choice-question-choice__label-container {
                @keyframes multiple-choice-confirmed-poll-animation {
                    0% { background-color: $surface-multiple-choice; border-color: r.replaceBy($surface-multiple-choice, --hop-neutral-border); }
                    100% { background-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-surface-weak); border-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-border-active); }
                }

                animation: multiple-choice-confirmed-poll-animation linear 80ms 5 alternate both;
            }
        }

        &:hover {
            .multiple-choice-question-choice__label-container {
                color: r.replaceBy($surface-multiple-choice-hovered-border, --hop-neutral-text);

                background-color: $surface-multiple-choice-hovered;
                outline-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-border-hover);
            }
        }

        &--highlighted,
        &--highlighted:hover {
            .multiple-choice-question-choice__label-container {
                color: r.replaceBy($surface-multiple-choice-hovered-border, --hop-neutral-text);

                outline-color: r.replaceBy($surface-multiple-choice-hovered-border, --hop-neutral-border-active);
            }
        }
    }

    &__label-container {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: r.replaceBy($space-3, --hop-space-inset-md) r.replaceBy(calc(1.2rem * var(--rem-ratio, 1)), --hop-space-inset-lg);

        background-color: $surface-multiple-choice;
        border-radius: r.replaceBy($space-2, --hop-shape-rounded-md);

        outline: calc(0.1rem * var(--rem-ratio, 1)) solid r.replaceBy($surface-multiple-choice, --hop-neutral-border);

        @include small {
            padding: r.replaceBy($space-4, --hop-space-inset-lg) r.replaceBy(calc(1.2rem * var(--rem-ratio, 1)), --hop-space-inset-lg);
        }
    }

    &__label {
        min-width: 0;

        @include medium-text;
        font-weight: r.replaceBy(var(--body-md-font-weight), --hop-body-md-medium-font-weight);
        text-align: center;
        overflow-wrap: break-word;
    }

    &__selected-icon {
        position: absolute;
        top: 50%;
        right: r.replaceBy(calc(1.2rem * var(--rem-ratio, 1)), --hop-space-inline-md);

        color: r.replaceBy($surface-multiple-choice-selected, --hop-neutral-icon);

        transform: translateY(-50%);

        visibility: hidden;

        @include small {
            top: calc(0.8rem * var(--rem-ratio, 1));
            right: calc(0.8rem * var(--rem-ratio, 1));

            transform: none;

            [data-brand="workleap"] & {
                top: 50%;
                right: var(--hop-space-inline-md);

                transform: translateY(-50%);
            }
        }
    }
}