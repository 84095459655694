@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.likert-question {
    $remove-mobile-label-font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-2xl-font-weight);

    color: $primary-text;

    [data-brand="workleap"] & {
        margin-top: calc(3.2rem * var(--rem-ratio, 1));

        @include small {
            margin-top: calc(4rem * var(--rem-ratio, 1));
        }
    }

    &--interacted {
        .likert-question__mobile-scroll-label {
            visibility: hidden;
        }
    }

    &--confirmed {
        pointer-events: none;

        .likert-question__mobile-group {
            overflow-x: hidden;
        }

        .likert-question__mobile-confirmed-label {
            @keyframes likert-confirmed-label-animation {
                0% { color: $primary-text; }
                100% { color: r.replaceBy($error, --hop-neutral-text-weakest); }
            }

            animation: likert-confirmed-label-animation linear 80ms 6 alternate both;
        }

        .likert-question__choice-label--selected .likert-question__choice-inner-bubble {
            @keyframes likert-confirmed-pulse-animation {
                0% { background-color: $surface-likert-selected-alt; }
                100% { background-color: $surface-likert-selected; }
            }

            animation: likert-confirmed-pulse-animation linear 80ms 5 alternate both;
        }
    }

    @for $i from 0 through 4 {
        &--#{$i} {
            .likert-question__mobile-choice:nth-child(#{$i + 1}) .likert-question__mobile-label {
                color: $likert-selected-text;
            }
        }
    }

    &__mobile-group {
        display: flex;
        margin: calc(-3.2rem * var(--rem-ratio, 1)) calc(-3.2rem * var(--rem-ratio, 1)) 0 calc(-3.2rem * var(--rem-ratio, 1));
        overflow-x: auto;

        [data-brand="workleap"] & {
            scroll-snap-type: x mandatory;
        }

        @include small {
            display: none;
        }
    }

    &__mobile-choices-container {
        display: flex;
        padding: 0 calc((100vw - 48vw) / 2);
        padding-bottom: calc(0.8rem * var(--rem-ratio, 1));
    }

    &__mobile-choice {
        position: relative;

        flex-shrink: 0;
        width: 48vw;
        padding: calc(3.2rem * var(--rem-ratio, 1)) 0;

        scroll-snap-align: start;

        @include heading-1;
        color: $surface-likert-mobile;
        text-align: center;

        [data-brand="workleap"] & {
            scroll-snap-align: center;
        }
    }

    &__mobile-radio-input {
        position: absolute;

        width: 0;
        height: 0;

        opacity: 0;

        pointer-events: none;

        &:focus-visible + .likert-question__mobile-label {
            position: relative;

            &:after {
                position: absolute;
                top: calc(-0.4rem * var(--rem-ratio, 1));
                right: calc(0.4rem * var(--rem-ratio, 1));
                bottom: calc(-0.4rem * var(--rem-ratio, 1));
                left: calc(0.4rem * var(--rem-ratio, 1));

                display: block;

                border: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid $focus-outline;
                border-radius: calc(0.4rem * var(--rem-ratio, 1));

                content: '';
            }
        }
    }

    &__mobile-label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: calc(0.8rem * var(--rem-ratio, 1));

        @include heading-1;
        color: r.replaceBy($surface-likert-mobile, --hop-neutral-text-weakest);
        font-weight: $remove-mobile-label-font-weight;
        text-align: center;

        &--focused {
            color: $likert-selected-text;
        }

        [data-brand="workleap"] & {
            padding: 0;
        }
    }

    &__mobile-click-to-confirm-label {
        position: absolute;
        top: 0;
        left: 50%;

        @include medium-text;
        color: $primary-text;
        white-space: nowrap;

        transform: translate(-50%, 0);

        @include small {
            display: none;
        }

        [data-brand="workleap"] & {
            position: initial;

            margin-top: calc(4.8rem * var(--rem-ratio, 1));

            text-align: center;

            transform: none;
        }
    }

    &__mobile-confirmed-label {
        position: absolute;
        top: 0;
        left: 50%;

        @include medium-text;
        color: $primary-text;
        white-space: nowrap;

        transform: translate(-50%, 0);

        @include small {
            display: none;
        }

        [data-brand="workleap"] & {
            position: initial;

            margin-top: r.replaceBy($space-7, --hop-space-stack-2xl);

            text-align: center;

            transform: none;
        }
    }

    &__mobile-scroll-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: r.replaceBy($space-0, --hop-space-stack-xs);

        @keyframes likert-scroll-label-animation-mobile {
            0%, 70%, 100% { transform: translateX(0); }
            80%, 90% { transform: translateX(calc(-1.6rem * var(--rem-ratio, 1))); }
            85%, 95% { transform: translateX(calc(1.6rem * var(--rem-ratio, 1))); }
        }

        animation: likert-scroll-label-animation-mobile 4000ms infinite;

        @include small {
            display: none;
        }

        [data-brand="workleap"] & {
            margin-bottom: 0;
        }
    }

    &__drag-arrow-left {
        width: calc(1.6rem * var(--rem-ratio, 1));
        height: calc(0.8rem * var(--rem-ratio, 1));
        margin-right: r.replaceBy($space-3, --hop-space-inline-md);

        color: $primary-text;

        [data-brand="workleap"] & {
            width: initial;
            height: initial;
        }
    }

    &__drag-arrow-right {
        width: calc(1.6rem * var(--rem-ratio, 1));
        height: calc(0.8rem * var(--rem-ratio, 1));
        margin-left: r.replaceBy($space-3, --hop-space-inline-md);

        color: $primary-text;

        [data-brand="workleap"] & {
            width: initial;
            height: initial;
        }
    }

    &__group {
        display: none;

        @include small {
            display: flex;
            align-items: flex-start;

            max-width: calc(76rem * var(--rem-ratio, 1));
            margin: 0 auto;
        }
    }

    &__choice {
        flex: 1 1 0%;
        min-width: 0;

        word-wrap: break-word;

        &--0 {
            .likert-question__segment:first-child {
                opacity: 0;
            }
        }

        &--4 {
            .likert-question__segment:last-child {
                opacity: 0;
            }
        }
    }

    &__radio-input {
        position: absolute;

        width: 0;
        height: 0;

        opacity: 0;

        pointer-events: none;

        &:focus-visible + .likert-question__choice-label .likert-question__choice-bubble-container {
            position: relative;

            &:after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                display: block;

                border-radius: 50%;
                outline: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid $focus-outline;

                content: '';

                [data-brand="workleap"] & {
                    outline-offset: calc(0.4rem * var(--rem-ratio, 1));
                }
            }
        }
    }

    &__choice-label {
        display: block;

        cursor: pointer;

        &:hover {
            .likert-question__choice-inner-bubble {
                width: calc(2.4rem * var(--rem-ratio, 1));
                height: calc(2.4rem * var(--rem-ratio, 1));

                opacity: 1;

                @keyframes tiny-pulse {
                    0%, 100% { transform: scale(1); }
                    50% { transform: scale(0.7); }
                }

                animation: tiny-pulse ease-in-out infinite 2000ms 300ms both;

                @include small {
                    width: calc(3.2rem * var(--rem-ratio, 1));
                    height: calc(3.2rem * var(--rem-ratio, 1));
                }
            }

            .likert-question__choice-bubble {
                [data-brand="workleap"] & {
                    @include hover-drop-shadow-stylised;
                }
            }

            .likert-question__choice-bubble-label {
                color: $surface-likert-hovered;
                font-weight: r.replaceBy(var(--font-weight-regular), --hop-heading-md-font-weight);
            }
        }

        &--highlighted {
            .likert-question__choice-inner-bubble {
                width: calc(2.4rem * var(--rem-ratio, 1));
                height: calc(2.4rem * var(--rem-ratio, 1));

                opacity: 1;

                @keyframes tiny-pulse {
                    0%, 100% { transform: scale(1); }
                    50% { transform: scale(0.7); }
                }

                animation: tiny-pulse ease-in-out infinite 2000ms 300ms both;

                @include small {
                    width: calc(3.2rem * var(--rem-ratio, 1));
                    height: calc(3.2rem * var(--rem-ratio, 1));
                }
            }

            .likert-question__choice-bubble-label {
                color: $surface-likert-hovered;
                font-weight: r.replaceBy(var(--font-weight-regular), --hop-heading-md-font-weight);
            }
        }

        &--selected {
            &:hover {
                .likert-question__choice-inner-bubble {
                    width: calc(2.8rem * var(--rem-ratio, 1));
                    height: calc(2.8rem * var(--rem-ratio, 1));

                    @include small {
                        width: calc(5.6rem * var(--rem-ratio, 1));
                        height: calc(5.6rem * var(--rem-ratio, 1));
                    }
                }

                .likert-question__choice-bubble-label {
                    color: $likert-selected-text;
                    font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-md-font-weight);
                }
            }

            .likert-question__choice-inner-bubble {
                width: calc(2.8rem * var(--rem-ratio, 1));
                height: calc(2.8rem * var(--rem-ratio, 1));

                background-color: $surface-likert-selected;

                opacity: 1;

                transition-duration: 100ms;

                @include small {
                    width: calc(5.6rem * var(--rem-ratio, 1));
                    height: calc(5.6rem * var(--rem-ratio, 1));
                }
            }

            .likert-question__choice-bubble-label {
                color: $likert-selected-text;
                font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-md-font-weight);
            }
        }
    }

    &__choice-segment-container {
        display: flex;

        align-items: center;
    }

    &__segment {
        flex: 1 0 0%;
        height: calc(0.8rem * var(--rem-ratio, 1));

        background-color: r.replaceBy($surface-likert, --hop-neutral-surface);

        border-top: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid $surface-likert;
        border-bottom: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid $surface-likert;

        [data-brand="workleap"] & {
            height: calc(1.6rem * var(--rem-ratio, 1));
        }
    }

    &__choice-bubble-container {
        position: relative;

        flex-shrink: 0;
    }

    &__choice-bubble {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(4rem * var(--rem-ratio, 1));
        height: calc(4rem * var(--rem-ratio, 1));

        background-color: r.replaceBy($surface-likert, --hop-neutral-surface);
        border-radius: 50%;
        outline: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid $surface-likert;

        @include small {
            width: calc(7.6rem * var(--rem-ratio, 1));
            height: calc(7.6rem * var(--rem-ratio, 1));
        }
    }

    &__choice-inner-bubble {
        width: 0;
        height: 0;

        background-color: $surface-likert-hovered;
        border-radius: 50%;
        opacity: 0;

        transition: width ease-out 300ms,
            height ease-out 300ms,
            opacity linear 300ms;
    }

    &__choice-check {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        width: calc(1.2rem * var(--rem-ratio, 1));
        height: calc(1.2rem * var(--rem-ratio, 1));

        color: r.replaceBy(var(--white), --hop-neutral-icon-strong);

        transform: translate(-50%, -50%);

        @include small {
            width: calc(2.5rem * var(--rem-ratio, 1));
            height: calc(2.5rem * var(--rem-ratio, 1));
        }
    }

    &__choice-bubble-label {
        padding: calc(2.4rem * var(--rem-ratio, 1)) calc(0.8rem * var(--rem-ratio, 1)) calc(0.8rem * var(--rem-ratio, 1)) calc(0.8rem * var(--rem-ratio, 1));

        color: $primary-text;

        white-space: pre-line;
        text-align: center;

        [data-brand="workleap"] & {
            @include heading-5;

            font-weight: r.replaceBy(var(--font-weight-regular), --hop-heding-sm-font-weight);
        }
    }
}