@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.custom-choice-input {
    width: 100%;
    margin-top: r.replaceBy($space-3, --hop-space-stack-sm);

    @include small {
        margin-top: r.replaceBy($space-3, --hop-space-stack-md);
    }

    @include medium {
        margin-top: r.replaceBy($space-4, --hop-space-stack-lg);
    }

    &__text-input-container {
        position: relative;
    }

    &__text-input {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: calc(1.6rem * var(--rem-ratio, 1)) calc(4.2rem * var(--rem-ratio, 1));

        text-align: center;

        border: calc(0.1rem * var(--rem-ratio, 1)) solid $focus-outline;
        border-radius: r.replaceBy($space-2, --hop-shape-rounded-md);

        @include small {
            padding: calc(1rem * var(--rem-ratio, 1)) calc(8rem * var(--rem-ratio, 1));
        }
    }

    &__cancel-button {
        position: absolute;
        top: 50%;
        right: calc(4rem * var(--rem-ratio, 1));

        display: flex;
        align-items: center;

        color: $disabled-text;

        transform: translateY(-50%);
        cursor: pointer;
    }

    &__cancel-icon {
        color: $disabled-text;
    }

    &__submit-button {
        position: absolute;
        top: 50%;
        right: calc(1rem * var(--rem-ratio, 1));

        display: flex;
        align-items: center;

        color: $disabled-text;

        transform: translateY(-50%);
        cursor: not-allowed;

        &--active {
            color: $surface-primary-button-coral;

            cursor: pointer;
        }
    }

    &__submit-icon {
        color: $disabled-text;

        &--active {
            color: $surface-primary-button-coral;
        }
    }
}