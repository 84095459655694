@use '@sass/workleap-rebrand/replace-by' as r;
@use "sass:math";
@import "@sass/utils/all";

.primary-button {
    position: relative;

    display: inline-flex;
    align-items: center;

    white-space: nowrap;

    user-select: none;

    &--small {
        height: r.replaceBy(calc(4.8rem * var(--rem-ratio, 1)), --_height-2_5-rem);
        padding: 0 r.replaceBy($space-4, --hop-space-inset-md);

        @include heading-4;
        line-height: calc(4.8rem * var(--rem-ratio, 1));

        border-radius: r.replaceBy($space-4, --hop-shape-rounded-md);

        html:not([data-brand="workleap"]) & {
            &:focus-visible {
                position: relative;

                outline: none;

                &:after {
                    position: absolute;
                    top: calc(-0.4rem * var(--rem-ratio, 1));
                    right: calc(-0.4rem * var(--rem-ratio, 1));
                    bottom: calc(-0.4rem * var(--rem-ratio, 1));
                    left: calc(-0.4rem * var(--rem-ratio, 1));

                    display: block;

                    border: calc(0.1rem * var(--rem-ratio, 1)) solid $primary-text;
                    border-radius: calc(2.6rem * var(--rem-ratio, 1));

                    content: '';
                }
            }
        }

        [data-brand="workleap"] & {
            @include medium-text;

            font-weight: var(--hop-body-md-medium-font-weight);

            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 calc(0.2rem * var(--rem-ratio, 1)) var(--hop-neutral-surface),
                    0 0 0 calc(0.4rem * var(--rem-ratio, 1)) var(--hop-primary-border-focus);
            }
        }

        &.primary-button--mobile-icon-only {
            height: auto;
            padding: calc(1.2rem * var(--rem-ratio, 1));

            line-height: normal;

            @include small {
                height: calc(4.8rem * var(--rem-ratio, 1));
                padding: 0 calc(2.4rem * var(--rem-ratio, 1));

                line-height: calc(4.8rem * var(--rem-ratio, 1));
            }
        }

        .primary-button__loader {
            width: calc(2rem * var(--rem-ratio, 1));
            height: calc(2rem * var(--rem-ratio, 1));
        }
    }

    &--regular {
        height: calc(7.2rem * var(--rem-ratio, 1));
        padding: 0 calc(4rem * var(--rem-ratio, 1));

        @include heading-4;
        line-height: calc(7.2rem * var(--rem-ratio, 1));

        border-radius: r.replaceBy(calc(math.div(calc(7.2rem * var(--rem-ratio, 1)), 2)), --hop-shape-rounded-md);

        html:not([data-brand="workleap"]) & {
            &:focus-visible {
                position: relative;

                outline: none;

                &:after {
                    position: absolute;
                    top: calc(-0.4rem * var(--rem-ratio, 1));
                    right: calc(-0.4rem * var(--rem-ratio, 1));
                    bottom: calc(-0.4rem * var(--rem-ratio, 1));
                    left: calc(-0.4rem * var(--rem-ratio, 1));

                    display: block;

                    border: calc(0.1rem * var(--rem-ratio, 1)) solid $primary-text;
                    border-radius: calc(3.8rem * var(--rem-ratio, 1));

                    content: '';
                }
            }
        }

        [data-brand="workleap"] & {
            font-weight: var(--hop-heading-xs-font-weight);

            &:focus-visible {
                background-color: var(--hop-primary-surface-strong-hover);
                outline: none;
                box-shadow: 0 0 0 calc(0.2rem * var(--rem-ratio, 1)) var(--hop-neutral-surface), 0 0 0 calc(0.4rem * var(--rem-ratio, 1)) var(--hop-primary-border-focus);
            }
        }

        &.primary-button--mobile-icon-only {
            height: auto;
            padding: calc(1.2rem * var(--rem-ratio, 1));

            line-height: normal;

            @include small {
                height: calc(7.2rem * var(--rem-ratio, 1));
                padding: 0 calc(4rem * var(--rem-ratio, 1));

                line-height: calc(7.2rem * var(--rem-ratio, 1));
            }
        }

        .primary-button__loader {
            width: calc(2.4rem * var(--rem-ratio, 1));
            height: calc(2.4rem * var(--rem-ratio, 1));
        }
    }

    &--coral {
        color: $primary-button-coral-text;

        background-color: $surface-primary-button-coral;

        &:hover:not(:disabled) {
            @include media-hover {
                color: $primary-button-coral-text-hovered;

                background-color: $surface-primary-button-coral-hovered;
            }
        }

        &.primary-button--disabled {
            background-color: $surface-primary-button-coral-disabled;
        }
    }

    &--dandelion {
        color: r.replaceBy($primary-button-dandelion-text, --hop-neutral-text-active);

        background-color: r.replaceBy($surface-primary-button-dandelion, --hop-primary-surface-strong);

        &:hover:not(:disabled) {
            @include media-hover {
                background-color: r.replaceBy($surface-primary-button-dandelion-hovered, --hop-primary-surface-strong-hover);
            }
        }

        &.primary-button--disabled {
            background-color: r.replaceBy($surface-primary-button-dandelion-disabled, --hop-primary-surface-strong-hover);
        }
    }

    &--disabled {
        color: r.replaceBy($disabled-text, --hop-neutral-text-disabled);

        [data-brand="workleap"] & {
            background-color: var(--hop-neutral-surface-disabled);
        }

        cursor: not-allowed;
    }

    &--mobile-icon-only {
        .primary-button__text {
            display: none;

            @include small {
                display: block;
            }
        }

        .primary-button__icon {
            margin: 0;
        }
    }

    &--loading {
        pointer-events: none;

        .primary-button__text,
        .primary-button__icon {
            visibility: hidden;
        }
    }

    &__icon {
        flex-shrink: 0;

        &--leading {
            margin-right: calc(0.8rem * var(--rem-ratio, 1));
        }

        &--trailing {
            margin-left: calc(0.8rem * var(--rem-ratio, 1));
        }
    }

    &__text {
        flex: 1 0 auto;
        min-width: 0;

        [data-brand="workleap"] & {
            margin: 0 r.replaceBy($space-2, --hop-space-inset-sm);
        }

        white-space: nowrap;
        text-align: center;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        &:after {
            display: block;
            width: calc(100% - calc(0.4rem * var(--rem-ratio, 1)));
            height: calc(100% - calc(0.4rem * var(--rem-ratio, 1)));

            border: calc(0.2rem * var(--rem-ratio, 1)) solid transparent;
            border-left-color: currentColor;
            border-radius: 50%;

            animation: spin 750ms infinite linear;

            content: " ";
        }
    }
}