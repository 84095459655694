@use '@sass/workleap-rebrand/replace-by' as r;
@use "sass:math";
@import "@sass/utils/all";

[data-brand="workleap"] {
    --_image-display-none: none;
}

$slider-height: calc(2rem * var(--rem-ratio, 1));
$thumb-size: calc(5.6rem * var(--rem-ratio, 1));
$slider-border-radius: calc(math.div($slider-height, 2));

.two-way-slider-question {
    [data-brand="workleap"] & {
        margin-top: calc(3.2rem * var(--rem-ratio, 1));

        @include small {
            margin-top: calc(4rem * var(--rem-ratio, 1));
        }
    }

    &__drag-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: calc(0.8rem * var(--rem-ratio, 1));

        @keyframes two-way-slider-drag-label-animation {
            0%, 70%, 100% { transform: translateX(0); }
            80%, 90% { transform: translateX(calc(-1.6rem * var(--rem-ratio, 1))); }
            85%, 95% { transform: translateX(calc(1.6rem * var(--rem-ratio, 1))); }
        }

        animation: two-way-slider-drag-label-animation 4000ms infinite;
    }

    &--interacted {
        .two-way-slider-question__drag-label {
            visibility: hidden;
        }
    }

    &__drag-arrow-left {
        html:not([data-brand="workleap"]) & {
            width: calc(1.6rem * var(--rem-ratio, 1));
            height: calc(0.8rem * var(--rem-ratio, 1));
        }

        margin-right: calc(0.8rem * var(--rem-ratio, 1));
    }

    &__drag-arrow-right {
        html:not([data-brand="workleap"]) & {
            width: calc(1.6rem * var(--rem-ratio, 1));
            height: calc(0.8rem * var(--rem-ratio, 1));
        }

        margin-left: calc(0.8rem * var(--rem-ratio, 1));
    }

    &__slider {
        display: flex;
        align-items: flex-start;
        padding: calc(math.div($thumb-size, 2) - math.div($slider-height, 2)) 0 calc(3.2rem * var(--rem-ratio, 1)) 0;
    }

    &__left-cap {
        flex-shrink: 0;
        width: calc(math.div($thumb-size, 2));
        height: $slider-height;

        background-color: $surface-slider-rail;
        border-radius: $slider-border-radius 0 0 $slider-border-radius;

        [data-brand="workleap"] & {
            border: calc(0.2rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-strong);
            border-right: none;
        }
    }

    &__track {
        flex: 1 0 0%;
    }

    &__right-cap {
        flex-shrink: 0;
        width: calc(math.div($thumb-size, 2));
        height: $slider-height;

        background-color: $surface-slider-rail;
        border-radius: 0 $slider-border-radius $slider-border-radius 0;

        [data-brand="workleap"] & {
            border: calc(0.2rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-strong);
            border-left: none;
        }
    }

    &__labels {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: calc(1.6rem * var(--rem-ratio, 1));

        [data-brand="workleap"] & {
            @include heading-5;
            font-weight: var(--hop-heading-xs-font-weight);
        }
    }

    &__min-label {
        width: 45%;

        text-align: left;
    }

    &__max-label {
        width: 45%;

        text-align: right;
    }

    &__image {
        /* stylelint-disable-next-line custom-property-no-missing-var-function */
        display: r.replaceBy(block, --_image-display-none);
        width: calc(25rem * var(--rem-ratio, 1));
        height: calc(15rem * var(--rem-ratio, 1));
        margin: 0 auto;

        @include small {
            display: block;
            width: calc(35rem * var(--rem-ratio, 1));
            height: calc(21rem * var(--rem-ratio, 1));
            margin-top: calc(3.2rem * var(--rem-ratio, 1));
        }
    }

    &__handle-wrapper {
        &--slider {
            &-dragging, &:hover {
                .slider-handle {
                    [data-brand="workleap"] & {
                        @include hover-drop-shadow-stylised;

                        transform: translate(calc(0.5rem * var(--rem-ratio, 1)), calc(-0.5rem * var(--rem-ratio, 1)));
                    }
                }
            }
        }
    }

    .rc-slider {
        position: relative;

        height: $slider-height;

        &-rail {
            position: absolute;

            width: 100%;

            height: $slider-height;

            background-color: $surface-slider-rail;

            [data-brand="workleap"] & {
                border: calc(0.2rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-strong);
                border-right: none;
                border-left: none;
            }
        }

        &-track {
            position: absolute;

            height: $slider-height;

            background-color: $surface-slider-track;

            [data-brand="workleap"] & {
                border: calc(0.2rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-strong);
            }
        }

        &-handle {
            position: absolute;

            margin-top: calc(0px - math.div($thumb-size, 2) - math.div($slider-height, 2));

            border-radius: 50%;

            transform: translateX(-50%);

            cursor: grab;

            &:focus-visible {
                outline: none;
            }
        }

        &-step {
            position: relative;

            height: $slider-height;
        }

        &-dot {
            position: absolute;
            top: 100%;

            width: calc(0.1rem * var(--rem-ratio, 1));
            height: calc(1.6rem * var(--rem-ratio, 1));

            background-color: $surface-slider-step;

            &:first-child,
            &:last-child {
                height: calc(3.2rem * var(--rem-ratio, 1));
            }
        }
    }
}