@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.smart-question {
    max-width: calc(76rem * var(--rem-ratio, 1));
    margin: 0 auto;
    padding: r.replaceBy($space-3 $space-5, --hop-space-inset-md);

    @include small {
        padding: r.replaceBy($space-3 $space-5, --hop-space-inset-squish-lg);
    }

    [data-brand="workleap"] & {
        margin-top: calc(-1 * var(--hop-space-stack-md));
    }

    &--confirmed {
        pointer-events: none;
    }

    &__link {
        color: $primary-text;

        font-weight: var(--font-weight-semibold);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__asked-by {
        margin-top: r.replaceBy($space-5, --hop-space-stack-lg);

        @include small {
            margin-top: r.replaceBy($space-5, --hop-space-stack-lg);
        }
    }

    &__input {
        margin-top: r.replaceBy($space-5, --hop-space-stack-lg);

        @include small {
            margin-top: r.replaceBy($space-5, --hop-space-stack-xl);
        }
    }

    &--animated-intro {
        .smart-question__prompt {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), smart-question-prompt-slide-in-animation);

            animation: fade-in linear 500ms both,
                smart-question-prompt-slide-in-animation ease-out 500ms both;
        }

        .smart-question__asked-by {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), smart-question-asked-by-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                smart-question-asked-by-slide-in-animation ease-out 500ms $delay both;
        }

        .smart-question__input {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), smart-question-input-slide-in-animation);
            $delay: 800ms;

            animation: fade-in linear 500ms $delay both,
                smart-question-input-slide-in-animation ease-out 500ms $delay both;
        }
    }
}