@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.onboarding-survey-multiple-choice-question {
    &--confirmed {
        pointer-events: none;

        .onboarding-survey-multiple-choice-question__choice-label--selected .onboarding-survey-multiple-choice-question__choice-box {
            @keyframes multiple-choice-confirmed-pulse-animation {
                0% { background-color: r.replaceBy($surface-multiple-choice, --hop-neutral-surface); border-color: r.replaceBy($surface-multiple-choice, --hop-neutral-border-strong); }
                100% { background-color: r.replaceBy($surface-multiple-choice-hovered, --hop-decorative-option5-surface-weak); border-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-border-strong); }
            }

            animation: multiple-choice-confirmed-pulse-animation linear 80ms 5 alternate both;
        }
    }

    &__mobile-image {
        display: block;
        width: 100%;
        max-width: calc(24rem * var(--rem-ratio, 1));
        height: calc(14.4rem * var(--rem-ratio, 1));
        margin: 0 auto r.replaceBy($space-3, --hop-space-stack-md) auto;

        @include small {
            display: none;
        }

        [data-brand="workleap"] & {
            display: none;
        }
    }

    &__group {
        display: flex;
        flex-wrap: wrap;
        max-width: calc(114rem * var(--rem-ratio, 1));
        margin: 0 auto;

        [data-brand="workleap"] & {
            margin-top: calc(9.6rem * var(--rem-ratio, 1));

            @include small {
                margin-top: 0;
            }
        }
    }

    &__choice {
        width: 100%;

        &:nth-child(n+2) {
            margin-top: r.replaceBy($space-3, --hop-space-stack-sm);
        }

        @include small {
            width: calc(50% - calc(1.2rem * var(--rem-ratio, 1))); // 1 * margin-left / 2 elements in the row

            // reset styles from mobile
            &:nth-child(n+2) {
                margin-top: 0;
            }

            &:nth-child(even) {
                margin-left: r.replaceBy($space-4, --hop-space-inline-lg);
            }

            &:nth-child(n+3) {
                margin-top: r.replaceBy($space-4, --hop-space-stack-lg);
            }
        }

        @include large {
            width: calc(25% - calc(1.8rem * var(--rem-ratio, 1))); // 3 * margin-left / 4 elements in the row

            &:nth-child(n+2) {
                margin-top: 0;
                margin-left: r.replaceBy($space-4, --hop-space-inline-lg);
            }
        }
    }

    &__radio-input {
        position: absolute;

        width: 0;
        height: 0;

        opacity: 0;

        pointer-events: none;
    }

    &__choice-label {
        @include medium-text;

        @include small {
            [data-brand="workleap"] & {
                @include heading-5;
            }
        }

        cursor: pointer;

        &:hover {
            .onboarding-survey-multiple-choice-question__choice-box {
                color: r.replaceBy($surface-multiple-choice-hovered-border, --hop-neutral-text);

                background-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-surface);
                border-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-border-strong);

                [data-brand="workleap"] & {
                    transform: translateX(calc(0.6rem * var(--rem-ratio, 1))) translateY(calc(-0.6rem * var(--rem-ratio, 1)));
                    @include hover-drop-shadow-stylised;
                }
            }

            .onboarding-survey-multiple-choice-question__label {
                font-weight: r.replaceBy(var(--font-weight-regular), --hop-heading-md-font-weight);
            }
        }

        &--selected {
            .onboarding-survey-multiple-choice-question__choice-box {
                background-color: r.replaceBy($surface-multiple-choice-hovered, --hop-decorative-option5-surface-weak);
                border-color: r.replaceBy($surface-multiple-choice-hovered, --hop-neutral-border-strong);
            }

            .onboarding-survey-multiple-choice-question__label {
                color: r.replaceBy($surface-multiple-choice-selected-border, --hop-neutral-text);

                font-weight: r.replaceBy(var(--font-weight-regular), --hop-heading-md-font-weight);
            }

            .onboarding-survey-multiple-choice-question__selected-icon {
                visibility: visible;
            }
        }

        &--highlighted,
        &--highlighted:hover {
            .onboarding-survey-multiple-choice-question__choice-box {
                border-color: r.replaceBy($surface-multiple-choice-hovered-border, --hop-primary-border-focus);
            }

            .onboarding-survey-multiple-choice-question__label {
                color: r.replaceBy($surface-multiple-choice-hovered-border, --hop-neutral-text);
            }
        }
    }

    &__choice-box {
        position: relative;

        height: 100%;

        background-color: r.replaceBy($surface-multiple-choice, --hop-neutral-surface);
        border: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid r.replaceBy($surface-multiple-choice, --hop-neutral-border-strong);
        border-radius: r.replaceBy($space-2, --hop-shape-rounded-md);

        @include large {
            padding: calc(2.4rem * var(--rem-ratio, 1)) 0;

            [data-brand="workleap"] & {
                padding: calc(2.8rem * var(--rem-ratio, 1)) 0;
            }
        }
    }

    &__image {
        display: none;

        @include small {
            display: block;
            height: calc(14.4rem * var(--rem-ratio, 1));
            margin: 0 auto;
        }

        @include medium {
            height: calc(20rem * var(--rem-ratio, 1));
        }

        @include large {
            height: calc(12.8rem * var(--rem-ratio, 1));
        }

        @include extra-large {
            height: calc(16rem * var(--rem-ratio, 1));
        }
    }

    &__label {
        padding: r.replaceBy($space-3, --hop-space-inset-md) r.replaceBy($space-6, --hop-space-inset-xl);

        font-weight: r.replaceBy(var(--font-weight-regular), --hop-body-md-font-weight);

        text-align: center;
        overflow-wrap: break-word;

        @include small {
            font-weight: r.replaceBy(var(--font-weight-regular), --hop-heading-xs-font-weight);
        }

        @include large {
            margin-top: calc(2.4rem * var(--rem-ratio, 1));
            padding: 0 calc(1.6rem * var(--rem-ratio, 1));
        }
    }

    &__selected-icon {
        position: absolute;
        top: 50%;
        right: calc(1.2rem * var(--rem-ratio, 1));

        color: r.replaceBy($surface-multiple-choice-selected, --hop-neutral-icon);

        transform: translateY(-50%);

        visibility: hidden;

        @include small {
            top: calc(0.8rem * var(--rem-ratio, 1));
            right: calc(0.8rem * var(--rem-ratio, 1));

            transform: none;
        }
    }

    &--animated-intro {
        .base-onboarding-survey-question__header {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), multiple-choice-question-slide-in-animation);

            animation: fade-in linear 500ms both,
                multiple-choice-question-slide-in-animation ease-out 500ms both;
        }

        .onboarding-survey-multiple-choice-question__mobile-image {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), multiple-choice-mobile-image-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                multiple-choice-mobile-image-slide-in-animation ease-out 500ms $delay both;
        }

        .onboarding-survey-multiple-choice-question__choice {
            @include slide-in-keyframes("right", calc(3.2rem * var(--rem-ratio, 1)), multiple-choice-mobile-choice-slide-in-animation);
            $mobileDelay: 800ms;
            $delay: 400ms;
            $increment: 200ms;

            @for $i from 1 through 4 {
                &:nth-child(#{$i}) {
                    $totalDelay: $mobileDelay + $increment * ($i - 1);

                    animation: fade-in linear 500ms $totalDelay both,
                        multiple-choice-mobile-choice-slide-in-animation ease-out 500ms $totalDelay both;

                    @include small {
                        $totalDelay: $delay + $increment * ($i - 1);

                        animation: fade-in linear 500ms $totalDelay both,
                            multiple-choice-mobile-choice-slide-in-animation ease-out 500ms $totalDelay both;
                    }
                }
            }
        }
    }
}