@import "@sass/utils/theme";

.phone-input {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: calc(4rem * var(--rem-ratio, 1));

    background-color: $surface;
    border: calc(0.1rem * var(--rem-ratio, 1)) solid $inputs-border;
    border-radius: var(--border-radius-sm);

    &--error {
        border-color: $error;
    }

    &--focused {
        border-color: $focus-outline;
    }

    &--previewing {
        .phone-input__phone-number-input {
            position: absolute;
            
            width: 0;
            height: 0;

            opacity: 0;

            pointer-events: none;
        }
    }

    &__country-select {
        margin: 0;
    }

    &__phone-number-input {
        flex: 1 0 0%;
        height: calc(3.8rem * var(--rem-ratio, 1));
        margin: 0;

        background-color: transparent;
        border: none;

        &:focus {
            border: none;
        }
    }

    &__readonly-phone-number {
        flex: 1 0 0%;
        height: calc(3.8rem * var(--rem-ratio, 1));
        padding: 0 var(--ids-input-padding);

        line-height: calc(3.8rem * var(--rem-ratio, 1));

        cursor: text;
    }
}