@import "@sass/utils/all";

.pulse-survey-custom-text-question {
    /* stylelint-disable-next-line workleap/no-naked-rem */
    max-width: 47.5rem;
    margin: 0 auto;
    padding: var(--hop-space-inset-md) var(--hop-space-inset-md);

    &--confirmed {
        pointer-events: none;
    }

    &__header {
        margin-bottom: var(--hop-space-stack-lg);

        @include small {
            margin-bottom: var(--hop-space-stack-lg);
        }
    }

    &--animated-intro {
        .pulse-survey-custom-text-question__header {
            @include slide-in-keyframes("bottom", 3rem, custom-text-question-slide-in-animation);

            animation: fade-in linear 500ms both,
                custom-text-question-slide-in-animation ease-out 500ms both;
        }

        .interaction-box {
            @include slide-in-keyframes("bottom", 3rem, custom-text-interaction-box-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                custom-text-interaction-box-slide-in-animation ease-out 500ms $delay both;
        }
    }
}