@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

@include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), pulse-survey-outro-slide-in);

.pulse-survey-outro {
    display: flex;
    justify-content: center;
    padding: 0 calc(1.6rem * var(--rem-ratio, 1)) calc(4.8rem * var(--rem-ratio, 1)) calc(1.6rem * var(--rem-ratio, 1));

    &__content {
        width: 100%;
        max-width: calc(32rem * var(--rem-ratio, 1));
    }

    &__image {
        display: block;
        width: calc(20rem * var(--rem-ratio, 1));
        height: calc(20rem * var(--rem-ratio, 1));
        margin: 0 auto;

        animation: fade-in linear 400ms both,
            pulse-survey-outro-slide-in ease-out 400ms both;
    }

    &__title {
        @include heading-1;
        font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-2xl-font-weight);

        text-align: center;

        animation: fade-in linear 400ms 200ms both,
            pulse-survey-outro-slide-in ease-out 400ms 200ms both;
    }

    &__subtitle {
        margin-top: calc(2.4rem * var(--rem-ratio, 1));

        @include medium-text;
        font-weight: r.replaceBy(var(--font-weight-regular), --hop-body-md-font-weight);
        text-align: center;

        animation: fade-in linear 400ms 400ms both,
            pulse-survey-outro-slide-in ease-out 400ms 400ms both;
    }

    &__redirect-timer-label {
        margin-top: r.replaceBy($space-5, --hop-space-stack-lg);

        @include medium-text;
        white-space: pre-line;
        text-align: center;

        animation: fade-in linear 400ms 600ms both,
            pulse-survey-outro-slide-in ease-out 400ms 600ms both;
    }

    &__remaining-seconds-before-redirect {
        font-weight: var(--font-weight-semibold);
        white-space: nowrap;
    }

    &__go-to-officevibe-button {
        display: flex;
        margin: calc(3.2rem * var(--rem-ratio, 1)) auto 0 auto;

        animation: fade-in linear 400ms 800ms both,
            pulse-survey-outro-slide-in ease-out 400ms 800ms both;
    }
}