@import "@sass/utils/all";

.deib-survey-slider-question {
    @include small {
        max-width: calc(76rem * var(--rem-ratio, 1));
        margin: 0 auto;
    }

    &--animated-intro {
        .base-deib-survey-question__header {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), slider-question-slide-in-animation);

            animation: fade-in linear 500ms both,
                slider-question-slide-in-animation ease-out 500ms both;
        }

        .slider-question__drag-label,
        .slider-question__slider,
        .slider-question__labels {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), slider-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                slider-slide-in-animation ease-out 500ms $delay both;
        }

        .slider-question__image {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), slider-image-slide-in-animation);
            $delay: 800ms;

            animation: fade-in linear 500ms $delay both,
                slider-image-slide-in-animation ease-out 500ms $delay both;
        }
    }
}