@use '@sass/workleap-rebrand/replace-by' as r;
@use "sass:math";
@import "@sass/utils/all";

.tag {
    display: inline-block;
    height: calc(3.2rem * var(--rem-ratio, 1));
    padding: 0 calc(1.6rem * var(--rem-ratio, 1));

    @include small-text;
    color: $tag-text;
    line-height: r.replaceBy($space-5, --hop-body-sm-line-height);
    white-space: nowrap;

    border-radius: r.replaceBy(calc(math.div(calc(3.2rem * var(--rem-ratio, 1)), 2)), --hop-shape-pill);

    [data-brand="workleap"] & {
        padding: calc(0.6rem * var(--rem-ratio, 1)) var(--hop-space-inset-md);
    }

    &--creamsicle {
        background-color: $surface-tag-creamsicle;

        [data-brand="workleap"] & {
            color: var(--hop-decorative-option3-text);
        }
    }

    &--seaweed {
        background-color: $surface-tag-seaweed;

        [data-brand="workleap"] & {
            color: var(--hop-decorative-option2-text);
        }
    }

    &--dandelion {
        background-color: $surface-tag-dandelion;

        [data-brand="workleap"] & {
            color: var(--hop-decorative-option6-text);
        }
    }

    &--electric-blue {
        background-color: $surface-tag-electric-blue;

        [data-brand="workleap"] & {
            color: var(--hop-decorative-option1-text);
        }
    }

    &--sky {
        background-color: $surface-tag-sky;

        [data-brand="workleap"] & {
            color: var(--hop-decorative-option5-text);
        }
    }

    &--coral {
        background-color: $surface-tag-coral;

        [data-brand="workleap"] & {
            color: var(--hop-decorative-option4-text);
        }
    }

    &--strawberry-fields {
        background-color: $surface-tag-strawberry-fields;

        [data-brand="workleap"] & {
            color: var(--hop-decorative-option8-text);
        }
    }

    &--compact {
        height: calc(2.4rem * var(--rem-ratio, 1));
        padding: 0 calc(0.8rem * var(--rem-ratio, 1));

        @include caption;
        line-height: r.replaceBy(calc(2.4rem * var(--rem-ratio, 1)), --hop-body-xs-line-height);

        border-radius: r.replaceBy(calc(math.div(calc(2.4rem * var(--rem-ratio, 1)), 2)), --hop-shape-pill);

        [data-brand="workleap"] & {
            padding: var(--hop-space-inset-squish-sm);
        }
    }
}