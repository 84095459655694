@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.slider-handle-wrapper {
    touch-action: none;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        position: relative;

        &:after {
            position: absolute;
            top: calc(-0.4rem * var(--rem-ratio, 1));
            right: calc(-0.4rem * var(--rem-ratio, 1));
            bottom: calc(-0.4rem * var(--rem-ratio, 1));
            left: calc(-0.4rem * var(--rem-ratio, 1));

            display: block;

            border: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid $focus-outline;
            border-radius: 50%;

            content: '';
        }
    }

    &__content {
        pointer-events: none;
    }
}