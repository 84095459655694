//Extra small devices (phones, 600px and down)
// -> we are mobile-first so we do not need a breakpoint for this width

// Small devices (portrait tablets and large phones, 600px and up
$small: 600px;

// Medium devices (landscape tablets, 768px and up)
$medium: 768px;

// Large devices (laptops an tablets, 992px and up)
$large: 992px;

// Extra Large devices (laptops and desktops, 1200px and up)
$extra-large: 1200px;

// Extra Large devices (large laptops and desktops, 1440px and up)
$extra-extra-large: 1440px;

@mixin small {
    @media (min-width: $small) {
        @content;
    }
}

@mixin medium {
    @media (min-width: $medium) {
        @content;
    }
}

@mixin large {
    @media (min-width: $large) {
        @content;
    }
}

@mixin extra-large {
    @media (min-width: $extra-large) {
        @content;
    }
}

@mixin extra-extra-large {
    @media (min-width: $extra-extra-large) {
        @content;
    }
}