@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.recognition-prompt {
    display: inline-block;
    width: 100%;
    padding: calc(1.6rem * var(--rem-ratio, 1));

    background-color: $surface;
    border-radius: var(--border-radius-sm);
    @include card-elevation;

    [data-brand="workleap"] & {
        height: 100%;
        padding: var(--hop-space-inset-md);

        border: calc(0.1rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-strong);
        border-radius: var(--hop-shape-rounded-md);
    }

    &:focus-visible {
        outline: none;
    }

    &--interactible {
        cursor: pointer;

        &:hover {
            .recognition-prompt__button {
                @include media-hover {
                    animation: fade-in 200ms linear both;
                }
            }

            .recognition-prompt__category {
                @include media-hover {
                    opacity: 0;
                }
            }

            [data-brand="workleap"] & {
                background-color: var(--hop-neutral-surface-hover);

                &.recognition-prompt--creamsicle .recognition-prompt__content {
                    background-color: var(--hop-decorative-option3-surface-hover);
                }

                &.recognition-prompt--seaweed .recognition-prompt__content {
                    background-color: var(--hop-decorative-option2-surface-hover);
                }

                &.recognition-prompt--dandelion .recognition-prompt__content {
                    background-color: var(--hop-decorative-option6-surface-hover);
                }

                &.recognition-prompt--electric-blue .recognition-prompt__content {
                    background-color: var(--hop-decorative-option1-surface-hover);
                }

                &.recognition-prompt--sky .recognition-prompt__content {
                    background-color: var(--hop-decorative-option5-surface-hover);
                }

                &.recognition-prompt--coral .recognition-prompt__content {
                    background-color: var(--hop-decorative-option4-surface-hover);
                }

                &.recognition-prompt--strawberry-fields .recognition-prompt__content {
                    background-color: var(--hop-decorative-option8-surface-hover);
                }
            }
        }

        &:focus-visible {
            outline: calc(0.1rem * var(--rem-ratio, 1)) solid $focus-outline;

            .recognition-prompt__button {
                @include media-hover {
                    opacity: 1;
                }
            }

            .recognition-prompt__category {
                @include media-hover {
                    opacity: 0;
                }
            }
        }
    }

    &--creamsicle .recognition-prompt__content {
        background-color: $surface-recognition-card-creamsicle;
    }

    &--seaweed .recognition-prompt__content {
        background-color: $surface-recognition-card-seaweed;
    }

    &--dandelion .recognition-prompt__content {
        background-color: $surface-recognition-card-dandelion;
    }

    &--electric-blue .recognition-prompt__content {
        background-color: $surface-recognition-card-electric-blue;
    }

    &--sky .recognition-prompt__content {
        background-color: $surface-recognition-card-sky;
    }

    &--coral .recognition-prompt__content {
        background-color: $surface-recognition-card-coral;
    }

    &--strawberry-fields .recognition-prompt__content {
        background-color: $surface-recognition-card-strawberry-fields;
    }

    &__content {
        padding: r.replaceBy(calc(1.6rem * var(--rem-ratio, 1)), --hop-space-inset-md);

        border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-rounded-md);

        @include small {
            padding: r.replaceBy(calc(2.4rem * var(--rem-ratio, 1)), --hop-space-inset-lg);
        }

        [data-brand="workleap"] & {
            height: 100%;
        }
    }

    &__prompt {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        height: calc(9.6rem * var(--rem-ratio, 1));

        overflow: hidden;

        color: $primary-text-alt;

        @include heading-4;
        font-weight: var(--font-weight-medium);

        overflow-wrap: break-word;

        [data-brand="workleap"] & {
            @include heading-4;
            font-weight: var(--hop-heading-sm-font-weight)
        }
    }

    &__category {
        pointer-events: none;
    }

    &__footer {
        position: relative;

        display: flex;
        align-items: flex-end;

        height: calc(4rem * var(--rem-ratio, 1));
        margin-top: calc(2rem * var(--rem-ratio, 1));

        @include small {
            margin-top: calc(0.4rem * var(--rem-ratio, 1));
        }
    }

    &__button {
        display: none;

        @include media-hover {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;

            display: flex;
            width: 100%;

            opacity: 0;

            [data-brand="workleap"] & {
                top: initial;
            }
        }
    }
}