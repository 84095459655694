@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.primary-icon-button {
    position: relative;

    display: inline-flex;
    align-items: center;

    user-select: none;

    &--small {
        padding: r.replaceBy(calc(1.2rem * var(--rem-ratio, 1)), --hop-space-inset-xs);

        @include heading-4;

        border-radius: r.replaceBy(calc(2.4rem * var(--rem-ratio, 1)), --hop-shape-rounded-md);

        &:focus-visible {
            position: relative;

            outline: none;

            &:after {
                position: absolute;
                top: calc(-0.4rem * var(--rem-ratio, 1));
                right: calc(-0.4rem * var(--rem-ratio, 1));
                bottom: calc(-0.4rem * var(--rem-ratio, 1));
                left: calc(-0.4rem * var(--rem-ratio, 1));
                
                display: block;
    
                border: calc(0.1rem * var(--rem-ratio, 1)) solid $primary-text;
                border-radius: calc(2.6rem * var(--rem-ratio, 1));
    
                content: '';
            }
        }

        .primary-icon-button__loader {
            width: calc(2rem * var(--rem-ratio, 1));
            height: calc(2rem * var(--rem-ratio, 1));
        }
    }

    &--regular {
        padding: calc(2.4rem * var(--rem-ratio, 1));

        @include heading-4;

        border-radius: calc(3.6rem * var(--rem-ratio, 1));

        &:focus-visible {
            position: relative;

            outline: none;

            &:after {
                position: absolute;
                top: calc(-0.4rem * var(--rem-ratio, 1));
                right: calc(-0.4rem * var(--rem-ratio, 1));
                bottom: calc(-0.4rem * var(--rem-ratio, 1));
                left: calc(-0.4rem * var(--rem-ratio, 1));
                
                display: block;
    
                border: calc(0.1rem * var(--rem-ratio, 1)) solid $primary-text;
                border-radius: calc(3.8rem * var(--rem-ratio, 1));
    
                content: '';
            }
        }

        .primary-icon-button__loader {
            width: calc(2.4rem * var(--rem-ratio, 1));
            height: calc(2.4rem * var(--rem-ratio, 1));
        }
    }

    &--coral {
        color: $primary-button-coral-text;

        background-color: $surface-primary-button-coral;

        &:hover:not(:disabled) {
            @include media-hover {
                background-color: $surface-primary-button-coral-hovered;
            }
        }

        &.primary-icon-button--disabled {
            background-color: $surface-primary-button-coral-disabled;
        }
    }

    &--dandelion {
        color: r.replaceBy($primary-button-dandelion-text, --hop-neutral-icon-active);

        background-color: r.replaceBy($surface-primary-button-dandelion, --hop-primary-surface-strong);

        &:hover:not(:disabled) {
            @include media-hover {
                background-color: r.replaceBy($surface-primary-button-dandelion-hovered, --primary-surface-strong-hover);
            }
        }

        &.primary-icon-button--disabled {
            background-color: r.replaceBy($surface-primary-button-dandelion-disabled, --primary-surface-disabled);
        }
    }

    &--disabled {
        color: $disabled-text;

        cursor: not-allowed;
    }

    &--loading {
        pointer-events: none;

        .primary-icon-button__icon {
            visibility: hidden;
        }
    }

    &__icon {
        flex-shrink: 0;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        &:after {
            display: block;
            width: calc(100% - calc(0.4rem * var(--rem-ratio, 1)));
            height: calc(100% - calc(0.4rem * var(--rem-ratio, 1)));

            border: calc(0.2rem * var(--rem-ratio, 1)) solid transparent;
            border-left-color: currentColor;
            border-radius: 50%;

            animation: spin 750ms infinite linear;

            content: " ";
        }
    }
}