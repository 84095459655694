@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.peer-select-option-loading {
    display: flex;
    align-items: center;
    padding: r.replaceBy($space-1 $space-3, --hop-space-inset-squish-md);

    &__thumbnail {
        width: calc(3.2rem * var(--rem-ratio, 1));
        height: calc(3.2rem * var(--rem-ratio, 1));
        margin-right: r.replaceBy($space-2, --hop-space-inline-sm);

        border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-circle);

        animation: loading-pulse-light ease-in-out 1500ms infinite both;

        [data-brand="workleap"] & {
            width: calc(2.4rem * var(--rem-ratio, 1));
            height: calc(2.4rem * var(--rem-ratio, 1));
        }
    }

    &__fullname {
        width: 60%;
        height: calc(1.2rem * var(--rem-ratio, 1));

        border-radius: r.replaceBy($border-radius-md, --hop-shape-pill);

        animation: loading-pulse-light ease-in-out 1500ms infinite both;
    }
}