@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.signup-pulse-survey-feedback-explanation-slide {
    display: flex;
    flex-direction: column;

    &__header {
        flex-shrink: 0;

        padding: r.replaceBy(inherit, --_signup-activation-feedback-explanation-slide-image-mobile-padding-y) r.replaceBy(inherit, --_signup-activation-feedback-explanation-slide-image-mobile-padding-x);

        @include small {
            padding: r.replaceBy(inherit, --hop-space-inset-md);
        }
    }

    &__body {
        flex: 1 0 auto;
        min-width: 0;

        @include ie {
            flex: 0 0 auto;
        }
    }

    &__footer {
        flex-shrink: 0;
    }
}