@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.interaction-box {
    padding: r.replaceBy($space-3, --hop-space-inset-md);

    background-color: r.replaceBy($surface, --hop-neutral-surface);
    border-radius: r.replaceBy(var(--border-radius-card), --hop-shape-rounded-md);
    box-shadow: 0 calc(0.1rem * var(--rem-ratio, 1)) calc(0.6rem * var(--rem-ratio, 1)) 0 rgba(#233043, 0.1);

    @include small {
        padding: r.replaceBy($space-4, --hop-space-inset-lg)  r.replaceBy($space-5, --hop-space-inset-lg);
    }

    [data-brand="workleap"] & {
        display: flex;
        flex-direction: column;

        border: calc(0.16rem * var(--rem-ratio, 1)) solid var(--hop-neutral-border-weak);

        box-shadow: none;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: r.replaceBy($space-2, --hop-space-stack-md);

        @include small {
            justify-content: space-between;
            margin-bottom: r.replaceBy($space-3, --hop-space-stack-md);
        }
    }

    &__author {
        margin-right: calc(1.6rem * var(--rem-ratio, 1));

        line-height: 0;

        @include small {
            display: flex;
            align-items: center;
        }
    }

    &__author-image {
        width: calc(3.2rem * var(--rem-ratio, 1));
        height: calc(3.2rem * var(--rem-ratio, 1));

        background-repeat: no-repeat;
        background-size: cover;
        border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-circle);

        user-select: none;

        @include small {
            margin-right: r.replaceBy($space-3, --hop-space-inline-md);
        }
    }

    &__author-name {
        display: none;

        @include small {
            display: block;

            @include medium-text;

            [data-brand="workleap"] & {
                @include heading-5;

                font-weight: r.replaceBy(normal, --hop-heading-xs-font-weight);
            }
        }
    }

    &__footer {
        margin-top: r.replaceBy($space-2, --hop-space-stack-md);

        @include small {
            display: flex;
            justify-content: flex-end;
            margin-top: r.replaceBy($space-3, --hop-space-stack-md);
        }

        [data-brand="workleap"] & {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__button {
        width: 100%;
        padding: r.replaceBy($space-0, --hop-space-inset-md) r.replaceBy($space-4, --hop-space-inset-lg);

        @include small {
            width: auto;
        }

        [data-brand="workleap"] & {
            width: auto;
            height: calc(4.8rem * var(--rem-ratio, 1));

            font-weight: var(--hop-body-lg-font-weight);
            font-size: var(--hop-body-lg-font-size);
            font-family: var(--hop-font-family-primary);
            line-height: var(--hop-body-lg-line-height);
        }
    }

    .anonymity-tooltip {
        [data-brand="workleap"] & {
            margin-left: auto;
        }
    }

    .ids-toggle {
        line-height: calc(1.0rem * var(--rem-ratio, 1));
    }

    .ids-toggle__text {
        @include heading-5;

        font-weight: var(--hop-heading-xs-font-weight);
    }

    .ids-textarea__field {
        @include medium-text;

        line-height: r.replaceBy(inherit, --hop-body-md-line-height);
    }
}