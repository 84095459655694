@import "@sass/utils/all";

.select-option {
    display: block;
    width: 100%;

    padding: calc(0.4rem * var(--rem-ratio, 1)) calc(1.6rem * var(--rem-ratio, 1));

    cursor: pointer;

    user-select: none;

    &:hover {
        @include media-hover {
            background-color: $brand-highlight;
        }
    }

    &--selected {
        background-color: $brand-highlight;
    }

    &--keyboard-focused {
        background-color: $brand-highlight-dim;
    }
}