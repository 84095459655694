@use '@sass/workleap-rebrand/replace-by' as r;

@import "@sass/utils/all";

.signup-slide-title-header {
    margin-bottom: r.replaceBy($space-4, --hop-space-stack-lg);
    padding: 0 calc(1.6rem * var(--rem-ratio, 1));

    @include small {
        padding: calc(6.4rem * var(--rem-ratio, 1)) calc(1.6rem * var(--rem-ratio, 1)) 0 calc(1.6rem * var(--rem-ratio, 1));
    }

    &__title {
        margin-bottom: r.replaceBy($space-2, --hop-space-stack-sm);

        @include heading-3;
        font-weight: r.replaceBy($font-weight-medium, --hop-heading-lg-font-weight);
        text-align: center;
    }

    &__subtitle {
        color: r.replaceBy($secondary-text, --hop-neutral-text-weak);
        text-align: center;
    }
}