* {
    box-sizing: border-box;
}

a {
    text-decoration: none;

    &:focus {
        outline: none;
    }

    &:visited {
        color: inherit;
    }
}