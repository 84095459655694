@import "@sass/utils/all";

.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 calc(1.6rem * var(--rem-ratio, 1));

    &__image-container {
        display: flex;
        justify-content: center;
    }

    &__image {
        width: calc(30rem * var(--rem-ratio, 1));
        height: calc(24rem * var(--rem-ratio, 1));
    }

    &__title {
        @include heading-4;
        text-align: center;
    }

    &__subtitle {
        margin-top: calc(0.8rem * var(--rem-ratio, 1));

        text-align: center;
    }

    &__reload-link {
        font-weight: var(--font-weight-semibold);
        text-decoration: underline;

        cursor: pointer;
    }
}