@use './../workleap-rebrand/replace-by' as r;

@mixin heading-display-xlarge() {
    font-size: var(--font-size-9);
    font-family: var(--secondary-font-family);
    line-height: var(--line-height-sm);
}

@mixin heading-1() {
    font-weight: r.replaceBy(normal, --hop-heading-2xl-font-weight);
    font-size: r.replaceBy($font-size-8, --hop-heading-2xl-font-size);
    font-family: r.replaceBy($secondary-font-family, --hop-heading-2xl-font-family);
    line-height: r.replaceBy($line-height-sm, --hop-heading-2xl-line-height);
}

@mixin heading-2() {
    font-weight: r.replaceBy(normal, --hop-heading-xl-font-weight);
    font-size: r.replaceBy($font-size-7, --hop-heading-xl-font-size);
    font-family: r.replaceBy($secondary-font-family, --hop-heading-xl-font-family);
    line-height: r.replaceBy($line-height-xs, --hop-heading-xl-line-height);
}

@mixin heading-3() {
    font-weight: r.replaceBy(normal, --hop-heading-lg-font-weight);
    font-size: r.replaceBy($font-size-6, --hop-heading-lg-font-size);
    font-family: r.replaceBy($secondary-font-family, --hop-heading-lg-font-family);
    line-height: r.replaceBy($line-height-lg, --hop-heading-lg-line-height);
}

@mixin heading-4() {
    font-weight: r.replaceBy(normal, --hop-heading-sm-font-weight);
    font-size: r.replaceBy($font-size-5, --hop-heading-sm-font-size);
    font-family: r.replaceBy($secondary-font-family, --hop-heading-sm-font-family);
    line-height: r.replaceBy($line-height-xl, --hop-heading-sm-line-height);
}

@mixin heading-5() {
    font-weight: r.replaceBy(normal, --hop-heading-xs-medium-font-weight);
    font-size: r.replaceBy($font-size-4, --hop-heading-xs-font-size);
    font-family: r.replaceBy($secondary-font-family, --hop-heading-xs-font-family);
    line-height: r.replaceBy($line-height-md, --hop-heading-xs-line-height);
}

@mixin large-text() {
    font-size: r.replaceBy($font-size-5, --hop-body-lg-font-size);
    font-family: r.replaceBy($primary-font-family, --hop-body-lg-font-family);
    line-height: r.replaceBy($line-height-xl, --hop-heading-2xl-line-height);
}

@mixin medium-text() {
    font-size: r.replaceBy($font-size-4, --hop-body-md-font-size);
    font-family: r.replaceBy($primary-font-family, --hop-body-md-font-family);
    line-height: r.replaceBy($line-height-xxxl, --hop-body-md-line-height);
}

@mixin small-text() {
    font-size: r.replaceBy($font-size-3, --hop-body-sm-font-size);
    font-family: r.replaceBy($primary-font-family, --hop-body-sm-font-family);
    line-height: r.replaceBy($line-height-xxxl, --hop-body-sm-line-height);
}

@mixin caption() {
    font-size: r.replaceBy($font-size-2, --hop-body-xs-font-size);
    font-family: r.replaceBy($primary-font-family, --hop-body-xs-font-family);
    line-height: r.replaceBy($line-height-xxxl, --hop-body-xs-line-height);
}

@mixin overline() {
    font-size: var(--font-size-2);
    font-family: var(--primary-font-family);
    line-height: var(--line-height-xl);
    letter-spacing: calc(0.02rem * var(--rem-ratio, 1));
}

@mixin accent-lg() {
    font-weight: var(--hop-accent-lg-font-weight);
    font-size: var(--hop-accent-lg-font-size);
    font-family: var(--hop-accent-lg-font-family);
    line-height: var(--hop-accent-lg-line-height);
}