@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.ghost-button {
    position: relative;

    display: inline-flex;
    align-items: center;

    height: calc(4.8rem * var(--rem-ratio, 1));
    padding: 0 calc(1.6rem * var(--rem-ratio, 1));

    @include heading-5;
    line-height: calc(4.8rem * var(--rem-ratio, 1));
    white-space: nowrap;

    cursor: pointer;

    user-select: none;

    [data-brand="workleap"] & {
        align-items: flex-start;

        line-height: calc(2.6rem * var(--rem-ratio, 1));
    }

    &:hover {
        @include media-hover {
            text-decoration: underline;
        }
    }

    &:focus-visible {
        position: relative;

        outline: none;

        &:after {
            position: absolute;
            top: calc(-0.4rem * var(--rem-ratio, 1));
            right: calc(-0.4rem * var(--rem-ratio, 1));
            bottom: calc(-0.4rem * var(--rem-ratio, 1));
            left: calc(-0.4rem * var(--rem-ratio, 1));

            display: block;

            border: calc(0.1rem * var(--rem-ratio, 1)) solid currentColor;
            border-radius: calc(2.8rem * var(--rem-ratio, 1));

            content: '';
        }
    }

    &--dark {
        color: $ghost-button-text-dark;
    }

    &--light {
        color: $ghost-button-text-light;
    }

    &--disabled {
        cursor: not-allowed;

        opacity: 0.5;

        [data-brand="workleap"] & {
            color: var(--hop-neutral-text-disabled);

            opacity: 1;
        }

        &:hover {
            @include media-hover {
                text-decoration: none;

                opacity: 0.5;

                [data-brand="workleap"] & {
                    color: var(--hop-neutral-text-disabled);

                    opacity: 1;
                }
            }
        }
    }

    &--mobile-icon-only {
        .ghost-button__text {
            display: none;

            @include small {
                display: block;
            }
        }

        .ghost-button__icon {
            margin: 0;
        }
    }

    &--loading {
        pointer-events: none;

        .ghost-button__text,
        .ghost-button__icon {
            visibility: hidden;
        }
    }

    &__text {
        flex: 1 0 auto;
        min-width: 0;

        white-space: nowrap;
        text-align: center;
    }

    &__icon {
        flex-shrink: 0;

        &--leading {
            margin-right: r.replaceBy($space-2, --hop-space-inline-xs);
        }

        &--trailing {
            margin-left: calc(0.8rem * var(--rem-ratio, 1));
        }
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        &:after {
            display: block;
            width: calc(1.2rem * var(--rem-ratio, 1));
            height: calc(1.2rem * var(--rem-ratio, 1));

            border: calc(0.2rem * var(--rem-ratio, 1)) solid transparent;
            border-left-color: currentColor;
            border-radius: 50%;

            animation: spin 750ms infinite linear;

            content: " ";
        }
    }
}