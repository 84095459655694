@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.tip {
    padding-top: r.replaceBy($space-2, --hop-space-inset-sm);
    padding-right: r.replaceBy($space-2, --hop-space-inset-sm);

    transition: transform ease-out 400ms,
        opacity linear 400ms;

    &--hidden {
        transform: translateX(calc(6.4rem * var(--rem-ratio, 1)));
        opacity: 0;
    }
}