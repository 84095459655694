@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.preview-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: r.replaceBy($space-1 $space-2, --hop-space-inset-squish-sm);

    color: r.replaceBy(initial, --hop-primary-text-strong);
    text-align: center;

    background-color: $surface-previewing-banner;

    @include small {
        height: calc(3.2rem * var(--rem-ratio, 1));
        padding: r.replaceBy($space-2, --hop-space-inset-sm);

        [data-brand="workleap"] & {
            height: calc(4rem * var(--rem-ratio, 1));
        }
    }

    &__icon {
        display: none;

        @include small {
            display: block;
            flex-shrink: 0;
            width: calc(2.4rem * var(--rem-ratio, 1));
            height: calc(2.4rem * var(--rem-ratio, 1));
            margin-right: r.replaceBy($space-2, --hop-space-inline-sm);
    
            fill: currentColor;
        }
    }
}