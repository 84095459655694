@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.activities-control-bar {
    display: flex;
    align-items: center;
    padding: calc(0.8rem * var(--rem-ratio, 1));

    @include slide-in-keyframes("bottom", 100%, activities-control-bar-slide-in-animation);

    animation: fade-in linear 400ms both,
        activities-control-bar-slide-in-animation ease-out 400ms both;

    pointer-events: all;

    @include small {
        padding: calc(1.6rem * var(--rem-ratio, 1)) calc(2.4rem * var(--rem-ratio, 1));
    }

    &--default {
        background-color: $surface-control-bar-light;
    }

    &--floating {
        pointer-events: none;

        .activities-control-bar__skip-button {
            pointer-events: initial;

            [data-brand="workleap"] & {
                //Override the default text of the ghost button
                .ghost-button__text {
                    color: var(--hop-status-neutral-text);

                    @include large-text;
                    font-weight: var(--hop-font-weight-400);
                    line-height: calc(3.2rem * var(--rem-ratio, 1));
                }

                //Override the default icon of the ghost button
                svg {
                    color: var(--hop-status-neutral-text);
                    line-height: calc(3.2rem * var(--rem-ratio, 1));
                }
            }
        }
    }

    &--hidden {
        @include slide-out-keyframes("bottom", 100%, activities-control-bar-slide-out-animation);

        animation: fade-out linear 600ms both,
            activities-control-bar-slide-out-animation ease-out 600ms both;

        pointer-events: none;
    }

    &__left-column {
        flex: 0 1 100%;
    }

    &__middle-column {
        flex-shrink: 0;
    }

    &__flow-steps {
        display: flex;
        align-items: center;
        margin: 0 calc(0.4rem * var(--rem-ratio, 1));

        white-space: nowrap;
    }

    &__flow-step + &__flow-step {
        margin-left: calc(0.4rem * var(--rem-ratio, 1));
    }

    &__right-column {
        display: flex;
        flex: 0 1 100%;
        justify-content: flex-end;
    }
}