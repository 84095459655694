@import "./theme";

@mixin shadow-6() {
    box-shadow: 0 calc(0.1rem * var(--rem-ratio, 1)) calc(0.6rem * var(--rem-ratio, 1)) 0 rgba(#233043, 0.1);
}

@mixin shadow-12() {
    box-shadow: 0 calc(0.4rem * var(--rem-ratio, 1)) calc(1rem * var(--rem-ratio, 1)) calc(0.4rem * var(--rem-ratio, 1)) rgba(#233043, 0.1);
}

@mixin shadow-24() {
    box-shadow: 0 calc(1rem * var(--rem-ratio, 1)) calc(1.8rem * var(--rem-ratio, 1)) calc(0.8rem * var(--rem-ratio, 1)) rgba(#233043, 0.1);
}

@mixin card-elevation() {
    @include shadow-6;
}

@mixin popover-elevation() {
    @include shadow-12;
}

@mixin hover-drop-shadow-stylised() {
    box-shadow: calc(-0.5rem * var(--rem-ratio, 1)) calc(0.5rem * var(--rem-ratio, 1)) 0 0 var(--hop-neutral-border-strong),
        calc(-0.4rem * var(--rem-ratio, 1)) calc(0.4rem * var(--rem-ratio, 1)) 0 0 var(--hop-neutral-border-strong),
        calc(-0.3rem * var(--rem-ratio, 1)) calc(0.3rem * var(--rem-ratio, 1)) 0 0 var(--hop-neutral-border-strong),
        calc(-0.2rem * var(--rem-ratio, 1)) calc(0.2rem * var(--rem-ratio, 1)) 0 0 var(--hop-neutral-border-strong),
        calc(-0.1rem * var(--rem-ratio, 1)) calc(0.1rem * var(--rem-ratio, 1)) 0 0 var(--hop-neutral-border-strong);
}

$z-index-background: -1;
$z-index-popover: 100;