@import "@sass/utils/all";

.inline-button {
    padding: 0 calc(0.4rem * var(--rem-ratio, 1));

    text-decoration: underline;

    &:focus-visible {
        position: relative;

        outline: none;

        &:after {
            position: absolute;
            top: calc(-0.4rem * var(--rem-ratio, 1));
            right: calc(-0.4rem * var(--rem-ratio, 1));
            bottom: calc(-0.4rem * var(--rem-ratio, 1));
            left: calc(-0.4rem * var(--rem-ratio, 1));
            
            display: block;

            border: calc(0.1rem * var(--rem-ratio, 1)) solid $primary-text;
            border-radius: calc(3.8rem * var(--rem-ratio, 1));

            content: '';
        }
    }
}