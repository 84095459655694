@import "@sass/utils/all";

.signup-pulse-survey-answers-explanation-slide {
    display: flex;
    flex-direction: column;

    &__header {
        flex-shrink: 0;
    }

    &__body {
        flex: 1 0 auto;
        min-width: 0;

        @include ie {
            flex: 0 0 auto;
        }
    }

    &__footer {
        flex-shrink: 0;
    }
}