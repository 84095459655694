@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.signup-flow {
    display: flex;
    flex-direction: column;

    @include small {
        align-items: center;
        justify-content: center;
    }

    &--intro {
        overflow-y: hidden;

        pointer-events: none;

        .signup-flow__slide {
            @include slide-in-keyframes("bottom", calc(4rem * var(--rem-ratio, 1)), signup-slide-intro-animation);
    
            animation: fade-in linear 400ms 600ms both,
                signup-slide-intro-animation ease-out 400ms 600ms both;
        }
    }

    &--slide-in {
        overflow-x: hidden;

        pointer-events: none;

        .signup-flow__slide {
            @include slide-in-keyframes("right", calc(6rem * var(--rem-ratio, 1)), signup-slide-slide-in-animation);

            animation: fade-in linear 400ms both,
                signup-slide-slide-in-animation ease-out 400ms both;
        }
    }

    &--slide-out {
        overflow-x: hidden;

        pointer-events: none;

        .signup-flow__slide {
            @include slide-out-keyframes("left", calc(6rem * var(--rem-ratio, 1)), signup-slide-slide-out-animation);

            animation: fade-out linear 400ms both,
                signup-slide-slide-out-animation ease-in 400ms both;
        }
    }

    &--slide-in-reverse {
        overflow-x: hidden;

        pointer-events: none;

        .signup-flow__slide {
            @include slide-in-keyframes("left", calc(6rem * var(--rem-ratio, 1)), signup-slide-slide-in-reverse-animation);

            animation: fade-in linear 400ms both,
                signup-slide-slide-in-reverse-animation ease-out 400ms both;
        }
    }

    &--slide-out-reverse {
        overflow-x: hidden;

        pointer-events: none;

        .signup-flow__slide {
            @include slide-out-keyframes("right", calc(6rem * var(--rem-ratio, 1)), signup-slide-slide-out-reverse-animation);

            animation: fade-out linear 400ms both,
                signup-slide-slide-out-reverse-animation ease-in 400ms both;
        }
    }

    &--pending {
        pointer-events: none;

        .signup-flow__slide {
            visibility: hidden;
        }
    }

    &__slide {
        flex: 1 0 auto;

        @include small {
            flex: 0 0 auto;
            width: 100%;
            max-width: calc(56rem * var(--rem-ratio, 1));
            min-height: r.replaceBy(calc(53rem * var(--rem-ratio, 1)), --_auto);
            
            background-color: $surface;
            border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-rounded-md);
            @include card-elevation;

            [data-brand="workleap"] & {
                border: 2px solid var(--hop-neutral-border-strong);
                box-shadow: -8px 8px 0 0 var(--hop-neutral-border-strong), -7px 7px 0 0 var(--hop-neutral-border-strong), -6px 6px 0 0 var(--hop-neutral-border-strong), -5px 5px 0 0 var(--hop-neutral-border-strong), -4px 4px 0 0 var(--hop-neutral-border-strong), -3px 3px 0 0 var(--hop-neutral-border-strong), -2px 2px 0 0 var(--hop-neutral-border-strong), -1px 1px 0 0 var(--hop-neutral-border-strong);
            }

            @include ie {
                min-height: auto;
            }
        }
    }
}