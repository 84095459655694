/* stylelint-disable workleap/no-naked-rem */
@import "@sass/utils/all";

.recognition-activity-customize-message-step {
    margin-top: 3rem;
    padding: 0 var(--hop-space-inset-md);

    @include small {
        max-width: 54.5rem;
        margin: 0 auto;
        margin-top: -4.5rem;
        padding: 0 var(--hop-space-inset-md);
    }

    &__back-button {
        @include heading-5;

        display: inline-flex;
        height: auto;
        margin-bottom: var(--hop-space-stack-sm);
        padding: var(--hop-space-inset-xs);

        font-weight: var(--hop-heading-xs-font-weight);

        @include small {
            display: inline-flex;
            padding: 0;
        }
    }

    &__card {
        background-color: $surface;
        border: var(--_border-1px) solid var(--hop-neutral-border-weak);
        border-radius: var(--hop-shape-rounded-md);

        box-shadow: var(--hop-elevation-raised);

        @include small {
            display: flex;
            align-items: stretch;
        }
    }

    &__prompt-section {
        padding: var(--hop-space-inset-md) var(--hop-space-inset-md) 0 var(--hop-space-inset-md);

        @include small {
            width: 45%;
            padding: var(--hop-space-inset-md) 0 var(--hop-space-inset-md) var(--hop-space-inset-md);
        }

        @include medium {
            width: 50%;
        }
    }

    &__image {
        height: 100%;
    }

    &__message-section {
        margin-top: var(--hop-space-stack-xl);

        @include small {
            display: flex;
            flex: 1 1 0%;
            flex-direction: column;
            min-width: 0;
            margin-top: 0;
            padding: var(--hop-space-inset-md) var(--hop-space-inset-md) var(--hop-space-inset-md) var(--hop-space-inset-lg);
        }
    }

    &__form {
        padding: 0 var(--hop-space-inset-md);

        @include small {
            flex: 1 1 auto;
            min-height: 0;
            margin-top: var(--hop-space-stack-md);
            padding: 0;
        }
    }

    &__form-inline-label {
        margin-bottom: var(--hop-space-stack-xs);

        @include heading-5;
        color: var(--hop-status-neutral-text);

        @include small {
            min-width: 3.5rem;
            margin-bottom: var(--hop-space-stack-xs);
        }
    }

    &__form-label {
        margin-bottom: var(--hop-space-stack-xs);

        @include heading-5;
        color: var(--hop-status-neutral-text);
    }

    &__optional-label {
        @include small-text;
        color: $secondary-text;
        font-weight: var(--hop-body-xs-font-weight);
    }

    &__recipient-field {
        flex-direction: column;
        align-items: flex-start;

        .inline-labeled-form-field__content {
            width: 100%;
        }
    }

    &__multiple-recipients-label {
        display: flex;
        gap: var(--hop-space-inline-xs);
        align-items: baseline;
    }

    &__multiple-recipients-inline-label {
        @include heading-5;
    }

    &__recipient-count-label {
        @include small-text;
        color: var(--hop-neutral-text-weak);
    }

    &__multiple-recipients-helper-text-icon {
        margin-right: var(--hop-space-inline-xs);
    }

    &__multiple-recipients-field {
        @include small {
            max-height: 7.5rem;
        }

        .ids-tag--small.ids-tag--select {
            padding-right: var(--hop-space-inset-xs);
            padding-left: var(--hop-space-inset-sm);
        }
    }

    &__sender-field {
        margin-top: var(--hop-space-stack-md);
    }

    &__message-field {
        margin-top: var(--hop-space-stack-lg);
    }

    &__message-input {
        height: auto;
        max-height: none;

        @include small {
            height: 9.75rem;
        }

        .ids-textarea__field {
            @include medium-text;

            height: 100%;
            min-height: 8rem;

            font-weight: var(--hop-body-md-font-weight);
        }
    }

    &__visibility-disclaimer {
        margin-top: var(--hop-space-stack-sm);

        @include small {
            margin-top: var(--hop-space-stack-lg);
        }

        &-multiple-recipients {
            @include caption;
            margin-top: var(--hop-space-stack-lg);

            @include medium {
                margin-top: var(--hop-space-stack-sm);
            }
        }

    }

    &__call-to-actions {
        margin-top: var(--hop-space-stack-xl);
        padding: var(--hop-space-inset-md);
        padding-top: 0;

        @include small {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            margin-top: var(--hop-space-stack-xl);
            padding: 0;
        }

        & > * {
            width: 100%;
            margin-top: var(--hop-space-stack-md);

            font-weight: var(--hop-body-md-font-weight);

            @include small {
                width: auto;
                margin-top: 0;
                margin-right: var(--hop-space-stack-xs);
            }

            &:first-child {
                margin-top: 0;

                @include small {
                    margin-right: 0;
                }
            }

            &:last-child {
                height: auto;
                margin-bottom: calc(-1 * var(--hop-space-stack-md));

                @include small {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__visibility-container {
        margin-top: var(--hop-space-stack-lg);
    }

    &__visibility-field {
        .ids-btn-group__item {
            padding: calc(var(--hop-space-inline-xs) / 2) var(--hop-space-inset-md);

            border-radius: var(--hop-shape-rounded-sm);

            &:not(.is-selected):hover {
                color: var(--hop-neutral-text-hover);

                background: var(--hop-neutral-surface-hover);
            }

            &:not(.is-selected):active {
                color: var(--hop-neutral-text-press);

                background: var(--hop-neutral-surface-press);
            }

            &.is-selected {
                color: var(--hop-neutral-text-selected);

                background: var(--hop-neutral-surface-selected);
            }
        }
    }

    &__visibility-message {
        display: flex;
        gap: var(--hop-space-inline-xs);
        align-items: center;

        margin-top: var(--hop-space-stack-xs);

        color: var(--hop-neutral-text-weak);

        @include caption;
    }

    &__visibility-tooltip {
        color: var(--hop-neutral-icon-weak);

        & > svg {
            width: var(--hop-space-160);
            height: var(--hop-space-160);
        }
    }
}