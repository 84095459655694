@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.custom-poll-follow-up-question {
    max-width: calc(76rem * var(--rem-ratio, 1));
    margin: 0 auto;
    padding: r.replaceBy($space-3, --hop-space-inset-md) r.replaceBy($space-5, --hop-space-inset-md);

    @include small {
        padding: r.replaceBy($space-3, --hop-space-inset-md) r.replaceBy($space-5, --hop-space-inset-xl);
    }

    &__previous-step {
        margin-bottom: r.replaceBy($space-3, --hop-space-stack-lg);

        @include medium-text;

        @include small {
            margin-bottom: r.replaceBy($space-5, --hop-space-stack-xl);
        }
    }

    &__previous-step-text {
        margin-right: r.replaceBy($space-2, --hop-space-inline-sm);
    }

    &__previous-step-button {
        font-weight: r.replaceBy(var(--font-weight-regular), --hop-body-md-medium-font-weight);

        [data-brand="workleap"] & {
            padding: 0;
        }

        &:focus-visible:after {
            [data-brand="workleap"] & {
                border: calc(0.16rem * var(--rem-ratio, 1)) solid var(--hop-primary-border-focus);
                border-radius: var(--hop-shape-rounded-sm);
            }
        }
    }

    &__question-asked-by {
        margin-bottom: r.replaceBy($space-3, --hop-space-stack-lg);

        @include small {
            margin-bottom: r.replaceBy($space-5, --hop-space-stack-lg);
        }
    }
}