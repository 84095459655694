@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.slider-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(5.6rem * var(--rem-ratio, 1));
    height: calc(5.6rem * var(--rem-ratio, 1));
    
    background-color: $surface-slider-handle;

    border: r.replaceBy(0, --_border-2px) solid var(--hop-neutral-border-strong);
    border-radius: 50%;

    &__bar-1,
    &__bar-3 {
        width: calc(0.45rem * var(--rem-ratio, 1));
        height: calc(1.65rem * var(--rem-ratio, 1));

        background-color: $surface-slider-bar;
        border-radius: calc(0.225rem * var(--rem-ratio, 1));
    }

    &__bar-2 {
        width: calc(0.45rem * var(--rem-ratio, 1));
        height: calc(2.25rem * var(--rem-ratio, 1));
        margin: 0 calc(0.75rem * var(--rem-ratio, 1));

        background-color: $surface-slider-bar;
        border-radius: calc(0.225rem * var(--rem-ratio, 1));
    }
}