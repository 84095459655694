@use '@sass/workleap-rebrand/replace-by' as r;
@use "sass:math";
@import "@sass/utils/all";

$slider-height: calc(2rem * var(--rem-ratio, 1));
$thumb-size: calc(5.6rem * var(--rem-ratio, 1));
$slider-border-radius: calc(math.div($slider-height, 2));

.slider-question {
    &--interacted {
        .slider-question__mobile-scroll-label,
        .slider-question__drag-label {
            visibility: hidden;
        }
    }

    &--confirmed {
        .slider-question__mobile-slider {
            overflow-x: hidden;
        }

        .slider-question__mobile-confirmed-label {
            @keyframes slider-confirmed-label-animation {
                0% { color: r.replaceBy(var(--coral-950), --hop-neutral-text); }
                100% { color: r.replaceBy(var(--coral-500), --hop-neutral-text-weak); }
            }

            animation: slider-confirmed-label-animation linear 80ms 6 alternate both;
        }
    }

    @for $i from 0 through 10 {
        &--#{$i} {
            .slider-question__left-cap,
            .rc-slider-track {
                background-color: map-get($surface-slider-score, $i);
            }

            .rc-slider-mark-text:nth-child(#{$i + 1}) {
                @include heading-1;
                color: r.replaceBy(map-get($surface-slider-score, $i), --hop-neutral-text);
                font-weight: var(--font-weight-semibold);
            }

            .slider-question__mobile-choice:nth-child(#{$i + 1}) .slider-question__mobile-number {
                color: r.replaceBy(map-get($surface-slider-score, $i), --hop-neutral-text);
                font-weight: var(--font-weight-semibold);
                font-size: calc(4.8rem * var(--rem-ratio, 1));
            }
        }
    }

    &__mobile-slider {
        display: flex;
        margin: calc(-3.2rem * var(--rem-ratio, 1)) calc(-3.2rem * var(--rem-ratio, 1)) 0 calc(-3.2rem * var(--rem-ratio, 1));
        overflow-x: auto;

        @include small {
            display: none;
        }
    }

    &__mobile-choices-container {
        display: flex;
        padding: 0 calc((100vw - 20vw) / 2);
        padding-bottom: calc(0.8rem * var(--rem-ratio, 1));
    }

    &__mobile-choice {
        position: relative;

        flex-shrink: 0;
        width: 20vw;
        padding: calc(3.2rem * var(--rem-ratio, 1)) 0 calc(3.2rem * var(--rem-ratio, 1)) 0;

        scroll-snap-align: start;
    }

    &__mobile-radio-input {
        position: absolute;

        width: 0;
        height: 0;

        opacity: 0;

        pointer-events: none;

        &:focus-visible + .slider-question__mobile-number {
            position: relative;

            &:after {
                position: absolute;
                top: calc(-0.4rem * var(--rem-ratio, 1));
                right: calc(0.4rem * var(--rem-ratio, 1));
                bottom: calc(-0.4rem * var(--rem-ratio, 1));
                left: calc(0.4rem * var(--rem-ratio, 1));

                display: block;

                border: r.replaceBy(calc(0.1rem * var(--rem-ratio, 1)), --_border-2px) solid $focus-outline;
                border-radius: calc(0.4rem * var(--rem-ratio, 1));

                content: '';
            }
        }
    }

    &__mobile-number {
        display: block;

        @include heading-1;
        color: r.replaceBy(var(--creamsicle-200), --hop-neutral-text-weakest);
        font-weight: var(--font-weight-bold);
        line-height: calc(5.7rem * var(--rem-ratio, 1));
        text-align: center;

        [data-brand="workleap"] & {
            @include accent-lg;
        }
    }

    &__mobile-min-label {
        position: absolute;
        bottom: 0;
        left: 0;

        padding-left: calc(0.8rem * var(--rem-ratio, 1));

        white-space: nowrap;

        text-align: center;
    }

    &__mobile-max-label {
        position: absolute;
        right: 0;
        bottom: 0;

        padding-right: calc(0.8rem * var(--rem-ratio, 1));

        white-space: nowrap;

        text-align: center;
    }

    &__mobile-click-to-confirm-label {
        position: absolute;
        top: 0;
        left: 50%;

        @include medium-text;
        white-space: nowrap;

        transform: translate(-50%, 0);
    }

    &__mobile-confirmed-label {
        position: absolute;
        top: 0;
        left: 50%;

        @include medium-text;
        white-space: nowrap;

        transform: translate(-50%, 0);
    }

    &__mobile-scroll-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: calc(0.8rem * var(--rem-ratio, 1));

        @keyframes slider-scroll-label-animation-mobile {
            0%, 70%, 100% { transform: translateX(0); }
            80%, 90% { transform: translateX(calc(-1.6rem * var(--rem-ratio, 1))); }
            85%, 95% { transform: translateX(calc(1.6rem * var(--rem-ratio, 1))); }
        }

        animation: slider-scroll-label-animation-mobile 4000ms infinite;

        @include small {
            display: none;
        }
    }

    &__mobile-scroll-instructions-section {
        display: flex;
        flex-direction: column;
        gap: calc(4rem * var(--rem-ratio, 1));
        align-items: center;

        @include small {
            display: none;
        }
    }

    &__mobile-scroll-instructions {
        display: flex;
        align-items: center;

        @keyframes slider-scroll-label-animation-mobile {
            0%, 70%, 100% { transform: translateX(0); }
            80%, 90% { transform: translateX(calc(-1.6rem * var(--rem-ratio, 1))); }
            85%, 95% { transform: translateX(calc(1.6rem * var(--rem-ratio, 1))); }
        }

        animation: slider-scroll-label-animation-mobile 4000ms infinite;
    }

    &__drag-label {
        display: none;

        @include small {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: calc(0.8rem * var(--rem-ratio, 1));

            @keyframes slider-drag-label-animation {
                0%, 70%, 100% { transform: translateX(0); }
                80%, 90% { transform: translateX(calc(0rem * var(--rem-ratio, 1))); }
                85%, 95% { transform: translateX(calc(1.6rem * var(--rem-ratio, 1))); }
            }

            animation: slider-drag-label-animation 4000ms infinite;
        }
    }

    &__drag-arrow-left {
        html:not([data-brand="workleap"]) & {
            width: calc(1.6rem * var(--rem-ratio, 1));
            height: calc(0.8rem * var(--rem-ratio, 1));
        }

        margin-right: calc(0.8rem * var(--rem-ratio, 1));
    }

    &__drag-arrow-right {
        html:not([data-brand="workleap"]) & {
            width: calc(1.6rem * var(--rem-ratio, 1));
            height: calc(0.8rem * var(--rem-ratio, 1));
        }

        margin-left: r.replaceBy($space-2, --hop-space-inline-xs);
    }

    &__slider {
        display: none;

        @include small {
            display: flex;
            align-items: flex-start;
            padding: calc(math.div($thumb-size, 2) - math.div($slider-height, 2)) 0 calc(7.2rem * var(--rem-ratio, 1)) 0;
        }
    }

    &__left-cap {
        flex-shrink: 0;
        width: calc(math.div($thumb-size, 2));
        height: $slider-height;

        background-color: $surface-slider-track;
        border: r.replaceBy(0, --_border-2px) solid var(--hop-neutral-border-strong);
        border-right: none;
        border-radius: $slider-border-radius 0 0 $slider-border-radius;
    }

    &__track {
        flex: 1 0 0%;
    }

    &__right-cap {
        flex-shrink: 0;
        width: calc(math.div($thumb-size, 2));
        height: $slider-height;

        background-color: $surface-slider-rail;
        border: r.replaceBy(0, --_border-2px) solid var(--hop-neutral-border-strong);
        border-left: none;
        border-radius: 0 $slider-border-radius $slider-border-radius 0;
    }

    &__labels {
        display: none;

        @include small {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: calc(1.6rem * var(--rem-ratio, 1));

            [data-brand="workleap"] & {
                @include heading-5;
                font-weight: var(--hop-heading-xs-font-weight);
            }
        }
    }

    &__min-label {
        width: 45%;

        text-align: left;
    }

    &__max-label {
        width: 45%;

        text-align: right;
    }

    &__image {
        display: block;

        width: 100%;
        max-width: calc(35rem * var(--rem-ratio, 1));

        margin: 0 auto;

        @include small {
            margin-top: calc(3.2rem * var(--rem-ratio, 1));
        }
    }

    &__handle-wrapper {
        &--slider {
            &-dragging, &:hover {
                .slider-handle {
                    [data-brand="workleap"] & {
                        @include hover-drop-shadow-stylised;

                        transform: translate(calc(0.5rem * var(--rem-ratio, 1)), calc(-0.5rem * var(--rem-ratio, 1)));
                    }
                }
            }
        }
    }

    .rc-slider {
        position: relative;

        height: $slider-height;

        &-rail {
            position: absolute;

            width: 100%;

            height: $slider-height;

            background-color: $surface-slider-rail;
            border: r.replaceBy(0, --_border-2px) solid var(--hop-neutral-border-strong);
            border-right: none;
        }

        &-track {
            position: absolute;

            height: $slider-height;

            border: r.replaceBy(0, --_border-2px) solid var(--hop-neutral-border-strong);
            border-left: none;
        }

        &-handle {
            position: absolute;

            margin-top: calc(0px - math.div($thumb-size, 2) - math.div($slider-height, 2));

            border-radius: 50%;

            transform: translateX(-50%);

            cursor: grab;

            &:focus-visible {
                outline: none;
            }
        }

        &-step {
            position: relative;

            height: $slider-height;
        }

        &-dot {
            position: absolute;
            top: 100%;

            width: calc(0.1rem * var(--rem-ratio, 1));
            height: calc(3.2rem * var(--rem-ratio, 1));

            background-color: $surface-slider-step;
        }

        &-mark {
            position: absolute;
            right: 0;
            left: 0;

            margin-top: calc(3.2rem * var(--rem-ratio, 1));
            padding-top: calc(1.6rem * var(--rem-ratio, 1));
        }

        &-mark-text {
            position: absolute;

            transition: font-size linear 100ms,
                line-height linear 100ms,
                color linear 100ms;
        }
    }
}