@import "@sass/utils/all";

.onboarding-survey-likert-question {
    &--animated-intro {
        .base-onboarding-survey-question__header {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), likert-question-slide-in-animation);

            animation: fade-in linear 500ms both,
                likert-question-slide-in-animation ease-out 500ms both;
        }

        .onboarding-survey-likert-question__content {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), likert-content-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                likert-content-slide-in-animation ease-out 500ms $delay both;
        }
    }
}