@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.secondary-button {
    position: relative;

    display: inline-flex;
    align-items: center;

    height: calc(4rem * var(--rem-ratio, 1));
    padding: 0 calc(2.4rem * var(--rem-ratio, 1));

    @include heading-5;
    color: $secondary-button-text;
    line-height: calc(4rem * var(--rem-ratio, 1));
    white-space: nowrap;

    background-color: $surface-secondary-button;
    border: calc(0.1rem * var(--rem-ratio, 1)) solid currentColor;
    border-radius: calc(2rem * var(--rem-ratio, 1));

    cursor: pointer;

    user-select: none;

    [data-brand="workleap"] & {
        height: calc(3.4rem * var(--rem-ratio, 1));
        padding: calc(0.6rem * var(--rem-ratio, 1)) var(--hop-space-inset-md);

        @include small-text;
        font-weight: var(--hop-body-md-medium-font-weight);

        border-radius: var(--hop-shape-rounded-md);
    }

    &:hover {
        @include media-hover {
            color: $secondary-button-text-hovered;

            [data-brand="workleap"] & {
                background-color: var(--hop-neutral-surface-hover);
                border-color: var(--hop-neutral-border-strong-hover);
            }
        }
    }

    &:focus-visible {
        position: relative;

        outline: none;

        &:after {
            position: absolute;
            top: calc(-0.4rem * var(--rem-ratio, 1));
            right: calc(-0.4rem * var(--rem-ratio, 1));
            bottom: calc(-0.4rem * var(--rem-ratio, 1));
            left: calc(-0.4rem * var(--rem-ratio, 1));

            display: block;

            border: calc(0.1rem * var(--rem-ratio, 1)) solid $focus-outline;
            border-radius: r.replaceBy(calc(2.6rem * var(--rem-ratio, 1)), --hop-shape-rounded-lg);

            content: '';
        }
    }

    &--disabled {
        color: $disabled-text;

        background-color: $surface-secondary-button-disabled;
        border-color: $surface-secondary-button-disabled;

        cursor: not-allowed;

        &:hover {
            @include media-hover {
                color: $disabled-text;

                [data-brand="workleap"] & {
                    border: none;
                }
            }
        }
    }

    &--mobile-icon-only {
        .secondary-button__text {
            display: none;

            @include small {
                display: block;
            }
        }

        .secondary-button__icon {
            margin: 0;
        }
    }

    &--loading {
        pointer-events: none;

        .secondary-button__text,
        .secondary-button__icon {
            visibility: hidden;
        }
    }

    &__text {
        flex: 1 0 auto;
        min-width: 0;

        white-space: nowrap;
        text-align: center;
    }

    &__icon {
        flex-shrink: 0;

        &--leading {
            margin-right: calc(0.8rem * var(--rem-ratio, 1));
        }

        &--trailing {
            margin-left: calc(0.8rem * var(--rem-ratio, 1));
        }
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        &:after {
            display: block;
            width: calc(1.2rem * var(--rem-ratio, 1));
            height: calc(1.2rem * var(--rem-ratio, 1));

            border: calc(0.2rem * var(--rem-ratio, 1)) solid transparent;
            border-left-color: currentColor;
            border-radius: 50%;

            animation: spin 750ms infinite linear;

            content: " ";
        }
    }
}