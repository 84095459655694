@mixin ie {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin media-hover {
    @media (hover: hover) {
        @content;
    }

    @include ie {
        @content;
    }
}