@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.question-asked-by {
    display: flex;

    color: r.replaceBy($primary-text, --hop-decorative-option3-text);

    @include heading-4;

    &__icon {
        display: flex;
        flex-shrink: 0;
        align-items: flex-start;
        justify-content: center;
        width: calc(4rem * var(--rem-ratio, 1));
        height: calc(4rem * var(--rem-ratio, 1));
        margin-right: r.replaceBy($space-2, --hop-space-inline-sm);

        border-radius: r.replaceBy($border-radius-none, --hop-shape-circle);

        [data-brand="workleap"] & {
            width: calc(3.2rem * var(--rem-ratio, 1));
            height: calc(3.2rem * var(--rem-ratio, 1));

            overflow: hidden;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__question {
        padding: r.replaceBy($space-3, --hop-space-inset-md);

        background-color: r.replaceBy(var(--creamsicle-100), --hop-decorative-option3-surface-weak);
        border-radius: r.replaceBy(calc(0.2rem * var(--rem-ratio, 1)), --hop-shape-rounded-sm) r.replaceBy(calc(1.6rem * var(--rem-ratio, 1)), --hop-shape-rounded-lg) r.replaceBy(calc(1.6rem * var(--rem-ratio, 1)), --hop-shape-rounded-lg) r.replaceBy(calc(1.6rem * var(--rem-ratio, 1)), --hop-shape-rounded-lg);
    }

    [data-brand="workleap"] & {
        @include large-text;

        font-weight: r.replaceBy(var(--font-weight-regular), --hop-body-lg-font-weight);
        line-height: r.replaceBy(var(--heading-2xl-line-height), --hop-body-lg-line-height);
    }
}