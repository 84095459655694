@import "@sass/utils/all";

.pulse-survey-slider-question {
    @include small {
        max-width: calc(76rem * var(--rem-ratio, 1));
        margin: 0 auto;
    }

    &--animated-intro {
        .base-pulse-survey-question__header {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), two-way-slider-question-slide-in-animation);

            animation: fade-in linear 500ms both,
                two-way-slider-question-slide-in-animation ease-out 500ms both;
        }

        .base-pulse-survey-question__input {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), two-way-slider-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                two-way-slider-slide-in-animation ease-out 500ms $delay both;
        }
    }
}