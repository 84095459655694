// This is temporary and only needed during the rebranding process.
// Will be removed at the end of the rebrand.
:root {
    --area-chart-line-color: #1053FF;
    --area-chart-bellow-line: url(#curveAreaFillGradient);
}

[data-brand="workleap"] {
    // Charts
    --area-chart-line-color: url("#areaChartLineGradient"); // Defined in chartsConfig.jsx
    --area-chart-bellow-line: none;

    // Upsell
    --upsell-border-active: var(--hop-upsell-border-active);
    --upsell-text-active: var(--hop-upsell-text-active);
    --upsell-icon-weakest: var(--hop-upsell-icon-weakest);
    --upsell-icon-weak: var(--hop-upsell-icon-weak);
    --upsell-border: var(--hop-upsell-border);
    --upsell-surface-hover: var(--hop-upsell-surface-hover);
    --upsell-border-disabled: var(--hop-upsell-border-disabled);
    --upsell-icon-active: var(--hop-upsell-icon-active);
    --upsell-surface-weak: var(--hop-upsell-surface-weak);
    --upsell-surface-disabled: var(--hop-upsell-surface-disabled);
    --upsell-text: var(--hop-upsell-text);
    --upsell-icon: var(--hop-upsell-icon);
    --upsell-text-hover: var(--hop-upsell-text-hover);
    --upsell-text-disabled: var(--hop-upsell-text-disabled);
    --upsell-surface-active: var(--hop-upsell-surface-active);
    --upsell-surface: var(--hop-upsell-surface);

    // Danger
    --danger-border-active: var(--hop-danger-border-active);
    --danger-icon-active: var(--hop-danger-icon-active);
    --danger-text-active: var(--hop-danger-text-active);
    --danger-surface: var(--hop-danger-surface);
    --danger-text-hover: var(--hop-danger-text-hover);
    --danger-surface-disabled: var(--hop-danger-surface-disabled);
    --danger-surface-hover: var(--hop-danger-surface-hover);
    --danger-surface-strong: var(--hop-danger-surface-strong);
    --danger-surface-strong-hover: var(--hop-danger-surface-strong-hover);
    --danger-surface-weak: var(--hop-danger-surface-weak);
    --danger-border-strong: var(--hop-danger-border-strong);
    --danger-icon: var(--hop-danger-icon);
    --danger-icon-weak: var(--hop-danger-icon-weak);
    --danger-text: var(--hop-danger-text);
    --danger-surface-active: var(--hop-danger-surface-active);
    --danger-text-weak: var(--hop-danger-text-weak);
    --danger-text-strong: var(--hop-danger-text-strong);
    --danger-text-strong-hover: var(--hop-danger-text-strong-hover);
    --danger-icon-strong: var(--hop-danger-icon-strong);
    --danger-icon-strong-hover: var(--hop-danger-icon-strong-hover);
    --danger-icon-hover: var(--hop-danger-icon-hover);
    --danger-text-disabled: var(--hop-danger-text-disabled);
    --danger-icon-disabled: var(--hop-danger-icon-disabled);
    --danger-border: var(--hop-danger-border);

    // Primary
    --primary-icon-active: var(--hop-primary-icon-active);
    --primary-border-active: var(--hop-primary-border-active);
    --primary-text-active: var(--hop-primary-text-active);
    --primary-icon-strong: var(--hop-primary-icon-strong);
    --primary-icon-strong-hover: var(--hop-primary-icon-strong-hover);
    --primary-icon: var(--hop-primary-icon);
    --primary-border-focus: var(--hop-primary-border-focus);
    --primary-border: var(--hop-primary-border);
    --primary-icon-hover: var(--hop-primary-icon-hover);
    --primary-icon-disabled: var(--hop-primary-icon-disabled);
    --primary-surface-disabled: var(--hop-primary-surface-disabled);
    --primary-surface-focus: var(--hop-primary-surface-focus);
    --primary-text-disabled: var(--hop-primary-text-disabled);
    --primary-surface: var(--hop-primary-surface);
    --primary-surface-weak: var(--hop-primary-surface-weak);
    --primary-surface-strong: var(--hop-primary-surface-strong);
    --primary-surface-strong-active: var(--hop-primary-surface-strong-active);
    --primary-surface-strong-hover: var(--hop-primary-surface-strong-hover);
    --primary-text: var(--hop-primary-text);
    --primary-surface-hover: var(--hop-primary-surface-hover);
    --primary-text-strong: var(--hop-primary-text-strong);
    --primary-text-strong-hover: var(--hop-primary-text-strong-hover);
    --primary-text-hover: var(--hop-primary-text-hover);
    --primary-surface-active: var(--hop-primary-surface-active);

    // Decorative
    --decorative-option9-icon: var(--hop-decorative-option9-icon);
    --decorative-option9-surface: var(--hop-decorative-option9-surface);
    --decorative-option9-surface-strong: var(--hop-decorative-option9-surface-strong);
    --decorative-option9-surface-hover: var(--hop-decorative-option9-surface-hover);
    --decorative-option9-surface-weak: var(--hop-decorative-option9-surface-weak);
    --decorative-option9-surface-weakest: var(--hop-decorative-option9-surface-weakest);
    --decorative-option9-text: var(--hop-decorative-option9-text);
    --decorative-option9-text-weak: var(--hop-decorative-option9-text-weak);
    --decorative-option9-border: var(--hop-decorative-option9-border);
    --decorative-option8-icon: var(--hop-decorative-option8-icon);
    --decorative-option8-surface-weakest: var(--hop-decorative-option8-surface-weakest);
    --decorative-option8-surface-weak: var(--hop-decorative-option8-surface-weak);
    --decorative-option8-surface-hover: var(--hop-decorative-option8-surface-hover);
    --decorative-option8-surface-strong: var(--hop-decorative-option8-surface-strong);
    --decorative-option8-text-weak: var(--hop-decorative-option8-text-weak);
    --decorative-option8-surface: var(--hop-decorative-option8-surface);
    --decorative-option8-text: var(--hop-decorative-option8-text);
    --decorative-option8-border: var(--hop-decorative-option8-border);
    --decorative-option7-icon: var(--hop-decorative-option7-icon);
    --decorative-option7-surface-weakest: var(--hop-decorative-option7-surface-weakest);
    --decorative-option7-surface-weak: var(--hop-decorative-option7-surface-weak);
    --decorative-option7-surface-strong: var(--hop-decorative-option7-surface-strong);
    --decorative-option7-surface-hover: var(--hop-decorative-option7-surface-hover);
    --decorative-option7-surface: var(--hop-decorative-option7-surface);
    --decorative-option7-text-weak: var(--hop-decorative-option7-text-weak);
    --decorative-option7-text: var(--hop-decorative-option7-text);
    --decorative-option7-border: var(--hop-decorative-option7-border);
    --decorative-option5-text-weak: var(--hop-decorative-option5-text-weak);
    --decorative-option5-text: var(--hop-decorative-option5-text);
    --decorative-option5-surface-weakest: var(--hop-decorative-option5-surface-weakest);
    --decorative-option5-surface-weak: var(--hop-decorative-option5-surface-weak);
    --decorative-option5-surface-strong: var(--hop-decorative-option5-surface-strong);
    --decorative-option5-surface-hover: var(--hop-decorative-option5-surface-hover);
    --decorative-option5-surface: var(--hop-decorative-option5-surface);
    --decorative-option5-icon: var(--hop-decorative-option5-icon);
    --decorative-option5-border: var(--hop-decorative-option5-border);
    --decorative-option6-surface-weakest: var(--hop-decorative-option6-surface-weakest);
    --decorative-option6-surface-weak: var(--hop-decorative-option6-surface-weak);
    --decorative-option6-surface-hover: var(--hop-decorative-option6-surface-hover);
    --decorative-option6-surface-strong: var(--hop-decorative-option6-surface-strong);
    --decorative-option6-surface: var(--hop-decorative-option6-surface);
    --decorative-option6-icon: var(--hop-decorative-option6-icon);
    --decorative-option6-text: var(--hop-decorative-option6-text);
    --decorative-option6-text-weak: var(--hop-decorative-option6-text-weak);
    --decorative-option6-border: var(--hop-decorative-option6-border);
    --decorative-option4-surface-weakest: var(--hop-decorative-option4-surface-weakest);
    --decorative-option4-surface-weak: var(--hop-decorative-option4-surface-weak);
    --decorative-option4-surface-strong: var(--hop-decorative-option4-surface-strong);
    --decorative-option4-surface-hover: var(--hop-decorative-option4-surface-hover);
    --decorative-option4-surface: var(--hop-decorative-option4-surface);
    --decorative-option4-icon: var(--hop-decorative-option4-icon);
    --decorative-option4-text-weak: var(--hop-decorative-option4-text-weak);
    --decorative-option4-text: var(--hop-decorative-option4-text);
    --decorative-option4-border: var(--hop-decorative-option4-border);
    --decorative-option3-surface-weak: var(--hop-decorative-option3-surface-weak);
    --decorative-option3-surface-weakest: var(--hop-decorative-option3-surface-weakest);
    --decorative-option3-surface-strong: var(--hop-decorative-option3-surface-strong);
    --decorative-option3-surface-hover: var(--hop-decorative-option3-surface-hover);
    --decorative-option3-surface: var(--hop-decorative-option3-surface);
    --decorative-option3-icon: var(--hop-decorative-option3-icon);
    --decorative-option3-text: var(--hop-decorative-option3-text);
    --decorative-option3-text-weak: var(--hop-decorative-option3-text-weak);
    --decorative-option3-border: var(--hop-decorative-option3-border);
    --decorative-option2-surface-weakest: var(--hop-decorative-option2-surface-weakest);
    --decorative-option2-surface-weak: var(--hop-decorative-option2-surface-weak);
    --decorative-option2-surface-strong: var(--hop-decorative-option2-surface-strong);
    --decorative-option2-surface: var(--hop-decorative-option2-surface);
    --decorative-option2-surface-hover: var(--hop-decorative-option2-surface-hover);
    --decorative-option2-text: var(--hop-decorative-option2-text);
    --decorative-option2-text-weak: var(--hop-decorative-option2-text-weak);
    --decorative-option2-icon: var(--hop-decorative-option2-icon);
    --decorative-option2-border: var(--hop-decorative-option2-border);
    --decorative-option1-surface-weak: var(--hop-decorative-option1-surface-weak);
    --decorative-option1-surface-weakest: var(--hop-decorative-option1-surface-weakest);
    --decorative-option1-surface-strong: var(--hop-decorative-option1-surface-strong);
    --decorative-option1-surface-hover: var(--hop-decorative-option1-surface-hover);
    --decorative-option1-surface: var(--hop-decorative-option1-surface);
    --decorative-option1-border: var(--hop-decorative-option1-border);
    --decorative-option1-text-weak: var(--hop-decorative-option1-text-weak);
    --decorative-option1-icon: var(--hop-decorative-option1-icon);
    --decorative-option1-text: var(--hop-decorative-option1-text);

    // Neutral
    --neutral-text-hover: var(--hop-neutral-text-hover);
    --neutral-surface-weakest-active: var(--hop-neutral-surface-weakest-active);
    --neutral-surface-weakest-hover: var(--hop-neutral-surface-weakest-hover);
    --neutral-text-weak: var(--hop-neutral-text-weak);
    --neutral-surface-disabled: var(--hop-neutral-surface-disabled);
    --neutral-surface-active: var(--hop-neutral-surface-active);
    --neutral-surface-weak: var(--hop-neutral-surface-weak);
    --neutral-surface: var(--hop-neutral-surface);
    --neutral-text-disabled: var(--hop-neutral-text-disabled);
    --neutral-text-active: var(--hop-neutral-text-active);
    --neutral-text-strong: var(--hop-neutral-text-strong);
    --neutral-text: var(--hop-neutral-text);
    --neutral-text-weakest: var(--hop-neutral-text-weakest);
    --neutral-border-disabled: var(--hop-neutral-border-disabled);
    --neutral-border-strong: var(--hop-neutral-border-strong);
    --neutral-surface-strong: var(--hop-neutral-surface-strong);
    --neutral-surface-hover: var(--hop-neutral-surface-hover);
    --neutral-icon-disabled: var(--hop-neutral-icon-disabled);
    --neutral-icon: var(--hop-neutral-icon);
    --neutral-icon-weak: var(--hop-neutral-icon-weak);
    --neutral-icon-weak-hover: var(--hop-neutral-icon-weak-hover);
    --neutral-border: var(--hop-neutral-border);
    --neutral-border-weak: var(--hop-neutral-border-weak);
    --neutral-icon-weakest: var(--hop-neutral-icon-weakest);
    --neutral-icon-strong: var(--hop-neutral-icon-strong);
    --neutral-icon-hover: var(--hop-neutral-icon-hover);
    --neutral-icon-active: var(--hop-neutral-icon-active);
    --neutral-border-hover: var(--hop-neutral-border-hover);
    --neutral-border-active: var(--hop-neutral-border-active);
    --neutral-surface-weak-hover: var(--hop-neutral-surface-weak-hover);
    --neutral-border-weakest: var(--hop-neutral-border-weakest);
    --neutral-surface-weakest: var(--hop-neutral-surface-weakest);

    // Warning
    --warning-icon-weakest: var(--hop-warning-icon-weakest);
    --warning-icon-weak: var(--hop-warning-icon-weak);
    --warning-text-weak: var(--hop-warning-text-weak);
    --warning-surface: var(--hop-warning-surface);
    --warning-surface-strong: var(--hop-warning-surface-strong);
    --warning-surface-weak: var(--hop-warning-surface-weak);
    --warning-border: var(--hop-warning-border);
    --warning-icon: var(--hop-warning-icon);
    --warning-text: var(--hop-warning-text);

    // Success
    --success-border: var(--hop-success-border);
    --success-icon-weak: var(--hop-success-icon-weak);
    --success-icon-weakest: var(--hop-success-icon-weakest);
    --success-text-weak: var(--hop-success-text-weak);
    --success-surface: var(--hop-success-surface);
    --success-surface-strong: var(--hop-success-surface-strong);
    --success-text-hover: var(--hop-success-text-hover);
    --success-text: var(--hop-success-text);
    --success-icon: var(--hop-success-icon);
    --success-surface-weak: var(--hop-success-surface-weak);

    // Information
    --information-icon-weakest: var(--hop-information-icon-weakest);
    --information-surface-strong: var(--hop-information-surface-strong);
    --information-icon-weak: var(--hop-information-icon-weak);
    --information-text: var(--hop-information-text);
    --information-surface: var(--hop-information-surface);
    --information-surface-weak: var(--hop-information-surface-weak);
    --information-text-weak: var(--hop-information-text-weak);
    --information-icon: var(--hop-information-icon);
    --information-border: var(--hop-information-border);

    // Status
    --status-neutral-surface-strong: var(--hop-status-neutral-surface-strong);
    --status-neutral-text: var(--hop-status-neutral-text);
    --status-neutral-icon: var(--hop-status-neutral-icon);
    --status-neutral-surface: var(--hop-status-neutral-surface);
    --status-progress-surface-strong: var(--hop-status-progress-surface-strong);
    --status-progress-icon: var(--hop-status-progress-icon);
    --status-progress-surface: var(--hop-status-progress-surface);
    --status-progress-text: var(--hop-status-progress-text);
    --status-option6-surface-strong: var(--hop-status-option6-surface-strong);
    --status-option6-icon: var(--hop-status-option6-icon);
    --status-option6-surface: var(--hop-status-option6-surface);
    --status-option6-text: var(--hop-status-option6-text);
    --status-option5-surface-strong: var(--hop-status-option5-surface-strong);
    --status-option5-icon: var(--hop-status-option5-icon);
    --status-option5-surface: var(--hop-status-option5-surface);
    --status-option5-text: var(--hop-status-option5-text);
    --status-option1-surface-strong: var(--hop-status-option1-surface-strong);
    --status-option1-icon: var(--hop-status-option1-icon);
    --status-option1-surface: var(--hop-status-option1-surface);
    --status-option1-text: var(--hop-status-option1-text);
    --status-option2-surface-strong: var(--hop-status-option2-surface-strong);
    --status-option2-text: var(--hop-status-option2-text);
    --status-option2-surface: var(--hop-status-option2-surface);
    --status-option2-icon: var(--hop-status-option2-icon);
    --status-option3-surface-strong: var(--hop-status-option3-surface-strong);
    --status-option3-icon: var(--hop-status-option3-icon);
    --status-option3-surface: var(--hop-status-option3-surface);
    --status-option3-text: var(--hop-status-option3-text);
    --status-option4-surface-strong: var(--hop-status-option4-surface-strong);
    --status-option4-icon: var(--hop-status-option4-icon);
    --status-option4-surface: var(--hop-status-option4-surface);
    --status-option4-text: var(--hop-status-option4-text);
    --status-caution-surface-strong: var(--hop-status-caution-surface-strong);
    --status-caution-icon: var(--hop-status-caution-icon);
    --status-caution-surface: var(--hop-status-caution-surface);
    --status-caution-text: var(--hop-status-caution-text);
    --status-negative-surface-strong: var(--hop-status-negative-surface-strong);
    --status-negative-icon: var(--hop-status-negative-icon);
    --status-negative-surface: var(--hop-status-negative-surface);
    --status-negative-text: var(--hop-status-negative-text);
    --status-inactive-surface-strong: var(--hop-status-inactive-surface-strong);
    --status-inactive-icon: var(--hop-status-inactive-icon);
    --status-inactive-surface: var(--hop-status-inactive-surface);
    --status-inactive-text: var(--hop-status-inactive-text);
    --status-positive-icon: var(--hop-status-positive-icon);
    --status-positive-surface-strong: var(--hop-status-positive-surface-strong);
    --status-positive-surface: var(--hop-status-positive-surface);
    --status-positive-text: var(--hop-status-positive-text);

    // Elevation
    --elevation-none: var(--hop-elevation-none);
    --elevation-raised: var(--hop-elevation-raised);
    --elevation-lifted: var(--hop-elevation-lifted);
    --elevation-floating: var(--hop-elevation-floating);

    // Heading
    --heading-3xl-font-family: var(--hop-heading-3xl-font-family);
    --heading-3xl-font-size: var(--hop-heading-3xl-font-size);
    --heading-3xl-font-weight: var(--hop-heading-3xl-font-weight);
    --heading-3xl-line-height: var(--hop-heading-3xl-line-height);
    --heading-2xl-font-family: var(--hop-heading-2xl-font-family);
    --heading-2xl-font-size: var(--hop-heading-2xl-font-size);
    --heading-2xl-font-weight: var(--hop-heading-2xl-font-weight);
    --heading-2xl-line-height: var(--hop-heading-2xl-line-height);
    --heading-xl-font-family: var(--hop-heading-xl-font-family);
    --heading-xl-font-size: var(--hop-heading-xl-font-size);
    --heading-xl-font-weight: var(--hop-heading-xl-font-weight);
    --heading-xl-line-height: var(--hop-heading-xl-line-height);
    --heading-lg-font-family: var(--hop-heading-lg-font-family);
    --heading-lg-font-size: var(--hop-heading-lg-font-size);
    --heading-lg-font-weight: var(--hop-heading-lg-font-weight);
    --heading-lg-line-height: var(--hop-heading-lg-line-height);
    --heading-md-font-family: var(--hop-heading-md-font-family);
    --heading-md-font-size: var(--hop-heading-md-font-size);
    --heading-md-font-weight: var(--hop-heading-md-font-weight);
    --heading-md-line-height: var(--hop-heading-md-line-height);
    --heading-sm-font-family: var(--hop-heading-sm-font-family);
    --heading-sm-font-size: var(--hop-heading-sm-font-size);
    --heading-sm-font-weight: var(--hop-heading-sm-font-weight);
    --heading-sm-line-height: var(--hop-heading-sm-line-height);
    --heading-xs-font-family: var(--hop-heading-xs-font-family);
    --heading-xs-font-size: var(--hop-heading-xs-font-size);
    --heading-xs-font-weight: var(--hop-heading-xs-font-weight);
    --heading-xs-line-height: var(--hop-heading-xs-line-height);
    --heading-xs-medium-font-family: var(--hop-heading-xs-medium-font-family);
    --heading-xs-medium-font-size: var(--hop-heading-xs-medium-font-size);
    --heading-xs-medium-font-weight: var(--hop-heading-xs-medium-font-weight);
    --heading-xs-medium-line-height: var(--hop-heading-xs-medium-line-height);

    // Overline
    --overline-font-family: var(--hop-overline-font-family);
    --overline-font-size: var(--hop-overline-font-size);
    --overline-font-weight: var(--hop-overline-font-weight);
    --overline-line-height: var(--hop-overline-line-height);

    //Body
    --body-2xl-font-family: var(--hop-body-2xl-font-family);
    --body-2xl-font-size: var(--hop-body-2xl-font-size);
    --body-2xl-font-weight: var(--hop-body-2xl-font-weight);
    --body-2xl-line-height: var(--hop-body-2xl-line-height);
    --body-xl-font-family: var(--hop-body-xl-font-family);
    --body-xl-font-size: var(--hop-body-xl-font-size);
    --body-xl-font-weight: var(--hop-body-xl-font-weight);
    --body-xl-line-height: var(--hop-body-xl-line-height);
    --body-lg-font-family: var(--hop-body-lg-font-family);
    --body-lg-font-size: var(--hop-body-lg-font-size);
    --body-lg-font-weight: var(--hop-body-lg-font-weight);
    --body-lg-line-height: var(--hop-body-lg-line-height);
    --body-lg-medium-font-family: var(--hop-body-lg-medium-font-family);
    --body-lg-medium-font-size: var(--hop-body-lg-medium-font-size);
    --body-lg-medium-font-weight: var(--hop-body-lg-medium-font-weight);
    --body-lg-medium-line-height: var(--hop-body-lg-medium-line-height);
    --body-lg-semibold-font-family: var(--hop-body-lg-semibold-font-family);
    --body-lg-semibold-font-size: var(--hop-body-lg-semibold-font-size);
    --body-lg-semibold-font-weight: var(--hop-body-lg-semibold-font-weight);
    --body-lg-semibold-line-height: var(--hop-body-lg-semibold-line-height);
    --body-lg-bold-font-family: var(--hop-body-lg-bold-font-family);
    --body-lg-bold-font-size: var(--hop-body-lg-bold-font-size);
    --body-lg-bold-font-weight: var(--hop-body-lg-bold-font-weight);
    --body-lg-bold-line-height: var(--hop-body-lg-bold-line-height);
    --body-lg-underline-font-family: var(--hop-body-lg-underline-font-family);
    --body-lg-underline-font-size: var(--hop-body-lg-underline-font-size);
    --body-lg-underline-font-weight: var(--hop-body-lg-underline-font-weight);
    --body-lg-underline-line-height: var(--hop-body-lg-underline-line-height);
    --body-md-font-family: var(--hop-body-md-font-family);
    --body-md-font-size: var(--hop-body-md-font-size);
    --body-md-font-weight: var(--hop-body-md-font-weight);
    --body-md-line-height: var(--hop-body-md-line-height);
    --body-md-medium-font-family: var(--hop-body-md-medium-font-family);
    --body-md-medium-font-size: var(--hop-body-md-medium-font-size);
    --body-md-medium-font-weight: var(--hop-body-md-medium-font-weight);
    --body-md-medium-line-height: var(--hop-body-md-medium-line-height);
    --body-md-semibold-font-family: var(--hop-body-md-semibold-font-family);
    --body-md-semibold-font-size: var(--hop-body-md-semibold-font-size);
    --body-md-semibold-font-weight: var(--hop-body-md-semibold-font-weight);
    --body-md-semibold-line-height: var(--hop-body-md-semibold-line-height);
    --body-md-bold-font-family: var(--hop-body-md-bold-font-family);
    --body-md-bold-font-size: var(--hop-body-md-bold-font-size);
    --body-md-bold-font-weight: var(--hop-body-md-bold-font-weight);
    --body-md-bold-line-height: var(--hop-body-md-bold-line-height);
    --body-md-underline-font-family: var(--hop-body-md-underline-font-family);
    --body-md-underline-font-size: var(--hop-body-md-underline-font-size);
    --body-md-underline-font-weight: var(--hop-body-md-underline-font-weight);
    --body-md-underline-line-height: var(--hop-body-md-underline-line-height);
    --body-sm-font-family: var(--hop-body-sm-font-family);
    --body-sm-font-size: var(--hop-body-sm-font-size);
    --body-sm-font-weight: var(--hop-body-sm-font-weight);
    --body-sm-line-height: var(--hop-body-sm-line-height);
    --body-sm-medium-font-family: var(--hop-body-sm-medium-font-family);
    --body-sm-medium-font-size: var(--hop-body-sm-medium-font-size);
    --body-sm-medium-font-weight: var(--hop-body-sm-medium-font-weight);
    --body-sm-medium-line-height: var(--hop-body-sm-medium-line-height);
    --body-sm-semibold-font-family: var(--hop-body-sm-semibold-font-family);
    --body-sm-semibold-font-size: var(--hop-body-sm-semibold-font-size);
    --body-sm-semibold-font-weight: var(--hop-body-sm-semibold-font-weight);
    --body-sm-semibold-line-height: var(--hop-body-sm-semibold-line-height);
    --body-sm-bold-font-family: var(--hop-body-sm-bold-font-family);
    --body-sm-bold-font-size: var(--hop-body-sm-bold-font-size);
    --body-sm-bold-font-weight: var(--hop-body-sm-bold-font-weight);
    --body-sm-bold-line-height: var(--hop-body-sm-bold-line-height);
    --body-sm-underline-font-family: var(--hop-body-sm-underline-font-family);
    --body-sm-underline-font-size: var(--hop-body-sm-underline-font-size);
    --body-sm-underline-font-weight: var(--hop-body-sm-underline-font-weight);
    --body-sm-underline-line-height: var(--hop-body-sm-underline-line-height);
    --body-xs-font-family: var(--hop-body-xs-font-family);
    --body-xs-font-size: var(--hop-body-xs-font-size);
    --body-xs-font-weight: var(--hop-body-xs-font-weight);
    --body-xs-line-height: var(--hop-body-xs-line-height);
    --body-xs-medium-font-family: var(--hop-body-xs-medium-font-family);
    --body-xs-medium-font-size: var(--hop-body-xs-medium-font-size);
    --body-xs-medium-font-weight: var(--hop-body-xs-medium-font-weight);
    --body-xs-medium-line-height: var(--hop-body-xs-medium-line-height);
    --body-xs-semibold-font-family: var(--hop-body-xs-semibold-font-family);
    --body-xs-semibold-font-size: var(--hop-body-xs-semibold-font-size);
    --body-xs-semibold-font-weight: var(--hop-body-xs-semibold-font-weight);
    --body-xs-semibold-line-height: var(--hop-body-xs-semibold-line-height);
    --body-xs-bold-font-family: var(--hop-body-xs-bold-font-family);
    --body-xs-bold-font-size: var(--hop-body-xs-bold-font-size);
    --body-xs-bold-font-weight: var(--hop-body-xs-bold-font-weight);
    --body-xs-bold-line-height: var(--hop-body-xs-bold-line-height);
    --body-xs-underline-font-family: var(--hop-body-xs-underline-font-family);
    --body-xs-underline-font-size: var(--hop-body-xs-underline-font-size);
    --body-xs-underline-font-weight: var(--hop-body-xs-underline-font-weight);
    --body-xs-underline-line-height: var(--hop-body-xs-underline-line-height);

    // Accent
    --accent-lg-font-family: var(--hop-accent-lg-font-family);
    --accent-lg-font-size: var(--hop-accent-lg-font-size);
    --accent-lg-font-weight: var(--hop-accent-lg-font-weight);
    --accent-lg-line-height: var(--hop-accent-lg-line-height);
    --accent-sm-font-family: var(--hop-accent-sm-font-family);
    --accent-sm-font-size: var(--hop-accent-sm-font-size);
    --accent-sm-font-weight: var(--hop-accent-sm-font-weight);
    --accent-sm-line-height: var(--hop-accent-sm-line-height);

    // Shape
    --shape-circle: var(--hop-shape-circle);
    --shape-pill: var(--hop-shape-pill);
    --shape-rounded-lg: var(--hop-shape-rounded-lg);
    --shape-rounded-md: var(--hop-shape-rounded-md);
    --shape-rounded-sm: var(--hop-shape-rounded-sm);

    // Space
    --space-0: var(--hop-space-0);
    --space-20: var(--hop-space-20);
    --space-40: var(--hop-space-40);
    --space-80: var(--hop-space-80);
    --space-160: var(--hop-space-160);
    --space-240: var(--hop-space-240);
    --space-320: var(--hop-space-320);
    --space-400: var(--hop-space-400);
    --space-480: var(--hop-space-480);
    --space-640: var(--hop-space-640);
    --space-800: var(--hop-space-800);
    --space-960: var(--hop-space-960);
    --space-1280: var(--hop-space-1280);
    --space-inset-xs: var(--hop-space-inset-xs);
    --space-inset-sm: var(--hop-space-inset-sm);
    --space-inset-md: var(--hop-space-inset-md);
    --space-inset-lg: var(--hop-space-inset-lg);
    --space-inset-xl: var(--hop-space-inset-xl);
    --space-inset-squish-sm: var(--hop-space-inset-squish-sm);
    --space-inset-squish-md: var(--hop-space-inset-squish-md);
    --space-inset-squish-lg: var(--hop-space-inset-squish-lg);
    --space-inset-stretch-sm: var(--hop-space-inset-stretch-sm);
    --space-inset-stretch-md: var(--hop-space-inset-stretch-md);
    --space-inset-stretch-lg: var(--hop-space-inset-stretch-lg);
    --space-stack-xs: var(--hop-space-stack-xs);
    --space-stack-sm: var(--hop-space-stack-sm);
    --space-stack-md: var(--hop-space-stack-md);
    --space-stack-lg: var(--hop-space-stack-lg);
    --space-stack-xl: var(--hop-space-stack-xl);
    --space-inline-xs: var(--hop-space-inline-xs);
    --space-inline-sm: var(--hop-space-inline-sm);
    --space-inline-md: var(--hop-space-inline-md);
    --space-inline-lg: var(--hop-space-inline-lg);
    --space-inline-xl: var(--hop-space-inline-xl);

    // Colors
    --coastal-25: var(--hop-coastal-25);
    --coastal-50: var(--hop-coastal-50);
    --coastal-75: var(--hop-coastal-75);
    --coastal-100: var(--hop-coastal-100);
    --coastal-200: var(--hop-coastal-200);
    --coastal-300: var(--hop-coastal-300);
    --coastal-400: var(--hop-coastal-400);
    --coastal-500: var(--hop-coastal-500);
    --coastal-600: var(--hop-coastal-600);
    --coastal-700: var(--hop-coastal-700);
    --coastal-800: var(--hop-coastal-800);
    --coastal-900: var(--hop-coastal-900);
    --quetzal-25: var(--hop-quetzal-25);
    --quetzal-50: var(--hop-quetzal-50);
    --quetzal-75: var(--hop-quetzal-75);
    --quetzal-100: var(--hop-quetzal-100);
    --quetzal-200: var(--hop-quetzal-200);
    --quetzal-300: var(--hop-quetzal-300);
    --quetzal-400: var(--hop-quetzal-400);
    --quetzal-500: var(--hop-quetzal-500);
    --quetzal-600: var(--hop-quetzal-600);
    --quetzal-700: var(--hop-quetzal-700);
    --quetzal-800: var(--hop-quetzal-800);
    --quetzal-900: var(--hop-quetzal-900);
    --orchid-bloom-25: var(--hop-orchid-bloom-25);
    --orchid-bloom-50: var(--hop-orchid-bloom-50);
    --orchid-bloom-75: var(--hop-orchid-bloom-75);
    --orchid-bloom-100: var(--hop-orchid-bloom-100);
    --orchid-bloom-200: var(--hop-orchid-bloom-200);
    --orchid-bloom-300: var(--hop-orchid-bloom-300);
    --orchid-bloom-400: var(--hop-orchid-bloom-400);
    --orchid-bloom-500: var(--hop-orchid-bloom-500);
    --orchid-bloom-600: var(--hop-orchid-bloom-600);
    --orchid-bloom-700: var(--hop-orchid-bloom-700);
    --orchid-bloom-800: var(--hop-orchid-bloom-800);
    --orchid-bloom-900: var(--hop-orchid-bloom-900);
    --sapphire-25: var(--hop-sapphire-25);
    --sapphire-50: var(--hop-sapphire-50);
    --sapphire-75: var(--hop-sapphire-75);
    --sapphire-100: var(--hop-sapphire-100);
    --sapphire-200: var(--hop-sapphire-200);
    --sapphire-300: var(--hop-sapphire-300);
    --sapphire-400: var(--hop-sapphire-400);
    --sapphire-500: var(--hop-sapphire-500);
    --sapphire-600: var(--hop-sapphire-600);
    --sapphire-700: var(--hop-sapphire-700);
    --sapphire-800: var(--hop-sapphire-800);
    --sapphire-900: var(--hop-sapphire-900);
    --fog-25: var(--hop-fog-25);
    --fog-50: var(--hop-fog-50);
    --fog-75: var(--hop-fog-75);
    --fog-100: var(--hop-fog-100);
    --fog-200: var(--hop-fog-200);
    --fog-300: var(--hop-fog-300);
    --fog-400: var(--hop-fog-400);
    --fog-500: var(--hop-fog-500);
    --fog-600: var(--hop-fog-600);
    --fog-700: var(--hop-fog-700);
    --fog-800: var(--hop-fog-800);
    --fog-900: var(--hop-fog-900);
    --toad-25: var(--hop-toad-25);
    --toad-50: var(--hop-toad-50);
    --toad-75: var(--hop-toad-75);
    --toad-100: var(--hop-toad-100);
    --toad-200: var(--hop-toad-200);
    --toad-300: var(--hop-toad-300);
    --toad-400: var(--hop-toad-400);
    --toad-500: var(--hop-toad-500);
    --toad-600: var(--hop-toad-600);
    --toad-700: var(--hop-toad-700);
    --toad-800: var(--hop-toad-800);
    --toad-900: var(--hop-toad-900);
    --sunken-treasure-25: var(--hop-sunken-treasure-25);
    --sunken-treasure-50: var(--hop-sunken-treasure-50);
    --sunken-treasure-75: var(--hop-sunken-treasure-75);
    --sunken-treasure-100: var(--hop-sunken-treasure-100);
    --sunken-treasure-200: var(--hop-sunken-treasure-200);
    --sunken-treasure-300: var(--hop-sunken-treasure-300);
    --sunken-treasure-400: var(--hop-sunken-treasure-400);
    --sunken-treasure-500: var(--hop-sunken-treasure-500);
    --sunken-treasure-600: var(--hop-sunken-treasure-600);
    --sunken-treasure-700: var(--hop-sunken-treasure-700);
    --sunken-treasure-800: var(--hop-sunken-treasure-800);
    --sunken-treasure-900: var(--hop-sunken-treasure-900);
    --koi-25: var(--hop-koi-25);
    --koi-50: var(--hop-koi-50);
    --koi-75: var(--hop-koi-75);
    --koi-100: var(--hop-koi-100);
    --koi-200: var(--hop-koi-200);
    --koi-300: var(--hop-koi-300);
    --koi-400: var(--hop-koi-400);
    --koi-500: var(--hop-koi-500);
    --koi-600: var(--hop-koi-600);
    --koi-700: var(--hop-koi-700);
    --koi-800: var(--hop-koi-800);
    --koi-900: var(--hop-koi-900);
    --amanita-25: var(--hop-amanita-25);
    --amanita-50: var(--hop-amanita-50);
    --amanita-75: var(--hop-amanita-75);
    --amanita-100: var(--hop-amanita-100);
    --amanita-200: var(--hop-amanita-200);
    --amanita-300: var(--hop-amanita-300);
    --amanita-400: var(--hop-amanita-400);
    --amanita-500: var(--hop-amanita-500);
    --amanita-600: var(--hop-amanita-600);
    --amanita-700: var(--hop-amanita-700);
    --amanita-800: var(--hop-amanita-800);
    --amanita-900: var(--hop-amanita-900);
    --moss-25: var(--hop-moss-25);
    --moss-50: var(--hop-moss-50);
    --moss-75: var(--hop-moss-75);
    --moss-100: var(--hop-moss-100);
    --moss-200: var(--hop-moss-200);
    --moss-300: var(--hop-moss-300);
    --moss-400: var(--hop-moss-400);
    --moss-500: var(--hop-moss-500);
    --moss-600: var(--hop-moss-600);
    --moss-700: var(--hop-moss-700);
    --moss-800: var(--hop-moss-800);
    --moss-900: var(--hop-moss-900);
    --abyss: var(--hop-abyss);
    --rock-20: var(--hop-rock-20);
    --rock-25: var(--hop-rock-25);
    --rock-50: var(--hop-rock-50);
    --rock-75: var(--hop-rock-75);
    --rock-100: var(--hop-rock-100);
    --rock-200: var(--hop-rock-200);
    --rock-300: var(--hop-rock-300);
    --rock-400: var(--hop-rock-400);
    --rock-500: var(--hop-rock-500);
    --rock-600: var(--hop-rock-600);
    --rock-700: var(--hop-rock-700);
    --rock-800: var(--hop-rock-800);
    --rock-900: var(--hop-rock-900);
    --samoyed: var(--hop-samoyed);
    --shadow-none: var(--hop-shadow-none);
    --shadow-sm: var(--hop-shadow-sm);
    --shadow-md: var(--hop-shadow-md);
    --shadow-lg: var(--hop-shadow-lg);

    // Font
    --font-family-primary: var(--hop-font-family-primary);
    --font-family-secondary: var(--hop-font-family-secondary);
    --font-family-tertiary: var(--hop-font-family-tertiary);
    --font-size-120: var(--hop-font-size-120);
    --font-size-140: var(--hop-font-size-140);
    --font-size-160: var(--hop-font-size-160);
    --font-size-180: var(--hop-font-size-180);
    --font-size-200: var(--hop-font-size-200);
    --font-size-240: var(--hop-font-size-240);
    --font-size-280: var(--hop-font-size-280);
    --font-size-320: var(--hop-font-size-320);
    --font-size-360: var(--hop-font-size-360);
    --font-size-480: var(--hop-font-size-480);
    --font-weight-400: var(--hop-font-weight-400);
    --font-weight-410: var(--hop-font-weight-410);
    --font-weight-505: var(--hop-font-weight-505);
    --font-weight-580: var(--hop-font-weight-580);
    --font-weight-590: var(--hop-font-weight-590);
    --font-weight-680: var(--hop-font-weight-680);
    --font-weight-690: var(--hop-font-weight-690);

    --line-height-1-125: var(--hop-line-height-1-125);
    --line-height-1-14: var(--hop-line-height-1-14);
    --line-height-1-20: var(--hop-line-height-1-20);
    --line-height-1-25: var(--hop-line-height-1-25);
    --line-height-1-33: var(--hop-line-height-1-33);
    --line-height-1-4285: var(--hop-line-height-1-4285);
    --line-height-1-50: var(--hop-line-height-1-50);

    --border-radius-0: var(--hop-border-radius-0);
    --border-radius-1: var(--hop-border-radius-1);
    --border-radius-2: var(--hop-border-radius-2);
    --border-radius-3: var(--hop-border-radius-3);
    --border-radius-4: var(--hop-border-radius-4);
    --border-radius-9999: var(--hop-border-radius-9999);

    // Dataviz
    --dataviz-diverging-sequence-2-negative1: var(--hop-dataviz-diverging-sequence-2-negative1);
    --dataviz-diverging-sequence-2-negative1-hover: var(--hop-dataviz-diverging-sequence-2-negative1-hover);
    --dataviz-diverging-sequence-2-negative2: var(--hop-dataviz-diverging-sequence-2-negative2);
    --dataviz-diverging-sequence-2-negative2-hover: var(--hop-dataviz-diverging-sequence-2-negative2-hover);
    --dataviz-diverging-sequence-2-negative3: var(--hop-dataviz-diverging-sequence-2-negative3);
    --dataviz-diverging-sequence-2-negative3-hover: var(--hop-dataviz-diverging-sequence-2-negative3-hover);
    --dataviz-diverging-sequence-2-negative4: var(--hop-dataviz-diverging-sequence-2-negative4);
    --dataviz-diverging-sequence-2-negative4-hover: var(--hop-dataviz-diverging-sequence-2-negative4-hover);

    --dataviz-diverging-sequence-2-positive1: var(--hop-dataviz-diverging-sequence-2-positive1);
    --dataviz-diverging-sequence-2-positive1-hover: var(--hop-dataviz-diverging-sequence-2-positive1-hover);
    --dataviz-diverging-sequence-2-positive2: var(--hop-dataviz-diverging-sequence-2-positive2);
    --dataviz-diverging-sequence-2-positive2-hover: var(--hop-dataviz-diverging-sequence-2-positive2-hover);
    --dataviz-diverging-sequence-2-positive3: var(--hop-dataviz-diverging-sequence-2-positive3);
    --dataviz-diverging-sequence-2-positive3-hover: var(--hop-dataviz-diverging-sequence-2-positive3-hover);
    --dataviz-diverging-sequence-2-positive4: var(--hop-dataviz-diverging-sequence-2-positive4);
    --dataviz-diverging-sequence-2-positive4-hover: var(--hop-dataviz-diverging-sequence-2-positive4-hover);

    --dataviz-diverging-sequence-1-negative1: var(--hop-dataviz-diverging-sequence-1-negative1);
    --dataviz-diverging-sequence-1-negative2: var(--hop-dataviz-diverging-sequence-1-negative2);
    --dataviz-diverging-sequence-1-negative3: var(--hop-dataviz-diverging-sequence-1-negative3);
    --dataviz-diverging-sequence-1-negative5: var(--hop-dataviz-diverging-sequence-1-negative5);

    --dataviz-diverging-sequence-1-neutral: var(--hop-dataviz-diverging-sequence-1-neutral);

    --dataviz-diverging-sequence-1-positive1: var(--hop-dataviz-diverging-sequence-1-positive1);
    --dataviz-diverging-sequence-1-positive2: var(--hop-dataviz-diverging-sequence-1-positive2);
    --dataviz-diverging-sequence-1-positive3: var(--hop-dataviz-diverging-sequence-1-positive3);
    --dataviz-diverging-sequence-1-positive5: var(--hop-dataviz-diverging-sequence-1-positive5);

    --dataviz-unavailable-weak: var(--hop-dataviz-unavailable-weak);
    --dataviz-unavailable: var(--hop-dataviz-unavailable);
}
