@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

@include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), preview-completed-outro-slide-in);

.preview-completed-outro {
    display: flex;
    justify-content: center;
    padding: calc(4.8rem * var(--rem-ratio, 1)) calc(1.6rem * var(--rem-ratio, 1));

    &__content {
        width: 100%;
        max-width: calc(32rem * var(--rem-ratio, 1));

        [data-brand="workleap"] & {
            max-width: calc(36rem * var(--rem-ratio, 1));
        }
    }

    &__image {
        display: block;
        width: calc(9.6rem * var(--rem-ratio, 1));
        height: calc(9.6rem * var(--rem-ratio, 1));
        margin: 0 auto;
        
        animation: fade-in linear 400ms both,
            preview-completed-outro-slide-in ease-out 400ms both;
    }

    &__title {
        margin-top: calc(3.2rem * var(--rem-ratio, 1));

        @include heading-1;
        font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-2xl-font-weight);
        text-align: center;

        animation: fade-in linear 400ms 200ms both,
            preview-completed-outro-slide-in ease-out 400ms 200ms both;
    }

    &__subtitle {
        margin-top: r.replaceBy($space-4, --hop-space-stack-sm);

        @include medium-text;
        font-weight: r.replaceBy(var(--font-weight-regular), --hop-body-md-font-weight);
        text-align: center;

        animation: fade-in linear 400ms 400ms both,
            preview-completed-outro-slide-in ease-out 400ms 400ms both;

        [data-brand="workleap"] & {
            @include large-text;
        }
    }
}