@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.peer-select-option {
    display: flex;
    align-items: center;

    [data-brand="workleap"] & {
        @include small-text;

        padding: var(--hop-space-inset-sm) var(--hop-space-inset-md);

        border-radius: var(--hop-shape-rounded-md);
    }

    &:hover {
        background-color: r.replaceBy($brand-highlight, --hop-neutral-surface-hover);

        .peer-select-option__fullname {
            @include media-hover {
                color: r.replaceBy($primary-text-alt, --hop-neutral-text-hover);
            }
        }
    }

    &--selected {
        background-color: r.replaceBy($brand-highlight, --hop-neutral-surface);

        .peer-select-option__fullname {
            color: $primary-text-alt;
            font-weight: r.replaceBy($font-weight-regular, --hop-body-sm-semibold-font-weight);
        }
    }

    &__thumbnail {
        flex-shrink: 0;
        width: calc(3.2rem * var(--rem-ratio, 1));
        height: calc(3.2rem * var(--rem-ratio, 1));
        margin-right: r.replaceBy($space-3, --hop-space-inline-sm);

        border-radius: r.replaceBy(var(--border-radius-sm), --hop-shape-circle);

        [data-brand="workleap"] & {
            width: calc(2.4rem * var(--rem-ratio, 1));
            height: calc(2.4rem * var(--rem-ratio, 1));
        }
    }

    &__infos {
        flex-grow: 1;
        overflow: hidden;

        white-space: nowrap;
    }

    &__fullname {
        overflow-x: hidden;

        color: r.replaceBy($secondary-text, --hop-neutral-text);
    }

    &__email {
        @include caption;

        font-weight: r.replaceBy($font-weight-regular, --hop-body-xs-font-weight);
    }

    &.select-option--keyboard-focused {
        background-color: r.replaceBy($brand-highlight-dim, --hop-neutral-surface-hover);
    }
}