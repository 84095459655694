@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.activities-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: $z-index-background;

    &--light {
        background-color: $background-light;
    }

    &--dark {
        background-color: $background-dark;
    }
}