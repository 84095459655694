@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

[data-brand="workleap"] {
    --_stroke-none: none;
}

.pulse-survey-star-rating-question {
    &__star-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(4.8rem * var(--rem-ratio, 1));

        color: r.replaceBy($surface-star-rating, --hop-neutral-surface);
        line-height: 0;

        cursor: pointer;

        stroke: r.replaceBy(none, --hop-neutral-border-strong);

        @include small {
            height: calc(8.8rem * var(--rem-ratio, 1));
        }

        &--selected {
            color: r.replaceBy($surface-star-rating-selected, --hop-decorative-option6-surface);

            .pulse-survey-star-rating-question__star-icon {
                width: calc(4.8rem * var(--rem-ratio, 1));
                height: calc(4.8rem * var(--rem-ratio, 1));

                @include small {
                    width: calc(8.8rem * var(--rem-ratio, 1));
                    height: calc(8.8rem * var(--rem-ratio, 1));
                }
            }
        }

        &--highlighted {
            color: r.replaceBy($surface-star-rating-hovered, --hop-decorative-option6-surface);

            .pulse-survey-star-rating-question__star-icon {
                width: calc(4.8rem * var(--rem-ratio, 1));
                height: calc(4.8rem * var(--rem-ratio, 1));

                @include small {
                    width: calc(8.8rem * var(--rem-ratio, 1));
                    height: calc(8.8rem * var(--rem-ratio, 1));
                }
            }
        }

        [data-brand="workleap"] & {
            &--hovered {
                .pulse-survey-star-rating-question__star-icon {
                    @include small {
                        width: calc(9.6rem * var(--rem-ratio, 1));
                        height: calc(9.6rem * var(--rem-ratio, 1));

                        filter: drop-shadow(calc(-0.2rem * var(--rem-ratio, 1)) calc(0.2rem * var(--rem-ratio, 1)) 0 var(--hop-neutral-border-strong))
                            drop-shadow(calc(-0.1rem * var(--rem-ratio, 1)) calc(0.1rem * var(--rem-ratio, 1)) 0 var(--hop-neutral-border-strong));
                    }
                }
            }
        }
    }

    &--confirmed {
        pointer-events: none;

        .pulse-survey-star-rating-question__star-label--selected {
            @keyframes star-label-confirmed-pulse-animation {
                0% { color: r.replaceBy($surface-star-rating-hovered, --hop-neutral-surface); }
                100% { color: r.replaceBy($surface-star-rating-selected, --hop-decorative-option6-surface); }
            }

            animation: star-label-confirmed-pulse-animation linear 80ms 5 alternate both;
        }
    }

    &__group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include small {
            max-width: calc(76rem * var(--rem-ratio, 1));
            margin: 0 auto;
        }
    }

    &__choice {
        flex: 1 1 0%;
        min-width: 0;
    }

    &__radio-input {
        position: absolute;

        width: 0;
        height: 0;

        opacity: 0;

        pointer-events: none;

        &:focus-visible + .pulse-survey-star-rating-question__star-label > .pulse-survey-star-rating-question__star-icon-container > .pulse-survey-star-rating-question__star-icon {
            stroke: r.replaceBy($surface-star-rating-focused, var(--_stroke-none));
        }

        &:focus-visible + .pulse-survey-star-rating-question__star-label > .pulse-survey-star-rating-question__star-icon-container > .pulse-survey-star-rating-question__star-icon > .focus-outline {
            stroke: r.replaceBy(none, --hop-primary-border-focus);
        }
    }

    &__star-icon {
        width: calc(4rem * var(--rem-ratio, 1));
        height: calc(4rem * var(--rem-ratio, 1));

        fill: currentColor;

        @include small {
            width: calc(8rem * var(--rem-ratio, 1));
            height: calc(8rem * var(--rem-ratio, 1));
        }
    }

    &--animated-intro {
        .base-pulse-survey-question__header {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), two-way-slider-question-slide-in-animation);

            animation: fade-in linear 500ms both,
                two-way-slider-question-slide-in-animation ease-out 500ms both;
        }

        .base-pulse-survey-question__input {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), two-way-slider-slide-in-animation);
            $delay: 400ms;

            animation: fade-in linear 500ms $delay both,
                two-way-slider-slide-in-animation ease-out 500ms $delay both;
        }
    }
}