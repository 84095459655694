@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.recognition-banner {
    display: flex;
    justify-content: center;

    color: r.replaceBy($primary-text, --hop-neutral-text-strong);

    text-align: center;

    background-color: $surface-recognition-banner;

    &--mobile {
        padding: r.replaceBy($space-1 $space-2, --hop-space-inset-squish-sm);

        @include large {
            display: none;
        }

        [data-brand="workleap"] & {
            @include small-text;
        }
    }

    &--desktop {
        display: none;

        @include large {
            display: flex;
            padding: r.replaceBy($space-2, --hop-space-inset-sm);
        }
    }
}