@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

[data-brand="workleap"] {
    --_cta-button-padding: calc(2.4rem * var(--rem-ratio, 1)) calc(4.8rem * var(--rem-ratio, 1));
}

.preview-custom-poll-forbidden-page {
    padding: 0 calc(1.6rem * var(--rem-ratio, 1));

    &__header {
        margin-bottom: calc(3.2rem * var(--rem-ratio, 1));

        @include small {
            margin-bottom: calc(7.2rem * var(--rem-ratio, 1));

            [data-brand="workleap"] & {
                margin-bottom: calc(5.4rem * var(--rem-ratio, 1));
            }
        }
    }

    &__logo {
        display: block;
        width: calc(11rem * var(--rem-ratio, 1));
        margin: calc(3.2rem * var(--rem-ratio, 1)) auto 0 auto;

        fill: r.replaceBy($primary-text, --hop-neutral-surface-strong);

        [data-brand="workleap"] & {
            width: calc(12.4rem * var(--rem-ratio, 1));
        }

        @include small {
            width: calc(16rem * var(--rem-ratio, 1));
            height: calc(4.8rem * var(--rem-ratio, 1));
            margin-top: calc(6.4rem * var(--rem-ratio, 1));

            [data-brand="workleap"] & {
                width: calc(16.4rem * var(--rem-ratio, 1));
                height: auto;
            }
        }
    }

    &__image-container {
        display: flex;
        justify-content: center;

        [data-brand="workleap"] & {
            margin-bottom: var(--hop-space-stack-lg);
        }
    }

    &__image {
        width: calc(30rem * var(--rem-ratio, 1));
        height: calc(24rem * var(--rem-ratio, 1));

        [data-brand="workleap"] & {
            width: calc(20rem * var(--rem-ratio, 1));
            height: calc(16rem * var(--rem-ratio, 1));
        }
    }

    &__title {
        @include heading-1;
        font-weight: r.replaceBy(var(--font-weight-semibold), --hop-heading-2xl-font-weight);
        text-align: center;
    }

    &__subtitle {
        margin-top: r.replaceBy($space-2, --hop-space-stack-sm);

        @include medium-text;
        text-align: center;
    }

    &__go-to-officevibe-button {
        display: flex;
        margin: r.replaceBy($space-6, --hop-space-stack-xl) auto r.replaceBy($space-3, --hop-space-stack-md) auto;
        /* stylelint-disable-next-line custom-property-no-missing-var-function */
        padding: r.replaceBy($space-0 $space-6, --_cta-button-padding)

    }
}