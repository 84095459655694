@use '@sass/workleap-rebrand/replace-by' as r;
@import "@sass/utils/all";

.signup-communication-preferences-slide {
    display: flex;
    flex-direction: column;

    &__header {
        flex-shrink: 0;
    }

    &__form {
        flex: 0 1 100%;
        min-width: 0;

        @include ie {
            flex: 0 0 auto;
        }
    }

    &__form-row + &__form-row {
        margin-top: r.replaceBy($space-2, --hop-space-stack-md);
    }

    &__footer {
        flex-shrink: 0;
    }
}