@import "@sass/utils/all";

.continue-pulse-survey-intro {
    max-width: calc(76rem * var(--rem-ratio, 1));
    margin: 0 auto;
    padding: 0 calc(1.6rem * var(--rem-ratio, 1));

    &__title {
        margin-bottom: calc(3.2rem * var(--rem-ratio, 1));

        @include heading-1;
        font-weight: var(--font-weight-semibold);
        text-align: center;
    }

    &__subtitle {
        @include heading-2;
        font-weight: var(--font-weight-medium);
        text-align: center;
    }

    &--animated-intro {
        .continue-pulse-survey-intro__title {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), continue-pulse-survey-intro-title-slide-in-animation);

            animation: fade-in linear 500ms both,
                continue-pulse-survey-intro-title-slide-in-animation ease-out 500ms both;
        }

        .continue-pulse-survey-intro__subtitle {
            @include slide-in-keyframes("bottom", calc(4.8rem * var(--rem-ratio, 1)), continue-pulse-survey-intro-subtitle-slide-in-animation);
            $delay: 200ms;

            animation: fade-in linear 500ms $delay both,
                continue-pulse-survey-intro-subtitle-slide-in-animation ease-out 500ms $delay both;
        }
    }
}